import Form from 'react-bootstrap/Form';

function SelectFieldFixedNew({ setValue, value, items, label, disabled, placeholder, name, required }) {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Select disabled={disabled} required={required} value={value} name={name} onChange={setValue}>
        {items && (placeholder !== undefined ? <option value=''>{placeholder}</option> : <option value=''></option>)}

        {items ? (
          items.map((option) => (
            <option key={option['id']} value={option['value']}>
              {option['label']}
            </option>
          ))
        ) : (
          <option value=''>{value}</option>
        )}
      </Form.Select>
    </>
  );
}

export default SelectFieldFixedNew;

import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import MaterialReactTable from 'material-react-table';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

function Table({ error, loading, columns, items, funcao, initialState, hasActions, elementHeight, actionRenderer, positionActionsColumn, enablePagination, enableGlobalFilter, enableBottomToolbar, enableTopToolbar }) {
  return (
    <>
      {!error ? (
        items ? (
          <MaterialReactTable
           enablePagination={enablePagination}
           enableGlobalFilter={enableGlobalFilter}
           enableBottomToolbar={enableBottomToolbar}
           enableTopToolbar={enableTopToolbar}
            muiTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                !loading && funcao(items[row.id]);
              },
              sx: {
                cursor: !loading ? 'pointer' : 'wait',
              },
            })}
            onRowClick={(event, rowData) => {
              console.log(event, rowData, 'ID');
            }}
            state={{ isLoading: loading }}
            data={items}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableHiding={true}
            initialState={initialState}
            columns={columns}
            
            // defaultColumn={{
            //   size: 20,
            //   maxSize: 20,
            // }}
            enableColumnResizing={false}
            positionActionsColumn={positionActionsColumn}
            enableRowActions={hasActions}
            renderRowActions={({ row }) => actionRenderer(row)}
            enableStickyHeader
            enableColumnActions={false}
            muiTableContainerProps={elementHeight && { sx: { maxHeight: `${elementHeight}px` } }}
          />
        ) : (
          <div className='spinner'>
            {' '}
            <Spinner animation='border' variant='primary' />{' '}
          </div>
        )
      ) : (
        <Alert key='danger' variant='danger'>
          {error}
        </Alert>
      )}
    </>
  );
}

export default Table;

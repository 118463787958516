import { useState, useMemo } from "react";
import { useFetch } from "../hooks/useFetch";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from "../components/Table";
import endpoints from "../modules/endpoints";
import { Col, Row } from "react-bootstrap";
import SelectFieldFetchNew from "../components/selectFieldFetchNew";
import { useRequest } from "../hooks/useRequest";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";

function PesquisasColaboradores() {
  const [openDialog, setOpenDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { data: items, httpConfig, loading, error } = useFetch(endpoints.pesquisas());
  const { sendRequest} = useRequest();
  // const { data: pesquisasEnviadas, httpConfig: sendRequest, loading: pesquisasLoading, error: pesquisasError } = useFetch(endpoints.pesquisasEnviadas());

  const initialState = {
    id: "",
    nome: "",
    grupo: "",
    link_template: "",
    nome_tabela_pesquisa: "",
    mensagem: "",
  };

  const [formData, setFormData] = useState(initialState);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    if (name !== "mensagem") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  function resetFormData() {
    setFormData(initialState);
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      const newClient = formData;

      if (isEditing) {
        httpConfig(newClient, "PUT");
      } else {
        httpConfig(newClient, "POST");
      }

      handleCloseDialog();

      if (!isEditing) {
        setShowAlert(true);
      } else {
        setShowAlertEdit(true);
      }

      setIsEditing(false);

      setTimeout(() => {
        setShowAlert(false);
        setShowAlertEdit(false);
      }, 5000);
    }
  };

  const navigate = useNavigate();

  const handleSendResearches = () => {
    sendRequest(endpoints.sendWhatsAppMessages(), "POST", formData);
  };

  const handleGoToResearchAnswers = () => {
    navigate("/pessoas/pesquisaInterna");
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.id,
        header: "Id",
        size: 30,
      },
      {
        accessorFn: (row) => row.nome,
        header: "Nome",
        enableHiding: false,
        size: 30,
      },
      {
        accessorFn: (row) => row.nome_grupo,
        header: "Grupo",
        size: 40,
      },
      {
        accessorFn: (row) => row.link_template,
        header: "Template Link",
        size: 60,
      },
      {
        accessorFn: (row) => row.nome_tabela_pesquisa,
        header: "Tabela pesquisa",
      },
    ],
    []
  );

  function editItem(item) {
    setIsEditing(true);
    setFormData(item);

    handleOpenDialog();
  }

  return (
    <>
      <Sidebar />
      <div className="content">
        {showAlertEdit && (
          <Alert key="success" variant="success">
            Pesquisa editada com sucesso!
          </Alert>
        )}
        {showAlert && (
          <Alert key="success" variant="success">
            Pesquisa cadastrada com sucesso!
          </Alert>
        )}
        <div className="header_modules">
          <h2 className="product-title">PESQUISAS</h2>
          {!error ? (
            !loading ? (
              <Button onClick={handleOpenDialog} className="btn-add-roles btn-success">
                Nova Pesquisa
              </Button>
            ) : (
              <Button className="btn-add-roles btn-success" disabled>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Loading...</span>
              </Button>
            )
          ) : null}
        </div>
        <div className="table_funcoes">
          <Table
            error={error}
            loading={loading}
            columns={columns}
            items={items}
            funcao={editItem}
            initialState={{ density: "compact", pagination: { pageSize: 100 } }}
          />
        </div>
        <div>
          <Modal show={openDialog} onHide={handleCloseDialog} centered size="lg">
            <Form onSubmit={handleSubmit} className="form-page">
              <Modal.Header closeButton>
                {!isEditing ? <Modal.Title>Nova Pesquisa</Modal.Title> : <Modal.Title>Pesquisa</Modal.Title>}
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={6}>
                    <Form.Group className="mb-3" controlId="formBasicNome">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        required
                        value={formData.nome}
                        name="nome"
                        type="text"
                        onChange={handleChangeValue}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <SelectFieldFetchNew
                      label="Grupo"
                      setValue={handleChangeValue}
                      name="grupo"
                      value={formData.grupo}
                      path="/api/groups"
                      field={"nome"}
                      placeholder="Selecione um grupo"
                      valueById
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>
                    <Form.Group className="mb-3" controlId="formBasicNome">
                      <Form.Label>Template do Link</Form.Label>
                      <Form.Control
                        required
                        value={formData.link_template}
                        name="link_template"
                        type="text"
                        onChange={handleChangeValue}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicNome">
                        <Form.Label>Nome Tabela DB</Form.Label>
                        <Form.Control
                          required
                          value={formData.nome_tabela_pesquisa}
                          name="nome_tabela_pesquisa"
                          type="text"
                          onChange={handleChangeValue}
                        />
                      </Form.Group>
                    </Col>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <Form.Group className="mb-3" controlId="formBasicNome">
                      <Form.Label>Mensagem</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={formData.mensagem}
                        name="mensagem"
                        rows={5}
                        onChange={handleChangeValue}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Form.Group className="btn-controls">
                  <Button className="btn-primary action-btn" onClick={handleGoToResearchAnswers}>
                    Pesquisas Enviadas
                  </Button>
                  <Button className="btn-success action-btn" onClick={handleSendResearches}>
                    Enviar Pesquisas
                  </Button>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group>
                  <Button className="btn-danger action-btn" onClick={handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button className="btn-success action-btn" type="submit">
                    {" "}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default PesquisasColaboradores;

import { useState, useMemo, useEffect, useRef } from 'react';
import { useFetch } from '../hooks/useFetch';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Table from '../components/Table';
import endpoints from '../modules/endpoints';
import Sidebar from '../components/Sidebar';
import { Col, FormGroup, InputGroup, ListGroup, Row } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Document, Page, pdfjs } from 'react-pdf';
import SelectFieldFixedNew from '../components/selectFieldFixedNew';
// import { useRequest } from '../hooks/useRequest';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Recruitment() {
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [fileName, setFileName] = useState(undefined);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadedFile, setUploadedFile] = useState(undefined);

  const [openPDFView, setOpenPDFView] = useState(false);
  const [PDFFile, setPDFFile] = useState(undefined);
  const [scale, setScale] = useState(1.5);

  const [authToken] = useState(localStorage.getItem('authToken'));

  // const [newOcurrence, setNewOccurence] = useState('');

  // const [ocorrencias, setOcorrencias] = useState(undefined);

  const { data: items, httpConfig, loading, error } = useFetch(endpoints.recrutamento());

  // const { sendRequest: getOcorrencias, response: ocorrenciasResponse } = useRequest();
  // const { sendRequest: addOcorrence, response: addOccurenceResponse } = useRequest();
  // const { sendRequest: removeOcorrence, response: removeOccurenceResponse } = useRequest();

  const [lastId, setLastId] = useState(undefined);

  const initialFormData = {
    id: '',
    nome_completo: '',
    email: '',
    local_de_residencia: '',
    telefone: '',
    funcoes: [],
    status: '',
    file: '',
    filename: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
    setFileName('');
    // setNewOccurence('');
    setUploadedFile(undefined);
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      if (isEditing) {
        if (uploadedFile) {
          const formFile = new FormData();
          formFile.append('file', uploadedFile);

          fetch(`${endpoints.apiServer()}/recruitmentFile/${formData.filename}`, {
            method: 'PUT',
            body: formFile,
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              const form = {
                id: formData.id,
                nome_completo: formData.nome_completo,
                email: formData.email,
                local_de_residencia: formData.local_de_residencia,
                telefone: formData.telefone,
                funcoes: formData.funcoes,
                filename: `${data.filename}.pdf`,
                status: formData.status,
              };
              httpConfig(form, 'PUT');
            })
            .catch((err) => console.error(err));
        } else {
          const newRecruit = formData;
          httpConfig(newRecruit, 'PUT');
        }
      } else {
        if (uploadedFile) {
          const formFile = new FormData();
          formFile.append('file', uploadedFile);

          fetch(`${endpoints.apiServer()}/recruitmentFile`, {
            method: 'POST',
            body: formFile,
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              const form = {
                nome_completo: formData.nome_completo,
                email: formData.email,
                local_de_residencia: formData.local_de_residencia,
                telefone: formData.telefone,
                funcoes: formData.funcoes,
                filename: `${data.filename}.pdf`,
                status: formData.status,
              };

              const newRecruit = form;

              httpConfig(newRecruit, 'POST');
            })
            .catch((err) => console.error(err));
        } else {
          const newRecruit = formData;
          httpConfig(newRecruit, 'POST');
        }
      }

      handleCloseDialog();

      if (!isEditing) {
        setShowAlert(true);
      } else {
        setShowAlertEdit(true);
      }

      setIsEditing(false);

      setTimeout(() => {
        setShowAlert(false);
        setShowAlertEdit(false);
      }, 5000);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.nome_completo,
        header: 'Nome completo',
      },
      {
        accessorFn: (row) => row.email,
        header: 'Email',
      },
      {
        accessorFn: (row) => row.telefone,
        header: 'Telefone',
      },

      {
        accessorFn: (row) => row.funcoes,
        header: 'Funções',
      },
      {
        accessorFn: (row) => row.local_de_residencia,
        header: 'Local de residência',
        enableHiding: false,
      },
    ],
    []
  );

  function viewItem(item) {
    // if (lastId !== item.id) {
    //   setOcorrencias(undefined);
    // }

    setLastId(item.id);

    setIsEditing(true);

    const updatedItem = {
      ...item,
      funcoes: JSON.parse(item.funcoes),
    };

    setFormData(updatedItem);

    handleOpenDialog();
  }

  // useEffect(() => {
  //   if (formData.id !== '') {
  //     getOcorrencias(endpoints.ocorrencias(), 'GET', `${formData.id}`);
  //   }
  // }, [formData.id]);

  // useEffect(() => {
  //   if (ocorrenciasResponse) {
  //     setOcorrencias(ocorrenciasResponse);
  //   }
  // }, [ocorrenciasResponse]);

  // useEffect(() => {
  //   if (addOccurenceResponse) {
  //     setOcorrencias((prevOcorrencias) => [
  //       ...prevOcorrencias,
  //       {
  //         id: addOccurenceResponse.id,
  //         recrutamento_id: addOccurenceResponse.recrutamento_id,
  //         ocorrencia: addOccurenceResponse.ocorrencia,
  //       },
  //     ]);
  //   }
  // }, [addOccurenceResponse]);

  const handleChangeValue = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'funcoes') {
      setFormData((prevFormData) => {
        const updatedFuncoes = checked ? [...prevFormData.funcoes, value] : prevFormData.funcoes.filter((item) => item !== value);

        return {
          ...prevFormData,
          funcoes: updatedFuncoes,
        };
      });
    } else {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setFormData((prevFormData) => {
      const updatedFuncoes = checked ? [...prevFormData.funcoes, value] : prevFormData.funcoes.filter((item) => item !== value);

      return {
        ...prevFormData,
        funcoes: updatedFuncoes,
      };
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const isPDF = selectedFile.name.toLowerCase().endsWith('.pdf');

      if (isPDF) {
        setFileName(selectedFile ? selectedFile.name : 'Selecione o arquivo...');
        setUploadedFile(selectedFile);
        setFormData({ ...formData, file: selectedFile });
      } else {
        setFileName('Selecione o arquivo...');
        setErrorMessage('formato do arquivo inválido, o arquivo enviado deve ser .pdf');
        setShowError(true);
      }
    } else {
      setUploadedFile(null);
      setFileName('Selecione o arquivo...');
    }
  };

  let pdfBlobUrl;

  const handleOpenDPFView = (filename) => {
    fetch(`${endpoints.apiServer()}/recrutamento/recruitmentFile/${filename}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        pdfBlobUrl = URL.createObjectURL(blob);
        setPDFFile(pdfBlobUrl);
        setOpenPDFView(true);
      })
      .catch((error) => console.error('Error fetching the PDF', error));
  };

  const handlCloseDPFView = () => {
    pdfBlobUrl = null;
    setOpenPDFView(false);
  };

  const handleZoomIn = () => {
    setScale(scale + 0.25);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.25);
  };

  const PdfViewer = () => {
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };

    return (
      <>
        <div>
          <h5>
            Ampliar:{' '}
            <button onClick={handleZoomIn}>
              <FaPlus />
            </button>
            <button onClick={handleZoomOut}>
              <FaMinus />
            </button>
          </h5>
        </div>
        <div>
          <Document
            file={PDFFile} // Endpoint to fetch the PDF file from the server
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page renderAnnotationLayer={false} key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} scale={scale} />
            ))}
          </Document>
          {numPages && (
            <p>
              Page {numPages} of {numPages}
            </p>
          )}
        </div>
      </>
    );
  };

  // const handleRemoveOcorrencia = (idToRemove) => {
  //   setOcorrencias((prevOcorrencias) => prevOcorrencias.filter((item) => item.id !== idToRemove));
  //   removeOcorrence(endpoints.ocorrencias(), 'DELETE', { id: idToRemove });
  // };
  // const handleAddOcorrencia = () => {
  //   if (newOcurrence.trim() !== '') {
  //     const data = { occurrence: newOcurrence, recrutamento_id: formData.id };
  //     addOcorrence(endpoints.ocorrencias(), 'POST', data);
  //     setNewOccurence('');
  //   }
  // };

  const locais = ['MARACÁS', 'POJUCA', 'CATU', 'MATA DE SÃO JOÃO', "DIAS D'ÁVILA", 'CAMAÇARI', 'SERGIPE/NOSSA SENHORA DO SOCORRO', 'SERGIPE/LARANJEIRAS', 'SERGIPE/ARACAJU', 'SIMÕES FILHO', 'OUTRO'];

  const funcoes = ['ELETRICISTA INDUSTRIAL', 'SOLDADOR', 'CALDEIREIRO', 'MECÂNICO INDUSTRIAL', 'VULCANIZADOR', 'PLANEJADOR DE OBRAS INDUSTRIAIS', 'TÉCNICO DE SEGURANÇA', 'ENCARREGADO DE OBRAS INDUSTRIAIS', 'OUTROS'];

  const status = [
    { id: 1, value: 'APROVADO', label: 'APROVADO' },
    { id: 2, value: 'REPROVADO', label: 'REPROVADO' },
    { id: 3, value: 'EM PROCESSO DE ADMISSÃO', label: 'EM PROCESSO DE ADMISSÃO' },
    { id: 4, value: 'ATIVO', label: 'ATIVO' },
    { id: 5, value: 'INATIVO', label: 'INATIVO' },
  ];

  const renderMenuItems = (row) => {
    return (
      <Box>
        <FontAwesomeIcon
          icon={faFilePdf}
          size='xl'
          onClick={(event) => {
            event.stopPropagation();
            // actionsFunctions[0](row.original.filename);
            handleOpenDPFView(row.original.filename);
          }}
          disabled={!(row.original.filename && row.original.filename.length > 0)}
        />
      </Box>
    );
  };

  return (
    <>
      <Sidebar />
      <div className='content' ref={elementRef}>
        <Modal show={openPDFView} onHide={handlCloseDPFView} centered size='xl'>
          <Form className='form-page'>
            <Modal.Header closeButton>
              <Modal.Title>Curriculo</Modal.Title>
            </Modal.Header>
            <Modal.Body>{PDFFile ? <PdfViewer /> : <p>Falha ao carregar Currívulo</p>}</Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Form>
        </Modal>

        {showAlertEdit && (
          <Alert key='success' variant='success'>
            Informação editada com sucesso!
          </Alert>
        )}
        {showAlert && (
          <Alert key='success' variant='success'>
            Informação cadastrada com sucesso!
          </Alert>
        )}
        <div className='header_modules'>
          <h2 className='product-title'>Informações de contato</h2>
          {/* {!error ? (
            !loading ? (
              <Button onClick={handleOpenDialog} className='btn-add-roles btn-success'>
                Adicionar informações
              </Button>
            ) : (
              <Button className='btn-add-roles btn-success' disabled>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span>Loading...</span>
              </Button>
            )
          ) : (
            <></>
          )}   */}
        </div>
        <p className='link-form'>
          Link para o formulário:{' '}
          <a href={`${endpoints.baseApplicationUrl()}/pessoas/formulario`} target='_blank' rel='noreferrer'>
            Acessar
          </a>
        </p>
        <div className='table_funcoes'>
          <Table
            elementHeight={height - 250}
            error={error}
            loading={loading}
            columns={columns}
            items={items}
            funcao={viewItem}
            initialState={{ density: 'compact', pagination: { pageSize: 100 } }}
            hasActions={true}
            actionRenderer={renderMenuItems}
            positionActionsColumn='last'
          />
        </div>
        <div>
          <Modal show={openDialog} onHide={handleCloseDialog} centered size='lg'>
            <Form onSubmit={handleSubmit} className='form-page'>
              <Modal.Header closeButton>{!isEditing ? <Modal.Title>Adicionar Informações</Modal.Title> : <Modal.Title>Editar Informações</Modal.Title>}</Modal.Header>
              <Modal.Body>
                <Row>
                  <Col lg={12}>
                    <Form.Group className='mb-3' controlId='formBasicNome'>
                      <Form.Label>Nome completo</Form.Label>
                      <Form.Control disabled value={formData.nome_completo} name='nome_completo' type='text' onChange={handleChangeValue} required />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} xs={12} md={12} lg={6}>
                    <Form.Group className='mb-3' controlId='formBasicEamil'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control disabled value={formData.email} name='email' type='text' onChange={handleChangeValue} required />
                    </Form.Group>
                  </Col>
                  <Col sm={12} xs={12} md={12} lg={6}>
                    <Form.Group className='mb-3' controlId='formBasicNome'>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control disabled value={formData.telefone} name='telefone' type='text' onChange={handleChangeValue} required />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId='formResidencia' as={Row}>
                      <Form.Label>Local de residência</Form.Label>
                      <Col sm={12} xs={12} md={12} className='radio-employee'>
                        {locais.map((option) => (
                          <Form.Check
                            disabled
                            inline
                            key={option}
                            type='radio'
                            label={option}
                            name='local_de_residencia'
                            value={option}
                            checked={formData.local_de_residencia !== null && formData.local_de_residencia === option}
                            onChange={handleChangeValue}
                            required
                          />
                        ))}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId='formFuncoesCk' as={Row}>
                      <Form.Label>Funções</Form.Label>
                      <Col sm={12} xs={12} md={12} className='radio-employee'>
                        {funcoes.map((option) => (
                          <Form.Check disabled key={option} type='checkbox' label={option} value={option} checked={formData.funcoes.includes(option)} onChange={handleCheckboxChange} />
                        ))}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col sm={12} xs={12} md={12} lg={6}>
                    <Form.Group className='mb-1' controlId='formBasicStatus'>
                      <SelectFieldFixedNew disabled={true} label='Status' setValue={handleChangeValue} value={formData.status} items={status} name='status' />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Form.Label>Currículo</Form.Label>
                  <Col sm={12} xs={12} md={12} lg={6}>
                    <Form.Control disabled type='file' hidden id='file-input' accept='.pdf' onChange={handleFileChange} />

                    <InputGroup className='mb-3'>
                      <Form.Control
                        disabled
                        type='text'
                        className='mr-2'
                        readOnly
                        accept='.pdf'
                        value={fileName}
                        onClick={() => {
                          setShowError(false);
                          document.getElementById('file-input').click();
                        }}
                      />
                      <Button
                        disabled
                        variant='outline-dark'
                        onClick={() => {
                          setShowError(false);
                          document.getElementById('file-input').click();
                        }}
                      >
                        Importar
                      </Button>
                    </InputGroup>
                    <span className='research-warning'>* Formato do currículo deve ser PDF</span>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1' controlId='formBasicNome'>
                      <Button
                        className='btn-primary'
                        onClick={() => {
                          handleOpenDPFView(formData.filename);
                        }}
                        // disabled={!(formData.filename && formData.filename.length > 0)}
                      >
                        Visualizar CV
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    {showError && (
                      <Form.Label
                        style={{
                          color: 'red',
                        }}
                      >
                        {errorMessage}
                      </Form.Label>
                    )}
                  </Col>
                </Row>
                <hr></hr>
                {/* <Row>
                  <Form.Label>Ocorrências</Form.Label>
                  <Col sm={10} xs={10} md={10} lg={10}>
                    <Form.Control
                      value={newOcurrence}
                      name="occurencie"
                      type="text"
                      onChange={(e) => {
                        setNewOccurence(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={2} xs={2} md={2} lg={2}>
                    <Button className="btn-success action-btn" onClick={handleAddOcorrencia}>
                      {" "}
                      Adicionar
                    </Button>
                  </Col>
                  <br></br>
                  <br></br>
                  <Col sm={12} xs={12} md={12} lg={12}>
                    {ocorrencias && ocorrencias.length > 0 && (
                      <ListGroup style={{ height: "124px", overflowY: "auto" }}>
                        {ocorrencias.map((ocorrencia) => (
                          <ListGroup.Item className="grupo-list-item" key={ocorrencia.id}>
                            {" "}
                            {ocorrencia.ocorrencia}{" "}
                            <Button className="btn-close " type="button" variant="danger" onClick={() => handleRemoveOcorrencia(ocorrencia.id)}></Button>{" "}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </Col>
                </Row> */}
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className='btn-controls'>
                  <Button className='btn-danger action-btn' onClick={handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button disabled className='btn-success action-btn' type='submit'>
                    {' '}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Recruitment;

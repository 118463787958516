import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button, Modal, Table } from 'react-bootstrap';

const ExcelXLSMViewer = ({ fileContent, fileName }) => {
  const [xlsmData, setXLSMData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    setModalIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const buffer = await fileContent.arrayBuffer();
        const data = new Uint8Array(buffer);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assume que há apenas uma planilha no arquivo XLSM
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Converte o conteúdo da planilha em formato de objeto legível
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setXLSMData(jsonData);

        // Abre automaticamente a modal ao carregar o conteúdo do XLSM
        setShowModal(true);
      } catch (error) {
        console.error('Erro ao processar o arquivo XLSM:', error);
      }
    };

    fetchData();
  }, [fileContent]);

  const downloadExcel = () => {
    const filename = fileName;
    const ws = XLSX.utils.json_to_sheet(xlsmData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsm', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });
    saveAs(blob, `${filename}.xlsm`);
  };

  return (
    <div>
      {xlsmData && (
        <Modal show={modalIsOpen} onHide={closeModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Conteúdo do Excel</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            <Table striped bordered hover>
              <thead>
                <tr>{xlsmData[0].map((header, index) => <th key={index}>{header}</th>)}</tr>
              </thead>
              <tbody>
                {xlsmData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => <td key={cellIndex}>{cell}</td>)}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Fechar
            </Button>
            <Button variant="primary" onClick={downloadExcel}>
              Download XLSM
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ExcelXLSMViewer;

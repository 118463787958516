import { useState, useEffect, useRef, useMemo } from 'react';

import { Alert, Button, Form, Modal, Spinner, Row, Col } from 'react-bootstrap';

import endpoints from '../modules/endpoints';

import Table from '../components/Table';
import SelectFieldFixedNew from '../components/selectFieldFixedNew';
import Sidebar from '../components/Sidebar';

import { useFetchPerformatic } from '../hooks/useFetchPerformatic';
import SelectFieldFetchWithId from '../components/selectFieldFetchWithId';
import { useNavigate } from 'react-router-dom';
import AddCollaborator from '../components/AddCollaborator/AddCollaborator';
import ModalDeleteColaborator from '../components/ModalDeleteColaborator/ModalDeleteColaborator';
import ModalVacationCollaborator from '../components/ModalVacationCollaborator/ModalVacationCollaborator';
import EditCollaboratorModal from '../components/EditCollaborator/EditCollaborator';

function Colaboradores() {

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const { data: items, httpConfig, loading, error, showAlert } = useFetchPerformatic(endpoints.colaboradores());
  const navigate = useNavigate();

  const initialFormData = {
    id: '',
    matricula: '',
    nome: '',
    status: '',
    filial: '',
    centro_de_custo: '',
    funcao_id: '',
    funcao_nome: '',
    tipo_de_contrato: '',
    tipo_de_trabalho: '',
    sexo: '',
    salario: '',
    nivel_funcao: '',
    data: null,
    categorizacao: '',
    data_de_nascimento: null,
    estado_civil: '',
    nome_do_conjuge: '',
    data_conjuge: null,
    quantidade_de_filhos: 0,
    // genero_filho_1: '',
    // nome_filho_01: '',
    // data_nascimento_01: '',
    // genero_filho_2: '',
    // nome_do_filho_02: '',
    // data_nascimento_02: '',
    // genero_filho_03: '',
    // nome_do_filho_03: '',
    // data_nascimento_03: '',
    // genero_de_filho_04: '',
    // nome_do_filho_04: '',
    // data_nascimento_04: '',
    // genero_do_filho_05: '',
    // nome_do_filho_05: '',
    // data_nascimento_05: '',
    formacao_1: '',
    status_1: '',
    nivel_1: '',
    formacao_2: '',
    status_2: '',
    nivel_2: '',
    descricao: '',
    experiencia_anterior: '',
    nivel_experiencia: '',
    tempo_experiencia: '',
    endereco: '',
    telefone: '',
    email: '',
    vendedor: 0,
  };

  const StatusList = [
    { id: 1, value: "EM CONTRATACAO", label: "EM CONTRATAÇÃO" },
    { id: 2, value: "ATIVO", label: "ATIVO" },
    { id: 3, value: "FERIAS", label: "FÉRIAS" },
    { id: 4, value: "INATIVO", label: "INATIVO" },
  ];
  
  const formatCurencie = (value) => {
    if (value && value !== null && value !== undefined) {
      const rawValue = value.toFixed(2).toString();
      const sanitizedValue = rawValue.replace(/[^0-9]/g, '');

      let formattedValue = '';

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, '').slice(0, -2);

        const dollarsWithSeparator = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        formattedValue = `${dollarsWithSeparator.length > 0 ? dollarsWithSeparator : '0'}.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      return formattedValue;
    } else {
      return null;
    }
  };

  const handleChangeValor = (e) => {
    const { name, value } = e.target;

    if (value === '') {
      setFormData({ ...formData, [name]: null });
    } else {
      const rawValue = value;
      const sanitizedValue = rawValue.replace(/[^0-9]/g, '');

      let formattedValue = '';

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, '').slice(0, -2);

        const dollarsWithSeparator = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        formattedValue = `${dollarsWithSeparator.length > 0 ? dollarsWithSeparator : '0'}.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      setFormData({ ...formData, [name]: formattedValue });
    }
  };

  const [formData, setFormData] = useState(initialFormData);

  
  const handleChangeFunction = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  

  const handleChangeStats = (value) => {
    const stat = 'status';

    setFormData((prevData) => ({ ...prevData, [stat]: value }));
    console.log(formData)
  };


  

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'funcao_id') {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      const newFormData = {
        ...formData,
        salario: formData.salario ? formData.salario.replace(/,/g, '') : null,
        
      };

      if (isEditing) {
        delete newFormData.codigo_pesquisas;
        delete newFormData.position;
        httpConfig(newFormData, 'PUT');
      } else {
        delete newFormData.id;
        delete newFormData.funcao_nome;
        httpConfig(newFormData, 'POST');
      }

      handleCloseDialog();
      setIsEditing(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'matricula',
        header: 'Matrícula',
        size: 30,
      },
      {
        accessorKey: 'nome',
        header: 'Nome',
        enableHiding: false,
        size: 30,
      },
      {
        accessorKey: 'position.nome',
        header: 'Função',
        size: 40,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 60,
      },
      {
        accessorKey: 'tipo_de_contrato',
        header: 'Tipo de contrato',
        size: 60,
      },
      {
        accessorKey: 'tipo_de_trabalho',
        header: 'Tipo de trabalho',
      },
      {
        accessorKey: 'telefone',
        header: 'Telefone',
      },
    ],
    []
  );

  function editItem(item) {
    setIsEditing(true);
    
    setFormData(item);

    const formattedValue = formatCurencie(item.salario);

    setFormData((prevFormData) => ({
      ...prevFormData,
      salario: formattedValue,
    }));

    handleOpenDialog();
  }

  const generoList = [
    { id: 1, value: 'MASCULINO', label: 'M' },
    { id: 2, value: 'FEMININO', label: 'F' },
  ];

  const vendedorStatus = [
    { id: 1, value: 0, label: 'N' },
    { id: 2, value: 1, label: 'S' },
  ];

  return (
    <>
      <Sidebar />
      
      <div className='content' ref={elementRef}>
        {showAlert && (
          <Alert key={showAlert.level} variant={showAlert.level}>
            {showAlert.message}
          </Alert>
        )}
        {mensagem && (
        <Alert variant={mensagem.includes('sucesso') ? 'success' : 'danger'} onClose={() => setMensagem('')} dismissible>
          {mensagem}
        </Alert>
      )}
        <div className='header_modules'>
        
          <h2 className='product-title'>COLABORADORES</h2>
          
        
          <AddCollaborator setMensagem = {setMensagem} />
          <ModalVacationCollaborator setMensagem={setMensagem}/>
          
             
            <ModalDeleteColaborator setMensagem={setMensagem}/>
        
             
          {/* <Button
                  onClick={() => {
                    navigate("/clientes/rhbi");
                  }}
                  className="btn-add-roles btn-primary"
                >
                  Métricas
                </Button> */}
        </div>
        <div className='table_funcoes'>
          <Table elementHeight={height - 200}  error={error} loading={loading} columns={columns} items={items} funcao={editItem} initialState={{ density: 'compact', pagination: { pageSize: 100 } }} />
        </div>
        <div>
        <EditCollaboratorModal
        show={openDialog}
        onHide={handleCloseDialog}
        isEditing={isEditing}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleChangeValor={handleChangeValor}
        handleChangeFunction={handleChangeStats}
        handleChangeOthers = {handleChangeFunction}
        formData={formData}
        StatusList={StatusList}
        vendedorStatus={vendedorStatus}
        generoList={generoList}
        
      />
          
        </div>
      </div>
    </>
  );
}

export default Colaboradores;

import { useState, useMemo, useEffect, useRef } from "react";

import {
  Alert,
  Button,
  Form,
  Modal,
  Accordion,
  Spinner,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import Table from "../components/Table";
import endpoints from "../modules/endpoints";
import { useParams, useNavigate } from "react-router-dom";
import { useLog } from "../hooks/useLog";
import Sidebar from "../components/Sidebar";
import SelectFieldFetchWithId from "../components/selectFieldFetchWithId";
import SelectFieldFixedNew from "../components/selectFieldFixedNew";
import { useFetchById } from "../hooks/useFetchById";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useFetchPerformatic } from "../hooks/useFetchPerformatic";
import CSVReader from 'react-csv-reader';
import XMLViewer from 'react-xml-viewer';
import ExcelViewer from "../components/ExcelViewer/ExcelViewer";
import PDFViewer from "../components/PDFViewer/PDFViewer";
import {format} from "date-fns";
import ExcelXLSMViewer from "../components/ExcelXLMSViewer/ExcelXLMSViewer";
import { saveAs } from 'file-saver';
function Propostas() {
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const { rid } = useParams();
  const navigate = useNavigate();
  const [authToken] = useState(localStorage.getItem("authToken"));
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [employeeToAddInList, setEmployeeToAddInList] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState("false");
  const [showServiceRanking, setShowServiceRanking] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Selecione o arquivo...");
  const [fileLoading, setFileLoading] = useState(false);

  const [showDimiAlert, setShowDimiAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [showalert, setShowAlert] = useState(false);
  const [acordeaoAberto, setAcordeaoAberto] = useState(false);

  const handleAcordeaoClick = () => {
    setAcordeaoAberto(!acordeaoAberto);
  };
  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.proposals());
  const {
    data: rankingEmployeeList,
    httpConfig: httpConfigRanking,
    loading: loadingRanking,
    error: errorRanking,
  } = useFetchById();

  const [modalAberto, setModalAberto] = useState(false);

  const handleAbrirModal = () => {
    setModalAberto(true);
  };

  const handleFecharModal = () => {
    setModalAberto(false);
    
  };

  const initialFormProposalFile = {
    filename: "",
    proposta_id: "",
  };

  const initialFormData = {
    id: "",
    cliente_id: "",
    cidade: "",
    estado: "",
    comprador: "",
    email: "",
    telefone: "",
    revisao: "0",
    tipo: "",
    data_solicitacao: null,
    data_limite_envio: null,
    hora_limite_envio: null,
    status: "RECEBIDO",
    status_finalizacao: "",
    numero_cotacao_ordem_servico: "",
    numero_cotacao_externo: "",
    servico: "",
    data_previsao_inicio: null,
    data_previsao_termino: null,
    cliente_empresa_responsavel: "VOLPE MANUTENÇÃO IND",
    cliente_tecnico_responsavel: "",
    equipe_designada: "",
    obs: "",
    valor: null,
    disciplina: "",
    ncg: null,
    retorno: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formFile, setFormFile] = useState(initialFormProposalFile);

  const reserFormData = () => {
    setFormData(initialFormData);
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "Selecione o arquivo...");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== "cliente_id") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    } else if (name === "hora_limite_envio") {
      setFormData({ ...formData, [name]: `${value}:00` });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChangeEmployee = (e) => {
    const { value } = e.target;
    setEmployeeToAddInList(value);
  };

  const handleAddRankingResearchEmployee = () => {
    httpConfigRanking(
      endpoints.pesquisaSaleService(),
      endpoints.pesquisaSaleService() + `/${formData.id}`,
      { proposta_id: formData.id, colaborador_id: employeeToAddInList },
      "POST"
    );
    setEmployeeToAddInList("");
  };

  const FileViewer = ({ fileContent, fileType, isViewerOpen, setIsViewerOpen }) => {
    switch (fileType) {
      case "application/pdf":
          return <PDFViewer fileContent={fileContent} isViewerOpen={isViewerOpen} setIsViewerOpen={setIsViewerOpen} />;
      case "xml":

        return <XMLViewer xml={fileContent} />;
      case "text/csv":
        
        return <CSVReader csv={fileContent} />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          // Utiliza o componente ExcelViewer
         
          return <ExcelViewer fileContent={fileContent} />;
      case "xlsm":
        return <ExcelXLSMViewer fileContent={fileContent} />;
      default:
        return <div>Tipo de arquivo não suportado.</div>;
    }
  };

  const ArquivosTable = ({ formData }) => {
    const [arquivosList, setArquivosList] = useState([]);
    const [loadingArquivos, setLoadingArquivos] = useState(false);
    const [errorArquivos, setErrorArquivos] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const columnsArquivos = useMemo(
      () => [
        {
          accessorKey: "filename",
          header: "Nome do arquivo",
          size: 100,
          minSize: 100,
        },
        {
          accessorKey: "id",
          header: "ID",
          size: 60,
          maxSize: 62,
        },
        {
          accessorKey: "created_at",
          header: "Data de criação",
          size: 60,
          maxSize: 62,
          
        },
        {
          accessorKey: "proposta.id",
          header: "ID da proposta",
          size: 60,
          maxSize: 62,
        },
      ],
      []
    );

    useEffect(() => {
      const fetchArquivos = async () => {
        try {
          setLoadingArquivos(true);

          const headers = {
            Authorization: `Bearer ${authToken}`,
          };

          const response = await fetch(
            `${endpoints.getProposalFiles()}${formData.id}`,
            {
              headers,
            }
          );

          if (!response.ok) {
            throw new Error(
              `Error: ${response.status} - ${response.statusText}`
            );
          }

          const data = await response.json();

          setArquivosList(data);
        } catch (error) {
          setErrorArquivos(error);
        } finally {
          setLoadingArquivos(false);
        }
      };

      fetchArquivos();
    }, [formData.id, authToken]);

    const handleFileClick = async (filename) => {
      try {
        setLoadingArquivos(true);
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const response = await fetch(
          `${endpoints.apiServer()}/proposalFiles/proposalFile/show/${filename}`,
          {
            headers,
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        
        
        const contentType = response.headers.get('Content-Type');
        
        const data = await response.blob();
       
        if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(data, filename);
        }else if (contentType === 'xlsm'){
          saveAs(data, filename);
        } else {
        setSelectedFile({
          filename,
          content: data,
          type: contentType,
        });
        setIsViewerOpen(true);
      }
      } catch (error) {
        setErrorArquivos(error);
      } finally {
        setLoadingArquivos(false);
      }
      
    };

    return (
      <div>
        {errorArquivos ? (
          <div>Error: {errorArquivos.message}</div>
        ) : (
          <>
            <div>
              <Table
                loading={loadingArquivos}
                columns={columnsArquivos}
                items={arquivosList}
                funcao={(item) => handleFileClick(item.filename)}
                initialState={{
                  density: "compact",
                  pagination: { pageSize: 3 },
                }}
                style={{ overflowY: "auto" }}
              />
              {loadingArquivos && <div>Carregando arquivo...</div>}
              {selectedFile && (
              <FileViewer
                fileContent={selectedFile.content}
                fileType={selectedFile.type}
                isViewerOpen={isViewerOpen}
                setIsViewerOpen={setIsViewerOpen}
                
              />
            )}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleChangeValor = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setFormData({ ...formData, [name]: null });
    } else {
      const rawValue = value;
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      setFormData({ ...formData, [name]: formattedValue });
    }
  };

  const formatCurencie = (value) => {
    if (value && value !== null && value !== undefined) {
      const rawValue = parseFloat(value).toFixed(2).toString();
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      return formattedValue;
    } else {
      return null;
    }
  };

  function editItem(item) {
    setIsEditing(true);
    setFormData(item);
    httpConfigRanking(
      endpoints.pesquisaSaleService() + `/${item.id}`,
      endpoints.pesquisaSaleService() + `/${item.id}`
    );

    const formattedValue = formatCurencie(item.valor);

    setFormData((prevFormData) => ({
      ...prevFormData,
      valor: formattedValue,
    }));

    const formattedNcg = formatCurencie(item.ncg);

    setFormData((prevFormData) => ({
      ...prevFormData,
      ncg: formattedNcg,
    }));

    const formattedRetorno = formatCurencie(item.retorno);

    setFormData((prevFormData) => ({
      ...prevFormData,
      retorno: formattedRetorno,
    }));

    handleOpenForm();
  }

  const handleOpenForm = () => {
    setOpenDialog(true);
  };

  const handleOpenNewSale = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    reserFormData();
    setIsEditing(false);
    setOpenDialog(false);
    setShowServiceRanking(false);
    setEmployeeToAddInList("");
    setAcordeaoAberto(false);
    localStorage.removeItem("prospectId");

    if (rid !== undefined) {
      navigate("/vendas");
    }
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      const newFormData = {
        ...formData,
        hora_limite_envio: formData.hora_limite_envio
          ? `${formData.hora_limite_envio.split(":")[0]}:${
              formData.hora_limite_envio.split(":")[1]
            }`
          : null,
        valor: formData.valor ? formData.valor.replace(/,/g, "") : null,
        ncg: formData.ncg ? formData.ncg.replace(/,/g, "") : null,
        retorno: formData.retorno ? formData.retorno.replace(/,/g, "") : null,
      };
      if (isEditing) {
        delete newFormData.client_razao_social;

        setFormData(newFormData);
        httpConfig(newFormData, "PUT");
      } else {
        delete newFormData.id;
        httpConfig(newFormData, "POST");
      }

      handleCloseDialog();

      setIsEditing(false);
    }
  };

  function renderStatus(status) {
    let statusClassName;
    if (status === "RECEBIDO") {
      statusClassName = "custom-badge venda-pedido-recebido";
    } else if (status === "EM ELABORAÇÃO") {
      statusClassName = "custom-badge venda-escopo-analise";
    } else if (status === "EM FILA") {
      statusClassName = "custom-badge venda-escopo-analise";
    } else if (status === "APRESENTADO") {
      statusClassName = "custom-badge venda-proposta-apresentada";
    } else if (status === "APRESENTADO") {
      statusClassName = "custom-badge venda-aprovado";
    } else if (status === "DECLINADO") {
      statusClassName = "custom-badge venda-declinada";
    } else {
      statusClassName = "STATUS INVÁLIDO";
    }
    return (
      <>
        <span className={statusClassName}>{status}</span>
      </>
    );
  }

  function renderStatusFinalizacao(status) {
    let statusClassName;
    if (status === "DECLINADA") {
      statusClassName = "custom-badge venda-declinada";
    } else if (status === "EM ANÁLISE") {
      statusClassName = "custom-badge venda-em-analise";
    } else if (status === "REPROVADA") {
      statusClassName = "custom-badge venda-reprovada";
    } else if (status === "CANCELADA") {
      statusClassName = "custom-badge venda-cancelada";
    } else if (status === "APROVADA") {
      statusClassName = "custom-badge venda-aprovada";
    } else {
      statusClassName = "STATUS INVÁLIDO";
    }
    return (
      <>
        <span className={statusClassName}>{status}</span>
      </>
    );
  }

  const columns = useMemo(
    () => [
      {
        // accessorFn: (row) => row.id,
        accessorKey: "id",
        header: "Cod.",
        enableHiding: true,
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: "cliente.razao_social",
        header: "Cliente",
        size: 30,
        maxSize: 30,
      },
      {
        // accessorFn: (row) => row.status,
        accessorKey: "status",
        header: "Status do pedido",
        enableHiding: false,
        size: 34,
        maxSize: 34,

        Cell: ({ cell }) => renderStatus(cell.getValue()),
      },
      {
        // accessorFn: (row) => row.status_finalizacao,
        accessorKey: "status_finalizacao",
        header: "Status finalização",
        enableHiding: false,
        size: 38,
        maxSize: 38,
        muiTableBodyCellProps: {
          align: "center",
        },

        Cell: ({ cell }) => renderStatusFinalizacao(cell.getValue()),
      },

      {
        // accessorFn: (row) => row.tipo,
        accessorKey: "tipo",
        header: "Tipo",
        size: 6,
        maxSize: 6,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        // accessorFn: (row) => row.data_solicitacao,
        accessorKey: "data_solicitacao",
        header: "Data solicitação",
        size: 30,
        maxSize: 30,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        // accessorFn: (row) => row.data_limite_envio,
        accessorKey: "data_limite_envio",
        header: "Data limite envio",
        size: 30,
        maxSize: 30,
        // muiTableHeadCellProps: {
        //   align: 'center',
        // },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorFn: (row) => row.numero_cotacao_ordem_servico,
        accessorKey: "numero_cotacao_ordem_servico",
        header: "Cotação/OS",
        size: 32,
        maxSize: 32,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        // accessorFn: (row) => row.servico,
        accessorKey: "servico",
        header: "Serviço",
        size: 120,
        maxSize: 200,
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()} placement="top" arrow>
            <div style={{ cursor: "pointer" }}>{cell.getValue()}</div>
          </Tooltip>
        ),
      },
      {
        // accessorFn: (row) => row.data_previsao_inicio,
        accessorKey: "data_previsao_inicio",
        header: "Data início",
        size: 30,
      },
      {
        // accessorFn: (row) => row.data_previsao_termino,
        accessorKey: "data_previsao_termino",
        header: "Data termino",
        size: 30,
      },
      {
        accessorKey: "cliente_empresa_responsavel",
        // accessorFn: (row) => row.cliente_empresa_responsavel,
        header: "Empresa responsável",
        size: 30,
      },
      {
        accessorKey: "cliente_tecnico_responsavel",
        // accessorFn: (row) => row.cliente_tecnico_responsavel,
        header: "Técnico responsável",
        size: 30,
      },
    ],
    []
  );

  const columnsRanking = useMemo(
    () => [
      {
        accessorKey: "colaborador.nome",
        header: "Colaborador",
        size: 100,
        minSize: 100,
      },
      {
        accessorKey: "total",
        header: "Total",
        size: 25,
        maxSize: 25,
      },
      {
        accessorKey: "relacionamento",
        header: "Re.",
        size: 14,
        maxSize: 14,
      },
      {
        accessorKey: "complexidade_tecnica",
        header: "C.T",
        size: 10,
        maxSize: 12,
      },
      {
        accessorKey: "concorrencia",
        header: "Con.",
        size: 12,
        maxSize: 12,
      },
      {
        accessorKey: "necessidade_do_cliente",
        header: "N.C",
        size: 30,
        maxSize: 32,
      },
      {
        accessorKey: "chance_de_fechar",
        header: "C.F",
        size: 30,
        maxSize: 32,
      },
    ],
    []
  );

  const tiposList = [
    { id: 1, value: "HH", label: "HH" },
    { id: 2, value: "PF", label: "PF" },
  ];

  const statusList = [
    { id: 1, value: "RECEBIDO", label: "RECEBIDO" },
    { id: 2, value: "EM FILA", label: "EM FILA" },
    { id: 3, value: "DECLINADO", label: "DECLINADO" },
    { id: 4, value: "EM ELABORAÇÃO", label: "EM ELABORAÇÃO" },
    { id: 5, value: "APRESENTADO", label: "APRESENTADO" },
  ];

  const statusClientsList = [
    { id: 1, value: "DECLINADA", label: "DECLINADA" },
    { id: 2, value: "EM FILA", label: "EM FILA" },
    { id: 3, value: "EM ANÁLISE", label: "EM ANÁLISE" },
    { id: 4, value: "APROVADA", label: "APROVADA" },
    { id: 5, value: "REPROVADA", label: "REPROVADA" },
    { id: 6, value: "CANCELADA", label: "CANCELADA" },
  ];
  const disciplinaList = [
    { id: 1, value: "ELÉTRICA", label: "ELÉTRICA" },
    { id: 2, value: "MECÂNICA", label: "MECÂNICA" },
    { id: 3, value: "CIVIL", label: "CIVIL" },
    { id: 4, value: "OUTROS", label: "OUTROS" },
  ];

  const estadosList = [
    { id: 1, value: "AC", label: "AC" },
    { id: 2, value: "AL", label: "AL" },
    { id: 3, value: "AP", label: "AP" },
    { id: 4, value: "AM", label: "AM" },
    { id: 5, value: "BA", label: "BA" },
    { id: 6, value: "CE", label: "CE" },
    { id: 7, value: "DF", label: "DF" },
    { id: 8, value: "ES", label: "ES" },
    { id: 9, value: "GO", label: "GO" },
    { id: 10, value: "MA", label: "MA" },
    { id: 11, value: "MT", label: "MT" },
    { id: 12, value: "MS", label: "MS" },
    { id: 13, value: "MG", label: "MG" },
    { id: 14, value: "PA", label: "PA" },
    { id: 15, value: "PB", label: "PB" },
    { id: 16, value: "PR", label: "PR" },
    { id: 17, value: "PE", label: "PE" },
    { id: 18, value: "PI", label: "PI" },
    { id: 19, value: "RJ", label: "RJ" },
    { id: 20, value: "RN", label: "RN" },
    { id: 21, value: "RS", label: "RS" },
    { id: 22, value: "RO", label: "RO" },
    { id: 23, value: "RR", label: "RR" },
    { id: 24, value: "SC", label: "SC" },
    { id: 25, value: "SP", label: "SP" },
    { id: 26, value: "SE", label: "SE" },
    { id: 27, value: "TO", label: "TO" },
  ];

  const renderMenuItems = (row) => {
    return (
      <Box>
        <FontAwesomeIcon
          icon={faCopy}
          size="xl"
          onClick={(event) => {
            // navigator.clipboard.writeText(
            //   endpoints.baseApplicationUrl() +
            //     `/clientes/pesquisaServico/${formData.id}/${row.original.colaborador_id}`
            // );
            const url = endpoints.baseApplicationUrl() +
          `/clientes/pesquisaServico/${formData.id}/${row.original.colaborador_id}`;
          window.open(url, '_blank');
            event.stopPropagation();
            event.stopPropagation();
          }}
        />
      </Box>
    );
  };

  const handleSubmitFile = async (e) => {
    setShowError(false);

    if (e && e.preventDefault) {
      e.preventDefault();

      if (!file) {
        setErrorMessage("Por favor, selecione um arquivo");
        setShowError(true);
        return;
      }

      const proposalFiles = new FormData();
      proposalFiles.append("file", file);
      

      try {
        setFileLoading(true);

        // Upload do arquivo
        const responseUpload = await fetch(endpoints.uploadProposalFile(), {
          method: "POST",
          body: proposalFiles,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (responseUpload.ok) {
          const respUpload = await responseUpload.json();

          // Setar o atributo filename de formFile
          const updatedFormFile = {
            ...formFile,
            filename: respUpload.filename,
            proposta_id: formData.id && formData.id.toString().padStart(6, "0"),
          };
          setFormFile(updatedFormFile);

          // Enviar outra requisição com a rota "/save"

          try {
            const responseSave = await fetch(endpoints.saveProposalFile(), {
              method: "POST",
              body: JSON.stringify(updatedFormFile),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
              },
            });

            if (responseSave.ok) {
              const respSave = await responseSave.json();

              if (!respSave.error) {
                setShowAlert(true);
              } else {
                setShowDimiAlert(true);
              }

              handleFecharModal();
              setFileLoading(false);
            } else {
              setFileLoading(false);
              setShowError(true);
              setErrorMessage(
                "Ocorreu um erro ao salvar o arquivo no servidor!"
              );
            }
          } catch (errorSave) {
            setFileLoading(false);
            setShowError(true);
            setErrorMessage(
              "Ocorreu um erro ao enviar a segunda requisição para o servidor!"
            );
            console.error("Error sending save request:", errorSave);
          }
        } else {
          setFileLoading(false);
          setShowError(true);
          setErrorMessage(
            "Ocorreu um erro ao enviar o arquivo para o servidor!"
          );
        }
      } catch (errorUpload) {
        setFileLoading(false);
        setShowError(true);
        setErrorMessage("Ocorreu um erro ao fazer o upload do arquivo!");
        console.error("Error uploading file:", errorUpload);
      }
    }

    setTimeout(() => {
      setShowAlert(false);
      setShowAlertEdit(false);
    }, 5000);
  };

  useEffect(() => {
    setModalIsOpen(isViewerOpen);  // Atualize o estado quando isViewerOpen mudar
  }, [isViewerOpen]);
  return (
    <>
      <Sidebar />
      <div className="content" ref={elementRef}>
        {showAlert && (
          <Alert key={showAlert.level} variant={showAlert.level}>
            {showAlert.message}
          </Alert>
        )}

        <div className="header_modules">
          <h2 className="product-title">PROPOSTAS</h2>
          {!error ? (
            !loading ? (
              <>
                <Button
                  onClick={handleOpenNewSale}
                  className="btn-add-roles btn-success"
                >
                  Nova Proposta
                </Button>
                {/* <Button
                  onClick={() => {
                    navigate("/clientes/salesbi");
                  }}
                  className="btn-add-roles btn-primary"
                >
                  Visão geral 
                </Button>
                <Button
                  onClick={() => {
                    navigate("/clientes/funil-vendas");
                  }}
                  className="btn-add-roles btn-primary"
                >
                  Funil de vendas
                </Button>
                <Button
                  onClick={() => {
                    navigate("/clientes/mercado-clientes");
                  }}
                  className="btn-add-roles btn-primary"
                >
                  Mercado e clientes
                </Button> */}
                <Button
                  onClick={() => {
                    navigate("/clientes/proposalsRanking");
                  }}
                  className="btn-add-roles btn-danger"
                >
                  Mesa de Propostas
                </Button>
              </>
            ) : (
              <Button className="btn-add-roles btn-success" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>Loading...</span>
              </Button>
            )
          ) : null}
        </div>

        <div className="table_funcoes">
          <Col xs={12}>
            <Table
              elementHeight={height - 200}
              error={error}
              loading={loading}
              columns={columns}
              items={items}
              funcao={editItem}
              initialState={{
                columnVisibility: {
                  id: false,
                  Tipo: false,
                  data_previsao_inicio: false,
                  data_previsao_termino: false,
                  cliente_tecnico_responsavel: false,
                  cliente_empresa_responsavel: false,
                  data_solicitacao: false,
                },
                density: "compact",
                pagination: { pageSize: 100 },
              }}
            />
          </Col>
        </div>

        <div>
          <Modal
            show={openDialog}
            onHide={handleCloseDialog}
            centered
            size="xl"
          >
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                {!isEditing ? (
                  <Modal.Title>Nova Proposta</Modal.Title>
                ) : (
                  <Modal.Title>
                    Proposta:{" "}
                    {formData.id && formData.id.toString().padStart(6, "0")}
                  </Modal.Title>
                )}
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="form">
                  <Row className="g-2">
                    <Col xs={2}>
                      <SelectFieldFixedNew
                        label="Disciplina"
                        setValue={handleChange}
                        value={formData.disciplina}
                        items={disciplinaList}
                        placeholder="Selecione"
                        name={"disciplina"}
                        required={true}
                      />
                    </Col>
                    <Col xs={5}>
                      <SelectFieldFetchWithId
                        label="Cliente"
                        funcao={handleChange}
                        loadedVal={formData.cliente_id}
                        path="/customers"
                        external_value={"razao_social"}
                        fieldId={"cliente_id"}
                        required={true}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.cidade}
                        name="cidade"
                        onChange={(e) => {
                          e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                      />
                    </Col>
                    <Col xs={1}>
                      <SelectFieldFixedNew
                        label="Estado"
                        setValue={handleChange}
                        value={formData.estado}
                        items={estadosList}
                        placeholder={null}
                        name={"estado"}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col xs={5}>
                      <Form.Label>Comprador</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.comprador}
                        name="comprador"
                        onChange={(e) => {
                          e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={formData.email}
                        name="email"
                        onChange={(e) => {
                          e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.telefone}
                        name="telefone"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col>
                      <SelectFieldFixedNew
                        label="Status do pedido"
                        setValue={handleChange}
                        value={formData.status}
                        items={statusList}
                        name={"status"}
                      />
                    </Col>
                    <Col xs={1}>
                      <SelectFieldFixedNew
                        label="Tipo"
                        setValue={handleChange}
                        value={formData.tipo}
                        items={tiposList}
                        placeholder={null}
                        name={"tipo"}
                        required
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Label>Data da solicitação</Form.Label>
                      <Form.Control
                        type="date"
                        value={formData.data_solicitacao}
                        name="data_solicitacao"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Label>Data Limite de envio</Form.Label>
                      <Form.Control
                        type="date"
                        value={formData.data_limite_envio}
                        name="data_limite_envio"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Label>Hora Limite de envio</Form.Label>
                      <Form.Control
                        type="time"
                        value={formData.hora_limite_envio}
                        name="hora_limite_envio"
                        onChange={handleChange}
                      />
                    </Col>

                    <Col xs={1}>
                      <Form.Label>Revisão</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        value={formData.revisao}
                        name="revisao"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col xs={2}>
                      <Form.Label>N° Cotação interno</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.numero_cotacao_ordem_servico}
                        name="numero_cotacao_ordem_servico"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Serviço</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.servico}
                        name="servico"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Label>N° Cotação Externo</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.numero_cotacao_externo}
                        name="numero_cotacao_externo"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col xs={2}>
                      <Form.Label>Data prevista início</Form.Label>
                      <Form.Control
                        type="date"
                        value={formData.data_previsao_inicio}
                        name="data_previsao_inicio"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Label>Data prevista término</Form.Label>
                      <Form.Control
                        type="date"
                        value={formData.data_previsao_termino}
                        name="data_previsao_termino"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Label>Empresa responsável</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.cliente_empresa_responsavel}
                        name="cliente_empresa_responsavel"
                        onChange={handleChange}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Label>Técnico responsável</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.cliente_tecnico_responsavel}
                        name="cliente_tecnico_responsavel"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col xs={2}>
                      <Form.Label>Valor</Form.Label>
                      <Form.Control
                        type="text"
                        value={formData.valor}
                        name="valor"
                        onChange={handleChangeValor}
                        className="text-end"
                        placeholder="0.00"
                      />
                    </Col>
                    <Col xs={3}>
                      <SelectFieldFixedNew
                        label="Status de finalização"
                        setValue={handleChange}
                        value={formData.status_finalizacao}
                        items={statusClientsList}
                        placeholder="Selecione o status"
                        name={"status_finalizacao"}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Form.Label>Equipe</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      type="text"
                      value={formData.equipe_designada}
                      name="equipe_designada"
                      onChange={(e) => {
                        e.target.value.toUpperCase();
                        handleChange(e);
                      }}
                    />
                  </Row>

                  <Row
                    className="g-2"
                    style={{
                      paddingTop: "40px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Col xs={13}>
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class={`accordion-button ${
                                acordeaoAberto ? "" : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data--bs-target="#collapseOne"
                              aria-expanded={acordeaoAberto ? "true" : "false"}
                              aria-controls="collapseOne"
                              onClick={handleAcordeaoClick}
                            >
                              <Form.Label>Arquivos da Proposta</Form.Label>
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class={`accordion-collapse collapse ${
                              acordeaoAberto ? "show" : ""
                            }`}
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <ArquivosTable formData={formData} />
                              <Row
                                className="g-2"
                                style={{
                                  paddingTop: "20px",
                                  paddingBottom: "20px",
                                }}
                              >
                                <Col xs={12} className="d-flex justify-content-center">
                                  <Button
                                    onClick={handleAbrirModal}
                                    className="btn-add-roles btn-success"
                                    
                                    
                                  >
                                    Importar arquivo
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col
                      style={{
                        paddingTop: "30px",
                        paddingRight: "35px",
                      }}
                    >
                      <div>
                        <Modal
                          show={modalAberto}
                          onHide={handleFecharModal}
                          centered
                        >
                          <Form
                            onSubmit={handleSubmitFile}
                            className="form-page"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Enviar Arquivo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form.Control
                                type="file"
                                hidden
                                id="file-input"
                                accept=".xml, .pdf, .csv, .xlsx, .xlsm"
                                onChange={onFileChange}
                              />

                              {!fileLoading ? (
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    type="text"
                                    className="mr-2"
                                    readOnly
                                    value={fileName}
                                    onClick={() => {
                                      setShowError(false);
                                      document
                                        .getElementById("file-input")
                                        .click();
                                    }}
                                  />
                                  <Button
                                    variant="outline-dark"
                                    onClick={() => {
                                      setShowError(false);
                                      document
                                        .getElementById("file-input")
                                        .click();
                                    }}
                                  >
                                    Importar
                                  </Button>
                                </InputGroup>
                              ) : (
                                <>
                                  {" "}
                                  Registrando dados, aguarde...
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                              {showError && (
                                <Form.Label style={{ color: "red" }}>
                                  {errorMessage}
                                </Form.Label>
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Form.Group className="btn-controls">
                                <Button
                                  className="btn-danger action-btn"
                                  onClick={handleFecharModal}
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  onClick={handleSubmitFile}
                                  className="btn-success action-btn"
                                  type="submit"
                                >
                                  Enviar
                                </Button>
                              </Form.Group>
                            </Modal.Footer>
                          </Form>
                        </Modal>
                      </div>
                    </Col>
                  </Row>

                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    type="text"
                    value={formData.obs}
                    name="obs"
                    onChange={(e) => {
                      e.target.value.toUpperCase();
                      handleChange(e);
                    }}
                  />
                </Form.Group>
                <hr></hr>
                {showServiceRanking ? (
                  <>
                    <Form.Label>Classificação de serviço</Form.Label>
                    <Row>
                      <Col xs={4}>
                        <Form.Label>NCG</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.ncg}
                          name="ncg"
                          onChange={handleChangeValor}
                          className="text-end"
                          placeholder="0.00"
                        />
                      </Col>
                      <Col xs={3}>
                        <Form.Label>Retorno</Form.Label>
                        <Form.Control
                          type="text"
                          value={formData.retorno}
                          name="retorno"
                          onChange={handleChangeValor}
                          className="text-end"
                          placeholder="0.00"
                        />
                      </Col>
                    </Row>
                    <Row className="g-2">
                      <Col xs={12}>
                        <InputGroup className="mb-3">
                          <SelectFieldFetchWithId
                            label="Colaborador"
                            funcao={handleChangeEmployee}
                            external_value={"nome"}
                            loadedVal={employeeToAddInList}
                            path="/collaborators/sellers"
                            fieldId={employeeToAddInList}
                            placeholder={"selecione um vendedor"}
                          />
                          {
                            <Button
                              disabled={
                                employeeToAddInList === "" ||
                                (rankingEmployeeList &&
                                  rankingEmployeeList.some(
                                    (item) =>
                                      item.colaborador_id ===
                                      employeeToAddInList
                                  ))
                              }
                              type="button"
                              variant="success"
                              className="btn"
                              onClick={handleAddRankingResearchEmployee}
                            >
                              Add
                            </Button>
                          }
                        </InputGroup>
                        <div className="table_services_ranking">
                        <Table
                error={errorRanking}
                loading={loadingRanking}
                columns={columnsRanking}
                items={rankingEmployeeList}
                funcao={(item) => {
                  //   navigator.clipboard.writeText(
                  //     endpoints.pesquisaSaleService() +`/${ID}`

                  //   );
                  const url =
                    endpoints.baseApplicationUrl() +
                    `/clientes/pesquisaServico/${formData.id}/${item.colaborador_id}`;
                  window.open(url, "_blank");
                }}
                initialState={{
                  columnVisibility: {},
                  density: "compact",
                  pagination: { pageSize: 100 },
                }}
                hasActions={false}
                actionRenderer={renderMenuItems}
                positionActionsColumn="last"
              />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Button
                    type="button"
                    variant="success"
                    className="btn"
                    onClick={() => {
                      setShowServiceRanking(true);
                    }}
                  >
                    Mostrar classificação
                  </Button>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className="btn-controls">
                  <Button
                    className="btn-danger action-btn"
                    onClick={handleCloseDialog}
                  >
                    Cancelar
                  </Button>
                  <Button className="btn-success action-btn" type="submit">
                    {" "}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Propostas;

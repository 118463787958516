import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import RankingTable from "../rankingTable/RankingTable";

const ProposalModal = (props) => {
  const {
    numeroProposta,
    nomeEmpresa,
    cliente_id,
    disciplina,
    revisao,
    tipo,
    data_solicitacao,
    data_limite_envio,
    hora_limite_envio,
    status,
    email,
    status_finalizacao,
    num_cot_os,
    servico,
    data_prev_inicio,
    data_prev_termino,
    cli_empresa_responsavel,
    Cli_tecnico_responsavel,
    equipe_designada,
    obs,
    valor,
    retorno,
    num_cot_externo,
    cidade,
    estado,
    telefone,
    roi,
    ncg,
    nomeComprador,
    closeModal,
    showModal,
  } = props;

  const [showTableRanking, setShowTableRanking] = useState(false);
  
  const handleViewPDF = () => {
    // Implemente a lógica para visualizar informações em PDF aqui
  };
  

  return (
    <>
      <Modal show={showModal} onHide={closeModal} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Número da Proposta: {numeroProposta}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="form">
            <Row className="g-2">
              <Col xs={4} >
                <p><b>Nome da Empresa: </b>{nomeEmpresa}</p>
                <p><b>Estado: </b> {estado}</p>
                <p><b>NCG: </b>{ncg}</p>
                <p><b>Núm. Cot. Ordem de serviço : </b>{num_cot_os}</p>
              </Col>
              <Col xs={4}>
                <p><b>Nome do Comprador: </b>{nomeComprador}</p>
                <p><b>Cidade: </b> {cidade}</p>
                <p><b>ROI: </b>{roi}</p>
                <p><b>Telefone: </b>{telefone}</p>
              </Col>
              <Col xs={4}>
                <p><b>Data limite de envio: </b>{data_limite_envio}</p>
                <p><b>Hora limite de envio: </b>{hora_limite_envio}</p>
                <p><b>Data de previsão de início: </b>{data_prev_inicio}</p>
                <p><b>Data de previsão de término: </b>{data_prev_termino}</p>
                
              </Col>    
            </Row>
            <Row className="g-2">
                   
              <Col xs={4}>
                <p><b>Disciplina: </b>{disciplina}</p>
                <p><b>Valor: </b> {valor}</p>
                
              </Col>
              <Col xs={4}>
              <p><b>Tipo: </b>{tipo}</p>
              <p><b>Retorno: </b> {retorno}</p>
              </Col>
              <Col xs={4}>
                <p><b>Data da solicitação: </b> {data_solicitacao}</p>
                <p><b>Revisão: </b>{revisao}</p>
              </Col>
              </Row>
              <Row className="g-2">
              <Col xs={6}>
                <p><b>Serviço : </b>{servico}</p>
                <p><b>E-mail :</b> {email}</p>
              </Col>
              
              <Col>
              <p><b> Número de Cotação Externo: </b>{num_cot_externo}</p>
              <p><b> Equipe Designada: </b>{equipe_designada}</p>  
              </Col>
             
            </Row>
            
            <Row className="g-2">
              <Col xs = {6}>
                <p><b>Empresa Responsável: </b>{cli_empresa_responsavel}</p>
                <p><b>Técnico Responsável: </b>{Cli_tecnico_responsavel}</p>
              </Col>
              <Col xs = {6}>
              <p><b>OBS: </b>{obs}</p>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
        <RankingTable id={numeroProposta} colid={cliente_id} showTableRanking={showTableRanking} setShowTableRanking={setShowTableRanking}></RankingTable>
        <div className="card-actions">
       
        <div className='button-container'>
       
       
        </div>
      </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProposalModal;

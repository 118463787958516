import { useState, useEffect, useRef, useMemo } from 'react';

import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';

import Table from '../components/Table';
import endpoints from '../modules/endpoints';
import Sidebar from '../components/Sidebar';
import { useFetchPerformatic } from '../hooks/useFetchPerformatic';

function Roles() {

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { data: items, httpConfig, loading, error, showAlert } = useFetchPerformatic(endpoints.positions());

  const initialFormData = {
    nome: '',
    descricao: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      if (isEditing) {
        httpConfig(formData, 'PUT');
      } else {
        httpConfig(formData, 'POST');
      }
      setIsEditing(false);
      handleCloseDialog();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.nome,
        header: 'Nome',
      },
      {
        accessorFn: (row) => row.descricao,
        header: 'Descrição',
      },
    ],
    []
  );

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toUpperCase() });
  };

  function viewItem(item) {
    setIsEditing(true);
    setFormData(item);
    handleOpenDialog();
  }

  return (
    <>
      <Sidebar />
      <div className='content' ref={elementRef}>
        {showAlert && (
          <Alert key={showAlert.level} variant={showAlert.level}>
            {showAlert.message}
          </Alert>
        )}
        <div className='header_modules'>
          <h2 className='product-title'>FUNÇÕES</h2>
          {!error ? (
            !loading ? (
              <Button onClick={handleOpenDialog} className='btn-add-roles btn-success'>
                Nova Função
              </Button>
            ) : (
              <Button className='btn-add-roles btn-success' disabled>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span>Loading...</span>
              </Button>
            )
          ) : null}
        </div>
        <div className='table_funcoes'>
          <Table elementHeight={height - 200} error={error} loading={loading} columns={columns} items={items} funcao={viewItem} initialState={{ density: 'compact', pagination: { pageSize: 100 } }} />
        </div>
        <div>
          <Modal show={openDialog} onHide={handleCloseDialog} centered>
            <Form onSubmit={handleSubmit} className='form-page'>
              <Modal.Header closeButton>{!isEditing ? <Modal.Title>Nova Função</Modal.Title> : <Modal.Title>Editar Função</Modal.Title>}</Modal.Header>
              <Modal.Body>
                <Form.Group className='mb-3' controlId='formBasicNome'>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control value={formData.nome} name='nome' type='text' onChange={handleChangeValue} />
                </Form.Group>
                <Form.Group className='mb-3' controlId='formBasicDescricao'>
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control type='text' value={formData.descricao} name='descricao' onChange={handleChangeValue} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className='btn-controls'>
                  <Button className='btn-danger action-btn' onClick={handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button className='btn-success action-btn' type='submit'>
                    {' '}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Roles;

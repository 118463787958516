import { useState, useEffect } from "react";

export const useLog = (url) => {
  const [response, setResponse] = useState(null);
  const [data, setData] = useState(null);
  const [authToken] = useState(localStorage.getItem("authToken"));

  const sendLog = (data) => {
    setData(data);
  };

  useEffect(() => {
    const httpRequest = async () => {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      });
      const json = await res.json();
      setResponse(json);
    };
    if (data !== null) {
      httpRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, url]);

  return { sendLog, response };
};

import { Button, Image, Accordion } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useRequest } from '../hooks/useRequest';
import { useEffect, useState } from 'react';
import endpoints from '../modules/endpoints';
import ReportModal from './ReportModal/ReportModal';

function Sidebar() {
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState([]);
  const { sendRequest, response } = useRequest();
  const [showSide, setShowSide] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    sendRequest(endpoints.userRoles(), 'GET');
  }, []);

  useEffect(() => {
    if (response) {
      const responseRoles = JSON.parse(response.roles);
      setUserRoles(responseRoles);
      setShowSide(true);
    }
  }, [response]);

  function showMenuItem(path, label) {
    return userRoles.includes('admin') || userRoles.includes(path.split('/')[1]) ? (
      <NavLink to={`/${path}`} className='menu-i'>
        <div className='sidebar-menu-item'>
          {label}
          <br />
        </div>
      </NavLink>
    ) : null;
  }

  function handleLogout() {
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }

  // Determine the first accessible page based on user roles
  function getFirstAccessiblePage() {
    if (userRoles.includes('admin')) {
      return '/clientes/propostas';
    } else if (userRoles.includes('clientes')) {
      return '/clientes/registros';
    } else if (userRoles.includes('pessoas')) {
      return '/pessoas/recrutamentos';
    } else if (userRoles.includes('administracao')) {
      return '/adm/medicoes';
    } else {
      // Default fallback
      return '/';
    }
  }

  return (
    showSide && (
      <div bg='dark' variant='dark' expand='lg' className='sidebar'>
        <div className='sidebar-header'>
          <div className='flex-column'>
            <Image src='https://grupovolpe.ind.br/wp-content/uploads/2022/11/Logo-GRUPO-VOLPE-PNG.png' className='sidebar-logo' />
            <hr />
            <Accordion defaultActiveKey={expandedSection}
            style={{ '--bs-accordion-bg': '#c4c4c4' }} >
              <Accordion.Item eventKey="clientes" >
                <Accordion.Header onClick={() => setExpandedSection(expandedSection === 'clientes' ? null : 'clientes')}>
                  <h4 className='sidebar-header-text'>CLIENTES</h4>
                </Accordion.Header>
                <Accordion.Body>
                  {showMenuItem('clientes/registros', 'Clientes')}
                  {showMenuItem('clientes/propostas', 'Propostas') || (
                    <NavLink to={getFirstAccessiblePage()} className='menu-i'>
                      <div className='sidebar-menu-item'>
                        Ir para a primeira página disponível
                        <br />
                      </div>
                    </NavLink>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="pessoas">
                <Accordion.Header onClick={() => setExpandedSection(expandedSection === 'pessoas' ? null : 'pessoas')}>
                  <h4 className='sidebar-header-text'>PESSOAS</h4>
                </Accordion.Header>
                <Accordion.Body>
                  {showMenuItem('pessoas/recrutamentos', 'Banco CVs')}
                  {showMenuItem('pessoas/colaboradores', 'Colaboradores')}
                  {showMenuItem('pessoas/funcoes', 'Funções')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="adm">
                <Accordion.Header onClick={() => setExpandedSection(expandedSection === 'adm' ? null : 'adm')}>
                  <h4 className='sidebar-header-text'>ADM.</h4>
                </Accordion.Header>
                <Accordion.Body>
                  {showMenuItem('adm/medicoes', 'Medições')}
                  {/* {showMenuItem('adm/paineladministrativo', 'Painel Administrativo')} */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <div className='sidebar-content'>
          
        </div>
        {showReportModal && <ReportModal onClose={() => setShowReportModal(false)} />}
        <div className='sidebar-footer'>
          <hr />
          <div className='flex-column'>
            <NavLink to={`/clientes/reports`} className='menu-i'>
              <div className='sidebar-menu-item'>
                Painel de Problemas/Sugestões
                <br />
              </div>
            </NavLink>
            <NavLink to={`/userPanel`} className='menu-i'>
              <div className='sidebar-menu-item'>
                Painel do usuário
                <br />
              </div>
              
            </NavLink>
            <div className='flex-column'>
            <NavLink className='menu-i'>
              <div
                className='sidebar-menu-item'
                onClick={() => setShowReportModal(true)} style={{ cursor: 'pointer' }}
              >
                Reportar Erro
                <br />
              </div>
            </NavLink>
          </div>
            <NavLink className='menu-i' onClick={handleLogout}>
              <div className='sidebar-menu-item sair-btn'>
                Sair
                <br />
              </div>
            </NavLink>
            
          </div>
        </div>
      </div>
    )
  );
}

export default Sidebar;

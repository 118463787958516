import React, { useMemo, useState, useEffect } from "react";
import {
  Alert,
  Button,
  Form,
  Modal,
  Accordion,
  Spinner,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import Table from "../Table";
import { useFetchById } from "../../hooks/useFetchById";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useFetchPerformatic } from "../../hooks/useFetchPerformatic";
import endpoints from "../../modules/endpoints";
import SelectFieldFetchWithId from "../selectFieldFetchWithId";
import { useRequestUserInfos } from "../../hooks/useRequestUserInfos";
import ImageViewer from "../ImageViewer/ImageViewer";
import { saveAs } from 'file-saver';

const AnyFileTable = ({ formData }) => {
    const [arquivosList, setArquivosList] = useState([]);
    const [loadingArquivos, setLoadingArquivos] = useState(false);
    const [errorArquivos, setErrorArquivos] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [authToken] = useState(localStorage.getItem("authToken"));
    const [isViewerOpen, setIsViewerOpen] = useState("false");
    const { getUserInfos, userInfos } = useRequestUserInfos();

    

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      getUserInfos(authToken);
    }
  }, []);



    const columnsArquivos = useMemo(
      () => [
        {
          accessorKey: "filename",
          header: "Nome do arquivo",
          size: 100,
          minSize: 100,
        },
       
      
       
      ],
      []
    );

    useEffect(() => {
      const fetchArquivos = async () => {
        try {
            
          setLoadingArquivos(true);

          const headers = {
            Authorization: `Bearer ${authToken}`,
          };

          const response = await fetch(
            `${endpoints.listReportFiles()}${formData.id}`,
            {
              headers,
            }
          );

          if (!response.ok) {
            throw new Error(
              `Error: ${response.status} - ${response.statusText}`
            );
          }

          const data = await response.json();

          setArquivosList(data);
        } catch (error) {
          setErrorArquivos(error);
        } finally {
          setLoadingArquivos(false);
        }
      };

      fetchArquivos();
    }, [formData.id, authToken]);

    const handleFileClick = async (filename) => {
        
      try {
        setLoadingArquivos(true);
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };
        const fileIdWithoutDashes = filename.replace(/-/g, '');
        const response = await fetch(
            `${endpoints.showReportFile()}${filename}`,
          {
            headers,
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        
        
        const data = await response.blob();
        
        
        setSelectedFile({
          imageName: filename,
          content: data,
        });
        setIsViewerOpen(true);
      
      } catch (error) {
        setErrorArquivos(error);
      } finally {
        setLoadingArquivos(false);
      }
      
    };

    return (
      <div>
        {errorArquivos ? (
          <div>Error: {errorArquivos.message}</div>
        ) : (
          <>
            <div>
              <Table
                enablePagination={false}
                enableGlobalFilter={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                loading={loadingArquivos}
                columns={columnsArquivos}
                items={arquivosList}
                funcao={(item) => handleFileClick(item.filename)}
                initialState={{
                  density: "compact",
                  pagination: { pageSize: 3 },
                }}
                style={{ overflowY: "auto" }}
              />
              {loadingArquivos && <div>Carregando arquivo...</div>}
              {selectedFile && (
                <ImageViewer
              
                imageContent={selectedFile.content}
                imageName={selectedFile.imageName}
                isViewerOpen={isViewerOpen}
                setIsViewerOpen={setIsViewerOpen}
                
              />
            )}
            </div>
          </>
        )}
      </div>
    );
  };

  export default AnyFileTable;
import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useRequest } from "../../hooks/useRequest";
import endpoints from "../../modules/endpoints";
import { useRequestUserInfos } from "../../hooks/useRequestUserInfos";
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal.js";

const ReportButton = ({ handleUpdateReports }) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ issue: "", userId: "" });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [currentReportId, setCurrentReportId] = useState(null);


  const handleCloseImageUpload = () => {
    setCurrentReportId(null);
    setShowImageUpload(false);
  };

  const httpConfig = useRequest();
  const { getUserInfos, userInfos } = useRequestUserInfos();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      getUserInfos(authToken);
    }
  }, []);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      userId: userInfos && userInfos.id,
    }));
  }, [userInfos]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({ issue: "", userId: userInfos.id });
    setLoading(false);
    setSelectedFile(null);
    setUploadedFileName("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(endpoints.createReport(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Erro ao enviar relatório: ${response.statusText}`);
      }

      const reportData = await response.json();
      const reportId = reportData.id;
      console.log(reportId)

      if (selectedFile) {
        const formData2 = new FormData();
        selectedFile.filename = reportData.id;
        formData2.append("file", selectedFile);
        

        const imageResponse = await fetch(endpoints.uploadReportFile(), {
          method: "POST",
          body: formData2,
        });

        if (!imageResponse.ok) {
          throw new Error(`Erro ao enviar imagem: ${imageResponse.statusText}`);
        } 

        const novoNome = await imageResponse.json();
        setUploadedFileName(novoNome.filename);

        
        const imageResponse2 = await fetch(endpoints.saveReportFile(), {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',  // Add this header to specify JSON content
          },
          body: JSON.stringify({
            report_id: reportId,
            filename: novoNome.filename,
          }),
        });

          if (!imageResponse2.ok) {
            throw new Error(`Erro ao enviar imagem: ${imageResponse.statusText}`);
          }

          const dataImg = await imageResponse2.json();
          console.log(dataImg);
      } 
      



      alert("Mensagem enviada com sucesso");
      handleUpdateReports();
      handleCloseModal();
      
    } catch (error) {
      console.error("Erro ao enviar relatório:", error);
      handleCloseModal();
      
    } finally {
      setLoading(false);
    }
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Button onClick={handleOpenModal} className="btn-add-roles btn-success">
        Reportar
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Form onSubmit={handleSubmit} className="form-page">
          <Modal.Header closeButton>
            <Modal.Title>Reportar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicIssue">
              <Form.Label>
                Descreva, em no máximo 254 caracteres, o seu problema/sugestão.
              </Form.Label>
              <Form.Control
                as="textarea"
                value={formData.issue}
                name="issue"
                rows={4}
                maxLength={254}
                onChange={handleChangeValue}
                required
              />
              <small className="text-muted">
                Caracteres restantes: {254 - formData.issue.length}
              </small>
            </Form.Group>

            <Form.Group controlId="formBasicImage">
              <Form.Label>Adicionar Anexo (Imagem)</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              {uploadedFileName && (
                <p>Arquivo selecionado: {uploadedFileName}</p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Form.Group className="btn-controls">
             
              <Button
                className="btn-danger action-btn"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
              <Button
                className="btn-success action-btn"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span> Enviando...</span>
                  </>
                ) : (
                  "Salvar"
                )}
              </Button>
            </Form.Group>
          </Modal.Footer>
        </Form>
      </Modal>
      <ImageUploadModal
        isOpen={showImageUpload}
        onRequestClose={handleCloseImageUpload}
      ></ImageUploadModal>
    </>
  );
};

export default ReportButton;

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import logo from './logo.png';
import './EmployeeResearch.css'

import { Container, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';

import endpoints from '../../modules/endpoints';
import { useRequest } from '../../hooks/useRequest';

function EmployeeResearch() {
  document.title = 'Pesquisa interna';

  const [showThanks, setShowThanks] = useState(false);
  const [invalidResearch, setInvalidResearch] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { sendRequest, response } = useRequest();
  const { sendRequest: getResearch, response: researchResponse } = useRequest();

  const { rid, campanha } = useParams();

  const initialFormData = {
    campanha: campanha,
    codigo: rid,
    email: '',
    nome_completo: '',
    preservar_na_empresa: '',
    praticar_mais: '',
    parar_de_fazer: '',
    comecar_fazer: '',
    nota_indicacao: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const resetFormData = () => {
    setFormData({ ...initialFormData });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
      const newResearch = formData;

      sendRequest(endpoints.pesquisaInterna(), 'PUT', newResearch);
    }
  };

  useEffect(() => {
    getResearch(`${endpoints.pesquisaInterna()}/${campanha}/${rid}`, 'GET');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, rid]);

  useEffect(() => {
    if (researchResponse) {
      if (researchResponse.length > 0) {
        if (researchResponse[0].respondido_em !== null) {
          setShowThanks(true);
          setShowForm(false)
        } else {
          setShowForm(true);
        }
      } else {
        setInvalidResearch(true);
      }
    }

  }, [researchResponse])

  useEffect(() => {
    if (response && response.message === 'Resposta enviada com sucesso!') {
      setShowThanks(true);
      setShowForm(false)
    }
  }, [response])


  return (
    <div className='content-research'>
      {invalidResearch && <>
        <Container className='thanks'>
          <Col xs={3}>
            <div></div>
          </Col>
          <Col xs={6}>
            <div className="text-center ">
              <div>
                <img src={logo} className="app-logo" alt="logo" height={120} />
                <h3 className='custom-heading'>Link de pesquisa inválido!</h3>
              </div>
            </div>
          </Col>
        </Container>
      </>}

      {showForm && <div className='back-reasearch'>
        <Container className="research ">
          <Row className="research-employee">
            <Col xs={12} md={8}>
              <div className="text-center ">
                <div className='research-header'>
                  <img src={logo} className="app-logo" alt="logo" height={120} />
                  <h3 className='custom-heading'>Pesquisa Interna</h3>
                  <p>Queremos te ouvir, sua contribuição sobre nossa cultura é muito importante!</p>
                  <p className='research-warning'>* Indica campos obrigatórios</p>
                </div>
              </div>
              <Form onSubmit={handleSubmit}>
                <Card className="mb-1 research-employee">
                  <Card.Body className='employee-card-body'>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>

                <Card className="mb-1 research-employee">
                  <Card.Body className='employee-card-body'>
                    <Form.Group controlId="formName">
                      <Form.Label>Qual seu nome completo?</Form.Label>
                      <Form.Control
                        type="text"
                        name="nome_completo"
                        value={formData.nome_completo}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>

                <Card className="mb-1 research-employee">
                  <Card.Body className='employee-card-body'>
                    <Form.Group controlId="formToPreserve">
                      <Form.Label>O que devemos preservar em nossa empresa?</Form.Label>
                      <Form.Control
                        type="text"
                        name="preservar_na_empresa"
                        value={formData.preservar_na_empresa}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>

                <Card className="mb-1 research-employee">
                  <Card.Body className='employee-card-body'>
                    <Form.Group controlId="formToPractice">
                      <Form.Label>O que devemos praticar mais?<span className='research-warning'> *</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="praticar_mais"
                        value={formData.praticar_mais}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>

                <Card className="mb-1 research-employee">
                  <Card.Body className='employee-card-body'>
                    <Form.Group controlId="formStopToDo">
                      <Form.Label>O que devemos parar de fazer?<span className='research-warning'> *</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="parar_de_fazer"
                        value={formData.parar_de_fazer}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>

                <Card className="mb-1 research-employee">
                  <Card.Body className='employee-card-body'>
                    <Form.Group controlId="formStartToDo">
                      <Form.Label>O que devemos começar a fazer?<span className='research-warning'> *</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="comecar_fazer"
                        value={formData.comecar_fazer}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>


                <Card className="mb-1 research-employee">
                  <Card.Body className='employee-card-body'>
                    <Form.Group controlId="formIndicateToFriend" as={Row}>
                      <Form.Label >Você indicaria a empresa para um amigo trabalhar?<span className='research-warning'> *</span></Form.Label>
                      <Col sm={12} xs={12} md={12} className="radio-employee">
                        {Array.from({ length: 10 }, (_, i) => (
                          <Form.Check
                            inline
                            key={i + 1}
                            type="radio"
                            label={i + 1}
                            name="nota_indicacao"
                            value={i + 1}
                            checked={formData.nota_indicacao !== null && parseInt(formData.nota_indicacao) === i + 1}
                            onChange={handleChange}
                            required
                          />
                        ))}
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>

                <div className='form-space-betwwen'>
                  <Button variant="primary" type="submit">
                    Enviar
                  </Button>

                  <Button variant="link" onClick={resetFormData}>
                    Limpar formulário
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container >
      </div>}
      {showThanks ? <>
        <Container className='thanks'>
          <Col xs={3}>
            <div></div>
          </Col>
          <Col xs={6}>
            <div className="text-center ">
              <div>
                <img src={logo} className="app-logo" alt="logo" height={120} />
                <h3 className='custom-heading'>Muito Obrigado!</h3>
                <p>Agradecemos muito por ter dedicado um tempo para responder nossa pesquisa.
                  Seus comentários são muito valiosos e nos ajudarão a melhorar continuamente.</p>
              </div>
            </div>
          </Col>
        </Container>
      </> : !showForm && <Container className='thanks'>
        <Col xs={6}>
          <div></div>
        </Col>
        <Col xs={1}>
          <Spinner></Spinner>
        </Col>
      </Container>}
    </div>
  )
}

export default EmployeeResearch
import { useState, useMemo, useEffect, useRef } from "react";
import { useRequest } from "../hooks/useRequest";
import {
  Alert,
  Button,
  Form,
  Modal,
  Spinner,
  Dropdown,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import endpoints from "../modules/endpoints";
import SearchIcon from "@mui/icons-material/Search";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import {
  Box,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useFetchPerformatic } from "../hooks/useFetchPerformatic";
import ProposalCard from "../components/ProposalCard/ProposalCard";

function ProposalsRanking() {
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);
  const [ordenacaoCrescente, setOrdenacaoCrescente] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const [authToken] = useState(localStorage.getItem("authToken"));
  const { sendRequest, response } = useRequest();
  const [proposalRanking, setProposalRanking] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [clientName, setClientName] = useState("");
  const [searchText, setSearchText] = useState("");

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleCloseErrorAlert = () => {
    setShowErrorAlert(false);
    setErrorMessage("");
  };

  const handleSearch = (event) => {
    
    if (event.target === document.activeElement) {
      // O campo de pesquisa está focado, apenas atualize o estado do texto de pesquisa
      setSearchText(event.target.value);
    } else {
      // O campo de pesquisa não está focado, execute a lógica relacionada ao clique do card
      setModalOpen(false);

      setSearchText(event.target.value);
    }
  };

  const initialFormData = {
    id: "",
    cliente_id: "",
    cidade: "",
    estado: "",
    comprador: "",
    email: "",
    telefone: "",
    revisao: "0",
    tipo: "",
    data_solicitacao: null,
    data_limite_envio: null,
    hora_limite_envio: null,
    status: "RECEBIDO",
    status_finalizacao: "",
    numero_cotacao_ordem_servico: "",
    numero_cotacao_externo: "",
    servico: "",
    data_previsao_inicio: null,
    data_previsao_termino: null,
    cliente_empresa_responsavel: "VOLPE MANUTENÇÃO IND",
    cliente_tecnico_responsavel: "",
    equipe_designada: "",
    obs: "",
    valor: null,
    disciplina: "",
    ncg: null,
    retorno: null,
  };

  const [formData, setFormData] = useState(initialFormData);

  const reserFormData = () => {
    setFormData(initialFormData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== "cliente_id") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    } else if (name === "hora_limite_envio") {
      setFormData({ ...formData, [name]: `${value}:00` });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    sendRequest(endpoints.calculateRanking(), "GET");
  }, []);

  const alternarOrdem = () => {
    setOrdenacaoCrescente((prev) => !prev);
  };

  // Função para ordenar o vetor com base no total

  const ordenarDinamicamente = (a, b) => {
    switch (selectedOption) {
      case "ncg":
        return ordenacaoCrescente
          ? a.proposal.ncg - b.proposal.ncg
          : b.proposal.ncg - a.proposal.ncg;
      case "roi":
        return ordenacaoCrescente
          ? a.proposal.retorno - b.proposal.retorno
          : b.proposal.retorno - a.proposal.retorno;
      case "data_prev_inicio":
        return ordenacaoCrescente
          ? a.proposal.data_prev_inicio - b.proposal.data_prev_inicio
          : b.proposal.data_prev_inicio - a.proposal.data_prev_inicio;
      default:
        return ordenacaoCrescente ? a.total - b.total : b.total - a.total;
    }
  };

  const resetModal = () => {
    setModalOpen(false);
    setIsModalOpen(false);
    setIsViewerOpen(false); // Adicione esta linha
  };

  const proposalRankingSorted = useMemo(() => {
    return response && Array.isArray(response.totalSum)
      ? response.totalSum.slice().sort(ordenarDinamicamente)
      : [];
  }, [response, ordenacaoCrescente, selectedOption]);

  // Atualize o estado quando necessário
  useEffect(() => {
    setProposalRanking(proposalRankingSorted);
  }, [proposalRankingSorted]);

  useEffect(() => {}, [clientName, selectedOption]);
  const navigate = useNavigate();

  // Função para alternar a ordem ao clicar no botão
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
  };

  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.proposals());

  useEffect(() => {
    
  }, [selectedOption]);

  const filteredRanking = proposalRanking.filter((item) => {
    // Implemente sua lógica de filtragem aqui, com base no searchText
    // Por exemplo, você pode comparar o texto de pesquisa com várias propriedades do item.
    return item.proposal.cliente.razao_social
      .toLowerCase()
      .includes(searchText.toLowerCase());
  });

  return (
    <>
      <Sidebar />
      <div className="content" ref={elementRef}>
        <div className="header_modules">
          <h2 className="product-title">MESA DE PROPOSTAS</h2>
        </div>
        {/* Renderizar o Alert somente se houver uma mensagem de erro */}
        {showErrorAlert && (
        <Modal
          show={showErrorAlert}
          onHide={handleCloseErrorAlert}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Aviso</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{errorMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseErrorAlert}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            padding: "15px",
          }}
        >
          {!error ? (
            !loading ? (
              <>
                <TextField
                  label="Pesquisar Cliente"
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={(e) => {
                    setIsModalOpen(false);
                    handleSearch(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",

                    paddingLeft: "30px",
                  }}
                >
                  <Form.Select
                    name="SelectOrder"
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option key="placeholder" value="" disabled>
                      {"Classificar por..."}
                    </option>
                    <option key="nota" value="nota">
                      {"Nota"}
                    </option>
                    <option key="ncg" value="ncg">
                      {"NCG"}
                    </option>
                    <option key="roi" value="roi">
                      {"ROI"}
                    </option>
                    <option key="data_prev_inicio" value="data_prev_inicio">
                      {"Data de inicio"}
                    </option>
                  </Form.Select>

                  <Button
                    style={{
                      backgroundColor: "white",
                      borderColor: "gray",
                      height: "38px",
                    }}
                    className="btn-add-roles btn-primary"
                    onClick={alternarOrdem}
                  >
                    {" "}
                    {ordenacaoCrescente ? (
                      <ArrowUpwardIcon size={5} style={{ color: "black" }} />
                    ) : (
                      <ArrowDownwardIcon size={5} style={{ color: "black" }} />
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <Button className="btn-add-roles btn-primary" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>Loading...</span>
              </Button>
            )
          ) : null}
        </div>
        <div>
          {proposalRanking.length === 0 ? (
            <div className="center-spinner">
              <Spinner
                as="span"
                animation="border"
                size="md"
                variant="primary"
                role="status"
                aria-hidden="true"
                position="center"
              />
            </div>
          ) : (
            
            filteredRanking.map((item, index) => (
              <ProposalCard
                key={index}
                numeroProposta={item.proposal.id}
                cliente_id={item.proposal.cliente_id}
                disciplina={item.proposal.disciplina}
                revisao={item.proposal.revisao}
                tipo={item.proposal.tipo}
                data_solicitacao={item.proposal.data_solicitacao}
                data_limite_envio={item.proposal.data_limite_envio}
                hora_limite_envio={item.proposal.hora_limite_envio}
                status={item.proposal.status}
                email={item.proposal.email}
                status_finalizacao={item.proposal.status_finalizacao}
                num_cot_os={item.proposal.numero_cotacao_ordem_servico}
                servico={item.proposal.servico}
                data_prev_inicio={item.proposal.data_previsao_inicio}
                data_prev_termino={item.proposal.data_previsao_termino}
                cli_empresa_responsavel={
                  item.proposal.cliente_empresa_responsavel
                }
                cli_tecnico_responsavel={
                  item.proposal.cliente_tecnico_responsavel
                }
                equipe_designada={item.proposal.equipe_designada}
                obs={item.proposal.obs}
                valor={item.proposal.valor}
                retorno={item.proposal.retorno}
                num_cot_externo={item.proposal.numero_cotacao_externo}
                cidade={item.proposal.cidade}
                estado={item.proposal.estado}
                telefone={item.proposal.telefone}
                nomeEmpresa={item.proposal.cliente.razao_social}
                roi={item.proposal.retorno}
                ncg={item.proposal.ncg}
                nomeComprador={item.proposal.comprador}
                total={item.total}
                modalOpen={isModalOpen}
                setModalOpen={setIsModalOpen} // Passe o valor de isOpen
                onModalClose={resetModal}
                showErrorAlert={showErrorAlert}
                setShowErrorAlert={setShowErrorAlert}
                setErrorMessage={setErrorMessage}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default ProposalsRanking;

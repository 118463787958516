import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useFetchPerformatic = (url) => {
  const navigate = useNavigate();
  const [data, setData] = useState('');

  const [config, setConfig] = useState('');
  const [method, setMethod] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [authToken] = useState(localStorage.getItem('authToken'));

  const httpConfig = (data, method) => {
    if (method === 'POST') {
      setConfig({
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      });
    } else if (method === 'PUT') {
      setConfig({
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      });
    }
    setMethod(method);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(`${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (res.status === 401) {
          localStorage.clear();
          navigate('/');
        } else {
          const json = await res.json();
          setData(json);
        }
      } catch (error) {
        setError('Desculpa :( erro ao carregar dados, tente novamente em alguns instantes!');
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    const httpRequest = async () => {
      if (method === 'POST') {
        let fetchOptions = [url, config];

        const res = await fetch(...fetchOptions);
        if (res.status === 401) {
          localStorage.clear();
          navigate('/');
        } else {
          const json = await res.json();
          if (json.statusCode && json.statusCode !== 200) {
            setShowAlert({ level: 'danger', message: json.message });
          } else if (json.status && json.status === 'error') {
            setShowAlert({ level: 'danger', message: json.message });
          } else {
            setData([json, ...data]);
            setShowAlert({ level: 'success', message: 'Informação cadastrada com sucesso!!!' });
          }
        }
      } else if (method === 'PUT') {
        let fetchOptions = [url, config];

        const res = await fetch(...fetchOptions);
        if (res.status === 401) {
          localStorage.clear();
          navigate('/');
        } else {
          const json = await res.json();

          if (json.statusCode && json.statusCode !== 200) {
            setShowAlert({ level: 'danger', message: json.message });
          } else if (json.status && json.status === 'error') {
            setShowAlert({ level: 'danger', message: json.message });
          } else {
            const updatedItems = data.map((item) => {
              if (item.id === json.id) {
                return { ...item, ...json };
              }
              return item;
            });

            setData(updatedItems);
            setShowAlert(true);
            setShowAlert({ level: 'success', message: 'Informação editada com sucesso!!!' });
          }
        }
      }
      setTimeout(() => {
        setShowAlert(null);
      }, 5000);
    };
    httpRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, method, url]);

  return { data, httpConfig, loading, error, showAlert };
};

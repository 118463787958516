import ModalDeleteUser from "../components/ModalDeleteUser/ModalDeleteUser";

const base_url = process.env.REACT_APP_BASE_URL;
const port = process.env.REACT_APP_PORT;
const portBackend = process.env.REACT_APP_PORT_BACKEND;
const portWhats = process.env.REACT_APP_PORT_WHATS;

let protocolo;

if (base_url === 'localhost') {
  protocolo = 'http';
} else {
  protocolo = 'https';
}
let url;
if (base_url === 'localhost') {
  url = `${protocolo}://${base_url}:${port}`;
} else {
  url = `${protocolo}://${base_url}`;
}

const urlServer = `${protocolo}://${base_url}:${portBackend}`;

// const url = `${protocolo}://${base_url}:${port}`;
const urlWhats = `${protocolo}://${base_url}:${portWhats}`;

class EndPoints {
  sendWhatsAppMessages() {
    return urlWhats + '/api/sendBulkResearches/employees';
  }

  login() {
    return urlServer + '/session';
  }

  userRoles() {
    return urlServer + '/session/infos';
  }

  addNewUser() {
    return urlServer + '/users';
  }

  deleteUser() {
    return urlServer + '/users/delete';
  }

  updateUserRoles() {
    return urlServer + '/users/updateRoles';
  }


  proposals() {
    return urlServer + '/proposals';
  }

  changePassword() {
    return urlServer + '/password/reset';
  }

  prospects() {
    return urlServer + '/prospects';
  }

  prospectsUpdateSaleId() {
    return urlServer + '/prospects/updateSaleId';
  }

  positions() {
    return urlServer + '/positions';
  }

  clients() {
    return urlServer + '/customers';
  }

  medicoes() {
    return urlServer + '/measurements';
  }

  colaboradores() {
    return urlServer + '/collaborators';
  }

  colaboradoresAtivos() {
    return urlServer + '/collaborators/active';
  }

  enviarEmailColaborador(){
    return urlServer + '/collaborators/send'
  }

  vacation() {
    return urlServer + '/vacation';
  }

  dependents() {
    return urlServer + '/dependents';
  }

  log() {
    return urlServer + '/log_status_change';
  }
  calculateRanking() {
    return urlServer + '/proposals/rankingtotal' 
  }

 
  pesquisaRanking() {//Rota que prepara um ranking de propostas para ser atualizado
    return urlServer + '/ranking/searchRanking';
  }

  updateRanking() {//Rota que atualiza o ranking de pesquisa de uma proposta com as notas
    return urlServer + '/ranking/update';
  }


  pesquisaInterna() {
    return urlServer + '/api/researches/employee';
  }

  pesquisaServico() {
    return urlServer + '/api/researches/services';
  }

  pesquisaSaleService() { //Rota que pesquisa os rankings relacionados a uma proposta
    return urlServer + '/ranking/list';
  }

  pesquisaInternaRespostas() {
    return urlServer + `/api/researches/employees/answers`;
  }

  recrutamento() {
    return urlServer + `/recrutamento`;
  }

  recruitmentFile() {
    return urlServer + `/recrutamento/recruitmentFile`;
  }

  pesquisas() {
    return urlServer + '/api/researches';
  }

  pesquisasEnviadas() {
    return urlServer + '/api/researches/sent';
  }

  grupos() {
    return urlServer + '/api/groups';
  }

  uploadCSV() {
    return urlServer + '/measurements/sendCsv';
  }

  uploadProposalFile() {
    return urlServer + '/proposalfiles/proposalFile';
  }

  

  showProposalFile() {
    return urlServer + '/proposalfiles/proposalFile/show';
  }

  showLatestFile() {
    return urlServer + '/proposalfiles/latest';
  }

  getProposalFiles() {
    return urlServer + '/proposalfiles/list/'
  }

  saveProposalFile() {
    return urlServer + '/proposalfiles/saveProposalFile';
  }

  ocorrencias() {
    return urlServer + '/api/occurrences';
  }

  uploadReportFile() {
    return urlServer + '/reportfiles/reportFile';
  }

  showReportFile() {
    return urlServer + '/reportfiles/reportfile/show/';
  }

  saveReportFile() {
    return urlServer + '/reportfiles/saveReportFile';
  }

  createReport() {
    return urlServer + '/reports/';
  }

  getReports() {
    return urlServer + '/reports/';
  }

  updateReports() {
    return urlServer + '/reports/';
  }

  listReportFiles() {
    return urlServer + '/reportfiles/list/';
  }


  baseUrl() {
    if (base_url === 'localhost') {
      return `${protocolo}://${base_url}:${portBackend}`;
    } else {
      return `${protocolo}://${base_url}`;
    }
  }

  baseApplicationUrl() {
    return url;
  }

  apiServer() {
    return `${protocolo}://${base_url}:${portBackend}`;
  }
  apiServerOld() {
    return `${protocolo}://${base_url}:9000`;
  }
}

const endpoints = new EndPoints();
export default endpoints;

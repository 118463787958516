import { useState, useMemo } from 'react';
import { useFetch } from '../hooks/useFetch';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from '../components/Table';
import endpoints from '../modules/endpoints';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from '../components/Sidebar';

function SentResearchesEmployees() {
  const [openDialog, setOpenDialog] = useState(false);

  const { data: items, loading, error } = useFetch(endpoints.pesquisaInternaRespostas());

  const initialFormData = {
    id: '',
    campanha: '',
    codigo: '',
    respondido_em: '',
    email: '',
    nome_completo: '',
    preservar_na_empresa: '',
    praticar_mais: '',
    parar_de_fazer: '',
    comecar_fazer: '',
    nota_indicacao: '',
    enviada_em: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleCloseDialog = () => {
    resetFormData();
    setOpenDialog(false);
  };

  function viewItem(item) {
    setFormData(item);

    handleOpenDialog();
  }

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.codigo,
        header: 'id',
        size: 40,
      },
      {
        accessorFn: (row) => row.campanha,
        header: 'Campanha',
        enableHiding: false,
        size: 30,
      },
      {
        accessorFn: (row) => row.email,
        header: 'Email',
        size: 60,
      },
      {
        accessorFn: (row) => row.nome_completo,
        header: 'Nome completo',
      },
      {
        accessorFn: (row) => row.nota_indicacao,
        header: 'Nota',
      },
    ],
    []
  );

  return (
    <>
      <Sidebar />
      <div className='content'>
        <div className='header_modules'>
          <h2 className='product-title'>Respostas dos colaboradores</h2>
        </div>
        <div className='table_funcoes'>
          <Table error={error} loading={loading} columns={columns} items={items} funcao={viewItem} initialState={{ density: 'compact', pagination: { pageSize: 100 } }} />
        </div>
        <div>
          <Modal show={openDialog} onHide={handleCloseDialog} centered size='xl'>
            <Form className='form-page'>
              <Modal.Header closeButton>
                <Modal.Title>Resposta</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={1}>
                    <Form.Group className='mb-1' controlId='formBasicNome'>
                      <Form.Label>Capanha</Form.Label>
                      <Form.Control value={formData.campanha} name='campanha' type='text' disabled />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className='mb-1' controlId='formBasicNome'>
                      <Form.Label>Código</Form.Label>
                      <Form.Control value={formData.codigo} name='codigo' type='text' disabled />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className='mb-1' controlId='formBasicNome'>
                      <Form.Label>Enviada em</Form.Label>
                      <Form.Control value={formData.enviada_em} name='enviada_em' type='date' disabled />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1' controlId='formBasicNome'>
                      <Form.Label>Respondida em</Form.Label>
                      <Form.Control value={formData.respondido_em} name='respondido_em' type='date' disabled />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Group className='mb-1' controlId='formBasicNome'>
                      <Form.Label>Nome completo</Form.Label>
                      <Form.Control value={formData.nome_completo} name='nome_completo' type='text' disabled />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className='mb-1' controlId='formBasicNome'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control value={formData.email} name='email' type='text' disabled />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Form.Group className='mb-3' controlId='formBasicNome'>
                    <Form.Label>Preservar na empresa</Form.Label>
                    <Form.Control as='textarea' value={formData.preservar_na_empresa} name='preservar_na_empresa' rows={2} disabled />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className='mb-3' controlId='formBasicNome'>
                    <Form.Label>Parar de fazer</Form.Label>
                    <Form.Control as='textarea' value={formData.parar_de_fazer} name='parar_de_fazer' rows={2} disabled />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className='mb-3' controlId='formBasicNome'>
                    <Form.Label>Começar a fazer</Form.Label>
                    <Form.Control as='textarea' value={formData.comecar_fazer} name='comecar_fazer' rows={2} disabled />
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className='btn-controls'>
                  <Button className='btn-danger action-btn' onClick={handleCloseDialog}>
                    Fechar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default SentResearchesEmployees;

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './NotFound.css';
function NotFound() {
  return (
    <Container className="my-5 container-404">
      <Row>
        <Col xs={12}>
          <h1 className="text-center header-404">404</h1>
          <p className="text-center text-404">Page not found</p>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound
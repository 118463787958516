import React, { useEffect, useState } from "react";
import * as pbi from "powerbi-client";
import { models } from "powerbi-client";
import Sidebar from "./Sidebar";
import endpoints from "../modules/endpoints";

const PowerBIDashboardSalesFunnel = () => {
  const [config, setConfig] = useState(null);
  const [authToken] = useState(localStorage.getItem('authToken'));

  const reportGroup = 'a73a8ffc-5b9d-4b7f-9032-c7ea3b6676ab';
  const reportId = '0db29a0c-206a-453d-a313-55635c636fda';

  useEffect(() => {
    fetch(`${endpoints.apiServerOld()}/dashboard/config?groups=${reportGroup}&reports=${reportId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to fetch embed config');
        return response.json();
      })
      .then((data) => {
        setConfig({
          type: 'report',
          tokenType: models.TokenType.Embed,
          id: data.embedUrl[0].reportId,
          accessToken: data.accessToken,
          embedUrl: data.embedUrl[0].embedUrl,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: true,
          },
        });
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  useEffect(() => {
    if (config) {
      const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      const reportContainer = document.getElementById('reportContainer');

      powerbi.reset(reportContainer);

      try {
        powerbi.embed(reportContainer, config);
        // const report = powerbi.embed(reportContainer, config);
        // const reportElement = report.getHtmlElement();
        // const childElement = reportElement.querySelector("notification-bar");
        // console.log(childElement);
        // childElement.setAttribute("hidden", "true");
      } catch (error) {
        console.error('Embed error:', error);
      }
    }
  }, [config]);

  return (
    <>
      <Sidebar />
      <div id='reportContainer'></div>
    </>
  );
};

export default PowerBIDashboardSalesFunnel;

import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';

const CustomToast = ({ show, onClose, message, bg }) => {
  return (
    <Toast show={show}  placement="bottom-end" bg={bg}>
      <Toast.Header>
        <strong className="me-auto">Mensagem</strong>
        <small>agora</small>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default CustomToast;
import { useState, useMemo, useEffect, useRef } from "react";
import { useFetch } from "../hooks/useFetch";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Table from "../components/Table";

import SelectFieldFetch from "../components/selectFieldFetch";
import SelectFieldFixed from "../components/selectFieldFixed";

import endpoints from "../modules/endpoints";
import { useParams, useNavigate } from "react-router-dom";
import { useLog } from "../hooks/useLog";
import Sidebar from "../components/Sidebar";

function Prospeccao() {
  const { rid } = useParams();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { data: items, httpConfig, loading, error } = useFetch(endpoints.prospects());
  const { sendLog } = useLog(endpoints.log());

  const [id, setId] = useState(null);
  const [responsavel, setResponsavel] = useState(null);
  const [status, setStatus] = useState("Pré cadastro");
  const status_prev = usePrevious(status);
  const [segmento, setSegmento] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [estado, setEstado] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [tecnico_responsavel, setTecnico] = useState(null);
  const [tecnico_email, setTecnicoEmail] = useState(null);
  const [tecnico_telefone, setTecnicoTelefone] = useState(null);
  const [comprador_responsavel, setComprador] = useState(null);
  const [comprador_email, setCompradorEmail] = useState(null);
  const [comprador_telefone, setCompradorTelefone] = useState(null);
  const [obs, setObs] = useState(null);
  const [id_venda, setIdVenda] = useState(null);

  const [showInnerModal, setShowInnerModal] = useState(false);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setId(null);
    setResponsavel(null);
    setStatus("Pré cadastro");
    setSegmento(null);
    setCliente(null);
    setCidade(null);
    setEstado(null);
    setTecnico(null);
    setTecnicoEmail(null);
    setTecnicoTelefone(null);
    setComprador(null);
    setCompradorTelefone(null);
    setCompradorEmail(null);
    setObs(null);
    setIdVenda(null);

    setIsEditing(false);
    setOpenDialog(false);
    setShowAlert(false);
  };

  const handleSubmitDirect = () => {
    handleSubmit();
  };

  const handleSubmitInnerDirect = () => {
    handleSubmitTeste();
  };

  const handleSubmit = () => {
    const newProspect = {
      id,
      responsavel,
      status,
      segmento,
      cliente,
      cidade,
      estado,
      tecnico_responsavel,
      tecnico_email,
      tecnico_telefone,
      comprador_responsavel,
      comprador_email,
      comprador_telefone,
      obs,
      id_venda,
    };

    if (isEditing) {
      httpConfig(newProspect, "PUT");
      if (status_prev !== null && status !== status_prev) {
        sendLog({
          table_status: "prospeccao",
          field_status: "status",
          status_register_id: id,
          from_status: status_prev,
          to_status: status,
        });
      }
      setShowAlertEdit(true);
    } else {
      httpConfig(newProspect, "POST");
      setShowAlert(true);
    }

    handleCloseDialog();
    setIsEditing(false);

    if (newProspect.status === "Cadastro concluído" && newProspect.id_venda === null) {
      handleShowInnerModal();
    } else {
      localStorage.removeItem("prospectId");
    }

    setTimeout(() => {
      setShowAlert(false);
      setShowAlertEdit(false);
    }, 5000);
  };

  const handleSubmitTeste = () => {
    handleCloseInnerModal();
    localStorage.removeItem("prospectId");
  };

  const handleSubmitRedirect = () => {
    handleCloseInnerModal();
    setTimeout(() => {
      navigate("/vendas/new");
    }, 2000);
  };

  const handleShowInnerModal = () => {
    setShowInnerModal(true);
  };

  const handleCloseInnerModal = () => {
    setShowInnerModal(false);
  };

  function preCadastro(status) {
    return (
      <Badge pill bg="danger">
        {status}
      </Badge>
    );
  }
  function contatoRealizado(status) {
    return (
      <Badge pill bg="warning" text="dark">
        {status}
      </Badge>
    );
  }

  function visitaRealizada(status) {
    return (
      <Badge pill bg="primary">
        {status}
      </Badge>
    );
  }
  function cadastroConcluido(status) {
    return (
      <Badge pill bg="success">
        {status}
      </Badge>
    );
  }

  const renderStatus = (status) => {
    let badge;
    if (status === "Pré cadastro") {
      badge = preCadastro(status);
    } else if (status === "Contato realizado") {
      badge = contatoRealizado(status);
    } else if (status === "Visita realizada") {
      badge = visitaRealizada(status);
    } else if (status === "Cadastro concluído") {
      badge = cadastroConcluido(status);
    } else {
      badge = status;
    }

    return (
      <>
        <h6 className="status">{badge}</h6>
      </>
    );
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.id,
        header: "Id",
        size: 30,
      },
      {
        accessorFn: (row) => row.status,
        header: "Status",
        enableHiding: false,
        size: 60,

        Cell: ({ cell }) => renderStatus(cell.getValue()),
      },
      {
        accessorFn: (row) => row.responsavel,
        header: "Responsável",
        size: 40,
      },
      {
        accessorFn: (row) => row.cliente,
        header: "Cliente",
        size: 60,
      },
      {
        accessorFn: (row) => row.cidade,
        header: "Cidade",
        size: 60,
      },
      {
        accessorFn: (row) => row.estado,
        header: "Estado",
        size: 30,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function editItem(item) {
    setIsEditing(true);
    setId(item.id);
    setResponsavel(item.responsavel);
    setStatus(item.status);
    setSegmento(item.segmento);
    setCliente(item.cliente);
    setCidade(item.cidade);
    setEstado(item.estado);
    setTecnico(item.tecnico_responsavel);
    setTecnicoEmail(item.tecnico_email);
    setTecnicoTelefone(item.tecnico_telefone);
    setComprador(item.comprador_responsavel);
    setCompradorEmail(item.comprador_email);
    setCompradorTelefone(item.comprador_telefone);
    setObs(item.obs);
    setIdVenda(item.id_venda);
    localStorage.setItem("prospectId", item.id);
    handleOpenDialog();
  }

  const statusList = [
    { id: 1, value: "Pré cadastro", label: "Pré cadastro" },
    { id: 2, value: "Contato realizado", label: "Contato realizado" },
    { id: 3, value: "Visita realizada", label: "Visita realizada" },
    { id: 4, value: "Cadastro concluído", label: "Cadastro concluído" },
  ];

  const responsaveisList = [
    { id: 1, value: "Raissa Cardoso", label: "Raissa Cardoso" },
    { id: 2, value: "Victor Valeriano", label: "Victor Valeriano" },
    { id: 3, value: "Valberto Volpe", label: "Valberto Volpe" },
    { id: 4, value: "Fábio Veras", label: "Fábio Veras" },
    { id: 5, value: "Matheus Volpe", label: "Matheus Volpe" },
  ];

  const estadosList = [
    { id: 1, value: "AC", label: "AC" },
    { id: 2, value: "AL", label: "AL" },
    { id: 3, value: "AP", label: "AP" },
    { id: 4, value: "AM", label: "AM" },
    { id: 5, value: "BA", label: "BA" },
    { id: 6, value: "CE", label: "CE" },
    { id: 7, value: "DF", label: "DF" },
    { id: 8, value: "ES", label: "ES" },
    { id: 9, value: "GO", label: "GO" },
    { id: 10, value: "MA", label: "MA" },
    { id: 11, value: "MT", label: "MT" },
    { id: 12, value: "MS", label: "MS" },
    { id: 13, value: "MG", label: "MG" },
    { id: 14, value: "PA", label: "PA" },
    { id: 15, value: "PB", label: "PB" },
    { id: 16, value: "PR", label: "PR" },
    { id: 17, value: "PE", label: "PE" },
    { id: 18, value: "PI", label: "PI" },
    { id: 19, value: "RJ", label: "RJ" },
    { id: 20, value: "RN", label: "RN" },
    { id: 21, value: "RS", label: "RS" },
    { id: 22, value: "RO", label: "RO" },
    { id: 23, value: "RR", label: "RR" },
    { id: 24, value: "SC", label: "SC" },
    { id: 25, value: "SP", label: "SP" },
    { id: 26, value: "SE", label: "SE" },
    { id: 27, value: "TO", label: "TO" },
  ];

  const segmentosList = [
    { id: 1, value: "Mineração", label: "Mineração" },
    { id: 2, value: "Cimento", label: "Cimento" },
    { id: 3, value: "Petróleo e Gás", label: "Petróleo e Gás" },
    { id: 4, value: "Fertilizante", label: "Fertilizante" },
    { id: 5, value: "Siderurgia", label: "Siderurgia" },
    { id: 6, value: "Metalurgia", label: "Metalurgia" },
    { id: 7, value: "Alimentícia", label: "Alimentícia" },
    { id: 8, value: "Automobilística", label: "Automobilística" },
    { id: 9, value: "Borracha", label: "Borracha" },
    { id: 10, value: "Química", label: "Química" },
    { id: 11, value: "Outros", label: "Outros" },
  ];

  useEffect(() => {
    if (rid !== undefined && items) {
      const item = items.find((item) => item.id === rid);
      if (item !== undefined) {
        editItem(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rid, items]);

  return (
    <>
      <Sidebar />
      <div className="content">
        {showAlertEdit && (
          <Alert key="success" variant="success">
            Prospecção editada com sucesso!
          </Alert>
        )}
        {showAlert && (
          <Alert key="success" variant="success">
            Prospecção cadastrada com sucesso!
          </Alert>
        )}
        <div className="header_modules">
          <h2 className="product-title">PROSPECÇÕES</h2>
          {!error ? (
            !loading ? (
              <Button onClick={handleOpenDialog} className="btn-add-roles btn-success">
                Nova Prospecção
              </Button>
            ) : (
              <Button className="btn-add-roles btn-success" disabled>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Loading...</span>
              </Button>
            )
          ) : null}
        </div>
        <div className="table_funcoes">
          <Table
            error={error}
            loading={loading}
            columns={columns}
            items={items}
            funcao={editItem}
            initialState={{ density: "compact", pagination: { pageSize: 15 } }}
          />
        </div>
        <div>
          <Modal show={openDialog} onHide={handleCloseDialog} centered size="xl">
            <Form>
              <Modal.Header closeButton>
                {!isEditing ? (
                  <Modal.Title>Nova Prospecção</Modal.Title>
                ) : (
                  <Modal.Title>ID: {id.toString().padStart(6, "0")}</Modal.Title>
                )}
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="form">
                  <Row className="g-2">
                    <Col xs={4}>
                      <SelectFieldFixed label="Status" funcao={setStatus} loadedVal={status} items={statusList} />
                    </Col>
                    <Col xs={4}>
                      <SelectFieldFixed
                        label="Vendedor responsável"
                        funcao={setResponsavel}
                        loadedVal={responsavel}
                        items={responsaveisList}
                      />
                    </Col>
                    <Col>
                      <SelectFieldFixed
                        label="Segmento"
                        funcao={setSegmento}
                        loadedVal={segmento}
                        items={segmentosList}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col xs={5}>
                      <SelectFieldFetch
                        label="Cliente"
                        funcao={setCliente}
                        loadedVal={cliente}
                        path="/clients"
                        field={"razao_social"}
                      />
                    </Col>
                    <Col xs={5}>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        value={cidade}
                        name="cidade"
                        onChange={(e) => {
                          setCidade(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <SelectFieldFixed
                        label="Estado"
                        funcao={setEstado}
                        loadedVal={estado}
                        items={estadosList}
                        placeholder={null}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col xs={4}>
                      <Form.Label>Técnico</Form.Label>
                      <Form.Control
                        type="text"
                        value={tecnico_responsavel}
                        name="tecnico_responsavel"
                        onChange={(e) => {
                          setTecnico(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="text"
                        value={tecnico_email}
                        name="tecnico_email"
                        onChange={(e) => {
                          setTecnicoEmail(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        type="text"
                        value={tecnico_telefone}
                        name="tecnico_telefone"
                        onChange={(e) => {
                          setTecnicoTelefone(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2">
                    <Col xs={4}>
                      <Form.Label>Comprador</Form.Label>
                      <Form.Control
                        type="text"
                        value={comprador_responsavel}
                        name="comprador_responsavel"
                        onChange={(e) => {
                          setComprador(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="text"
                        value={comprador_email}
                        name="comprador_email"
                        onChange={(e) => {
                          setCompradorEmail(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        type="text"
                        value={comprador_telefone}
                        name="emacomprador_telefoneil"
                        onChange={(e) => {
                          setCompradorTelefone(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    type="text"
                    value={obs}
                    name="obs"
                    onChange={(e) => {
                      setObs(e.target.value);
                    }}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className="btn-controls">
                  <Button className="btn-danger action-btn" onClick={handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button className="btn-success action-btn" onClick={handleSubmitDirect}>
                    {" "}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={showInnerModal} onHide={handleCloseInnerModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Prospecção finalizada</Modal.Title>
            </Modal.Header>
            <Modal.Body>Deseja criar uma nova venda?</Modal.Body>
            <Modal.Footer>
              <Form.Group className="btn-controls">
                <Button className="btn-danger action-btn" onClick={handleSubmitInnerDirect}>
                  Não
                </Button>
                <Button className="btn-success action-btn" onClick={handleSubmitRedirect}>
                  {" "}
                  Sim
                </Button>
              </Form.Group>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Prospeccao;

import { useFetch } from "../hooks/useFetch";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

import endpoints from "../modules/endpoints";

function SelectFieldFetchNew({ setValue, value, path, field, label, placeholder, name, valueById, required }) {
  const { data: items } = useFetch(endpoints.apiServer() + path);

  return (
    <>
      <InputGroup className="mb-1">
        <Form.Label>{label}</Form.Label>
      </InputGroup>
      <Form.Select required={required} value={value} name={name} onChange={(event) => setValue(event)}>
        {items && (placeholder !== undefined ? <option value="">{placeholder}</option> : <option value=""></option>)}

        {items ? (
          items.map((item) =>
            !valueById ? (
              <option key={item["id"]} value={item[field]}>
                {item[field]}
              </option>
            ) : (
              <option key={item["id"]} value={item["id"]}>
                {item[field]}
              </option>
            )
          )
        ) : !valueById ? (
          <option value="">{value}</option>
        ) : (
          <option value=""></option>
        )}
      </Form.Select>
      {path === "/clients" && false && <Button variant="outline-primary">+</Button>}
    </>
  );
}

export default SelectFieldFetchNew;

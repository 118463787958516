import React from 'react';
import { Card, Col } from 'react-bootstrap';
import "react-icons/bs";
import "./UserMenuCard.css"
import 'bootstrap/dist/css/bootstrap.min.css';
const UserMenuCard = ({ iconName, name, path, onClick, disabledMenu }) => {
    
  const handleClick = () => {
    console.log({disabledMenu});
    if (!disabledMenu) {
      onClick(path);
    }
  };
  

  return (
    <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
      <Card style={{ width: '10rem', cursor: disabledMenu ? 'not-allowed' : 'pointer' }} onClick={handleClick}>
        <Card.Body className="text-center" style={{ opacity: disabledMenu ? 0.5 : 1 }}>
          <i className={iconName} style={{ fontSize: '2rem' }} />
          <Card.Title>{name}</Card.Title>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default UserMenuCard;

import { useState, useEffect, useRef, useMemo } from "react";

import { Alert, Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";

import endpoints from "../modules/endpoints";

import Table from "../components/Table";
import SelectFieldFixedNew from "../components/selectFieldFixedNew";
import Sidebar from "../components/Sidebar";

import { useFetchPerformatic } from "../hooks/useFetchPerformatic";
import SelectFieldFetchWithId from "../components/selectFieldFetchWithId";
import { useNavigate } from "react-router-dom";
import UserMenu from "../components/UserMenu/UserMenu";
import ModalChangePassword from "../components/ModalChangePassword/ModalChangePassword";
import ModalAddNewUser from "../components/ModalAddNewUser/ModalAddNewUser";
import ModalDeleteUser from "../components/ModalDeleteUser/ModalDeleteUser";
import CustomToast from "../components/CustomToast/CustomToast";
import ModalEditUser from "../components/ModalEditUser/ModalEditUser";

const MenuItem = ({ iconName, name, path, onClick, disabledMenu }) => {
  return {
    iconName,
    name,
    path,
    onClick,
    disabledMenu,
  };
};

function UserPanel() {
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);
  /*CONTROLE DAS MODAIS DO PAINEL*/

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [success, setSuccess] = useState(null);

  const handleCardClick = (modalType) => {
    switch (modalType) {
      case "password":
        setShowPasswordModal(true);
        break;
      case "data":
        setShowDataModal(true);
        break;
      case "newUser":
        setShowNewUserModal(true);
        break;
      case "deleteUser":
        setShowDeleteUserModal(true);
        break;
      case "editUser":
        setShowEditUserModal(true);
        break;
      // Adicione mais casos conforme necessário para outros modais
      default:
        break;
    }
  };
  const [cancel, handleCancel] = useState(false);
  const [telaCancel, setTelaCancel] = useState("");

  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case "password":
        setShowPasswordModal(false);
        navigate("");
        break;
      case "data":
        setShowDataModal(false);
        break;
      case "newUser":
        if ((success || !success) && cancel === false) {
          handleShowToast();
          handleCancel(false);
          setShowNewUserModal(false);
        } else {
          handleCancel(false);
          setShowNewUserModal(false);
        }
        break;
      case "deleteUser":
        if ((success || !success) && telaCancel === false) {
          handleShowToast();
          handleCancel(false);
          setShowDeleteUserModal(false);
        } else {
          handleCancel(false);
          setShowNewUserModal(false);
        }
        break;
        case "editUser":
          if ((success || !success) && telaCancel === false) {
            handleShowToast();
            handleCancel(false);
            setShowEditUserModal(false);
          } else {
            handleCancel(false);
            setShowEditUserModal(false);
          }
          break;
      // Adicione mais casos conforme necessário para outros modais
      default:
        break;
    }
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastBg, setToastBg] = useState("");

  const [averiguaToast, setAverigaToast] = useState("");
  const [averiguaToastm, setAverigaToastm] = useState("");

  useEffect(() => {
    setTelaCancel(cancel);
  }, [cancel, handleCloseModal]);

  const handleShowToast = () => {
    setShowToast(true);

    // Defina um tempo para ocultar automaticamente o Toast após alguns segundos (opcional)
    setTimeout(() => {
      setShowToast(false);
    }, 3000); // Por exemplo, ocultará o Toast após 3 segundos
  };

  const handleCloseToast = () => {
    setShowToast(false);
    setToastMessage("");
    setToastBg(averiguaToast);
  };

  useEffect(() => {
    // if (success) {
    //   setToastBg("success");
    //   setToastMessage("Usuário adicionado com sucesso");
    // } else {
    //   setToastBg("danger");
    //   setToastMessage("Ocorreu um erro ao adicionar o usuário");
    // };
  }, [showNewUserModal]);

  useEffect(() => {
    setAverigaToastm(toastMessage);
  }, [toastMessage, success]);

  useEffect(() => {
    console.log(toastMessage);
  }, [toastBg, toastMessage]);
  /*CONTROLE DAS MODAIS DO PAINEL*/
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.colaboradores());
  const navigate = useNavigate();

  const menuOptionsADM = [
    MenuItem({
      iconName: "bi bi-person-fill-lock",
      name: "Trocar senha de usuario",
      path: "password",
      onClick: (path) => handleCardClick(path),
      disabledMenu: true,
    }),

    MenuItem({
      iconName: "bi bi-trash2-fill",
      name: "Deletar usuario",
      path: "deleteUser",
      onClick: (path) => handleCardClick(path),
      disabledMenu: false,
    }),

    MenuItem({
      iconName: "bi bi-pencil-square",
      name: "Editar permissões",
      path: "editUser",
      onClick: (path) => handleCardClick(path),
      disabledMenu: false,
    }),

    MenuItem({
      iconName: "bi bi-person-fill-add",
      name: "Vincular novo usuario",
      path: "newUser",
      onClick: (path) => handleCardClick(path),
      disabledMenu: false,
    }),
    // Adicione mais opções conforme necessário
  ];

  const menuOptions = [
    MenuItem({
      iconName: "bi bi-pencil-square",
      name: "Alterar dados",
      path: "modal_2",
      onClick: (path) => console.log(`Abrir modal para ${path}`),
      disabledMenu: true,
    }),

    MenuItem({
      iconName: "bi bi-key-fill",
      name: "Trocar senha",
      path: "password",
      onClick: (path) => handleCardClick(path),
      disabledMenu: false,
    }),

    MenuItem({
      iconName: "bi bi-wallet-fill",
      name: "propostas",
      path: "modal_4",
      onClick: (path) => console.log(`Abrir modal para ${path}`),
      disabledMenu: true,
    }),

    MenuItem({
      iconName: "bi bi-building-gear",
      name: "Visão geral",
      path: "modal_3",
      onClick: (path) => console.log(`Abrir modal para ${path}`),
      disabledMenu: true,
    }),

    // Adicione mais opções conforme necessário
  ];
  const initialFormData = {
    id: "",
    matricula: "",
    nome: "",
    status: "",
    filial: "",
    centro_de_custo: "",
    funcao_id: "",
    funcao_nome: "",
    tipo_de_contrato: "",
    tipo_de_trabalho: "",
    sexo: "",
    salario: "",
    nivel_funcao: "",
    data: null,
    categorizacao: "",
    data_de_nascimento: null,
    estado_civil: "",
    nome_do_conjuge: "",
    data_conjuge: null,
    quantidade_de_filhos: 0,
    // genero_filho_1: '',
    // nome_filho_01: '',
    // data_nascimento_01: '',
    // genero_filho_2: '',
    // nome_do_filho_02: '',
    // data_nascimento_02: '',
    // genero_filho_03: '',
    // nome_do_filho_03: '',
    // data_nascimento_03: '',
    // genero_de_filho_04: '',
    // nome_do_filho_04: '',
    // data_nascimento_04: '',
    // genero_do_filho_05: '',
    // nome_do_filho_05: '',
    // data_nascimento_05: '',
    formacao_1: "",
    status_1: "",
    nivel_1: "",
    formacao_2: "",
    status_2: "",
    nivel_2: "",
    descricao: "",
    experiencia_anterior: "",
    nivel_experiencia: "",
    tempo_experiencia: "",
    endereco: "",
    telefone: "",
    email: "",
    vendedor: 0,
  };

  const formatCurencie = (value) => {
    if (value && value !== null && value !== undefined) {
      const rawValue = value.toFixed(2).toString();
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      return formattedValue;
    } else {
      return null;
    }
  };

  const handleChangeValor = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setFormData({ ...formData, [name]: null });
    } else {
      const rawValue = value;
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      setFormData({ ...formData, [name]: formattedValue });
    }
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== "funcao_id") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      const newFormData = {
        ...formData,
        salario: formData.salario ? formData.salario.replace(/,/g, "") : null,
      };

      if (isEditing) {
        delete newFormData.codigo_pesquisas;
        httpConfig(newFormData, "PUT");
      } else {
        delete newFormData.id;
        delete newFormData.funcao_nome;
        httpConfig(newFormData, "POST");
      }

      handleCloseDialog();
      setIsEditing(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "matricula",
        header: "Matrícula",
        size: 30,
      },
      {
        accessorKey: "nome",
        header: "Nome",
        enableHiding: false,
        size: 30,
      },
      {
        accessorKey: "position.nome",
        header: "Função",
        size: 40,
      },
      {
        accessorKey: "tipo_de_contrato",
        header: "Tipo de contrato",
        size: 60,
      },
      {
        accessorKey: "tipo_de_trabalho",
        header: "Tipo de trabalho",
      },
      {
        accessorKey: "telefone",
        header: "Telefone",
      },
    ],
    []
  );

  function editItem(item) {
    setIsEditing(true);
    setFormData(item);

    const formattedValue = formatCurencie(item.salario);

    setFormData((prevFormData) => ({
      ...prevFormData,
      salario: formattedValue,
    }));

    handleOpenDialog();
  }

  const generoList = [
    { id: 1, value: "MASCULINO", label: "M" },
    { id: 2, value: "FEMININO", label: "F" },
  ];

  const vendedorStatus = [
    { id: 1, value: 0, label: "N" },
    { id: 2, value: 1, label: "S" },
  ];

  return (
    <>
      <Sidebar />
      <div className="content" ref={elementRef}>
        {showAlert && (
          <Alert key={showAlert.level} variant={showAlert.level}>
            {showAlert.message}
          </Alert>
        )}
        <div className="header_modules">
          <h2 className="product-title">PAINEL DO USUÁRIO</h2>
        </div>
        <div
          style={{
            alignitems: "center",
            alignContent: "center",
            margin: "10px 10px 20px 20px",
          }}
        >
          <h5 className="sidebar-header-text">Minha conta</h5>
          <hr></hr>
          <UserMenu menuOptions={menuOptions} />
        </div>
        <div
          style={{
            alignitems: "center",
            alignContent: "center",
            margin: "10px 10px 20px 20px",
          }}
        >
          <h5 className="sidebar-header-text">Painel Administrativo</h5>
          <hr></hr>
          <UserMenu menuOptions={menuOptionsADM} />
        </div>
        <div
          style={{
            alignitems: "center",
            alignContent: "center",
            margin: "10px 10px 20px 20px",
          }}
        >
          <hr></hr>
          <h5 className="sidebar-header-text">Grupo Volpe 2024</h5>
        </div>
        <div style={{ position: "absolute", right: "5px", bottom: "5px" }}>
          <CustomToast
            show={showToast}
            onClose={handleCloseToast}
            message={toastMessage}
            bg={toastBg}
          />
        </div>
      </div>
      <ModalChangePassword
        show={showPasswordModal}
        handleClose={() => handleCloseModal("password")}
      />
      <ModalAddNewUser
        show={showNewUserModal}
        setShowNewUserModal={setShowNewUserModal}
        handleCancel={handleCancel}
        handleShowToast={handleShowToast}
        setToastMessage={setToastMessage}
        success={success}
        setSuccess={setSuccess}
        setToastBg={setToastBg}
        handleClose={() => handleCloseModal("newUser")}
      />
      <ModalDeleteUser
        show={showDeleteUserModal}
        setShowDeleteUserModal={setShowDeleteUserModal}
        handleCancel={handleCancel}
        handleShowToast={handleShowToast}
        setToastMessage={setToastMessage}
        success={success}
        setSuccess={setSuccess}
        setToastBg={setToastBg}
        handleClose={() => handleCloseModal("deleteUser")}
      />

    <ModalEditUser
        show={showEditUserModal}
        setShowDeleteUserModal={setShowEditUserModal}
        handleCancel={handleCancel}
        handleShowToast={handleShowToast}
        setToastMessage={setToastMessage}
        success={success}
        setSuccess={setSuccess}
        setToastBg={setToastBg}
        handleClose={() => handleCloseModal("editUser")}
      />
    </>
  );
}

export default UserPanel;

import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';

const ImageViewer = ({ imageContent, imageName, isViewerOpen, setIsViewerOpen }) => {
  const [modalIsOpen, setModalIsOpen] = useState(isViewerOpen);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    setModalIsOpen(isViewerOpen);  // Atualize o estado quando isViewerOpen mudar
  }, [isViewerOpen]);

  const closeModal = () => {
    setModalIsOpen(false);
    setImageURL(null); // Limpe a URL da imagem ao fechar a modal
  };

  useEffect(() => {
    // Criar uma URL para a imagem e armazená-la no estado
    try {
      const blob = new Blob([imageContent], { type: 'image/*' });
      const url = URL.createObjectURL(blob);
      
      setImageURL(url);
    } catch (error) {
      console.error('Erro ao criar a URL da imagem:', error);
    }
  
    // Limpar a URL quando o componente for desmontado
    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL);
       
      }
    };
  }, [imageContent]);

  useEffect(() => {
    if (!modalIsOpen) {
      // Adicione lógica adicional ao fechar a modal, se necessário
      
      setIsViewerOpen(false);
    }
  }, [modalIsOpen]);

  return (
    <Modal show={modalIsOpen} size="xl" onHide={closeModal}>
      <Modal.Header closeButton style={{ maxHeight: '40px', padding: '10px' }}>
        <Modal.Title>{imageName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Renderizar a imagem usando a URL criada */}
        {imageURL && <img src={imageURL} alt={imageName} style={{ maxwidth: '100%', maxHeight: '466px', marginTop: '2px' }} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => downloadImage(imageContent, imageName)}>
          Download Imagem
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const downloadImage = (imageContent, imageName) => {
  const blob = new Blob([imageContent], { type: 'image/*' });
  saveAs(blob, `${imageName}`);
};

export default ImageViewer;

import { useState, useEffect, useRef, useMemo } from "react";

import { Alert, Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";

import endpoints from "../../modules/endpoints";

import Table from "../../components/Table";
import SelectFieldFixedNew from "../../components/selectFieldFixedNew";
import "./ModalDeleteUser.css";
import { useFetchPerformatic } from "../../hooks/useFetchPerformatic";
import SelectFieldFetchWithId from "../../components/selectFieldFetchWithId";
import { useNavigate } from "react-router-dom";

function ModalDeleteUser({
  show,
  handleClose,
  setSuccess,
  handleShowToast,
  success,
  setToastBg,
  setToastMessage,
  handleCancel,
  setShowDeleteUserModal,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.addNewUser(), "GET");
  const navigate = useNavigate();
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [authToken] = useState(localStorage.getItem("authToken"));

  const handleChangeCol = (event) => {
    // Lógica para lidar com a mudança de colaborador, se necessário
    const selectedId = event.target.value;
    const selected = items.find((item) => item.id === selectedId);
    setSelectedCollaborator(selected);
    // ... Outras lógicas que você possa precisar ...
  };
  const showToast = (message, type) => {
    setToastBg(type);
    setToastMessage(message);
  }
  const columnsArquivos = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Nome",
        size: 100,
        minSize: 100,
      },
    ],
    []
  );

  

  const handleDelete = async () => {
    // Verifique se o colaborador e o tipo de usuário foram selecionados
    if (!selectedCollaborator) {
      // Adicione lógica de tratamento aqui (por exemplo, exibir uma mensagem de erro)
      return;
    }

    const confirmDelete = window.confirm(`Você tem certeza que deseja deletar o usuário: ${selectedCollaborator.name}?`);

  // Se o usuário clicou em "Cancelar" no alerta, retorna sem deletar
  if (!confirmDelete) {
    return;
  }


    // Crie o corpo da requisição
    const requestBody = {
      id: selectedCollaborator.id,
     
    };

    try {
      // Envie a requisição para a rota endpoints.deleteUser()
      const response = await fetch(`${endpoints.deleteUser()}/${selectedCollaborator.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
         
          // Adicione outros headers necessários aqui
        },
       
      });
  
      // Verifique o status da resposta
      if (response.ok) {
        setSuccess(true);
        showToast("Usuário deletado com sucesso!", "success");
      } else {
        setSuccess(null);
        handleCancel(true);
        console.error("Erro ao deletar usuário:", response.statusText);
        showToast("Erro ao deletar usuário. Tente novamente.", "danger");
      }
    } catch (error) {
      setSuccess(false);
      console.error("Erro de rede ao deletar usuário:", error.message);
      showToast("Erro de rede ao deletar usuário. Verifique sua conexão.", "danger");
    } finally {
      setSelectedCollaborator(null);
      handleClose();
    }
  };

  // Adicione mais opções conforme necessário

  //METODOS DOS CHECKBOX GERAL

  //FIM DOS METODOS DE CHECK

  const initialFormData = {
    id: "",
    matricula: "",
    nome: "",
    status: "",
    filial: "",
    centro_de_custo: "",
    funcao_id: "",
    funcao_nome: "",
    tipo_de_contrato: "",
    tipo_de_trabalho: "",
    sexo: "",
    salario: "",
    nivel_funcao: "",
    data: null,
    categorizacao: "",
    data_de_nascimento: null,
    estado_civil: "",
    nome_do_conjuge: "",
    data_conjuge: null,
    quantidade_de_filhos: 0,
    // genero_filho_1: '',
    // nome_filho_01: '',
    // data_nascimento_01: '',
    // genero_filho_2: '',
    // nome_do_filho_02: '',
    // data_nascimento_02: '',
    // genero_filho_03: '',
    // nome_do_filho_03: '',
    // data_nascimento_03: '',
    // genero_de_filho_04: '',
    // nome_do_filho_04: '',
    // data_nascimento_04: '',
    // genero_do_filho_05: '',
    // nome_do_filho_05: '',
    // data_nascimento_05: '',
    formacao_1: "",
    status_1: "",
    nivel_1: "",
    formacao_2: "",
    status_2: "",
    nivel_2: "",
    descricao: "",
    experiencia_anterior: "",
    nivel_experiencia: "",
    tempo_experiencia: "",
    endereco: "",
    telefone: "",
    email: "",
    vendedor: 0,
  };


  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    // Atualiza o formulário quando selectedColaborator é alterado
    if (selectedCollaborator) {
      setFormData(selectedCollaborator);
    }
    console.log(selectedCollaborator)
  }, [selectedCollaborator]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileClick = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await fetch(`${endpoints.showReportFile()}${id}`, {
        headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.blob();
    } catch (error) {
    } finally {
    }
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  return (
    <>
      <Modal show={show} onHide={()=>{setShowDeleteUserModal(false)}}>
        <div className="header_modules">
          <h2 className="product-title">DELETAR USUÁRIO</h2>
        </div>

        <div className="form-rows">
          <Form>
            <Form.Group className="mb-3">
              <SelectFieldFetchWithId
                label="Colaborador"
                funcao={handleChangeCol}
                loadedVal={selectedCollaborator ? selectedCollaborator.id : ""}
                path="/users/list"
                external_value="name"
                fieldId="name"
                required={true}
                placeholder={"Selecione o Usuário"}
              />
            </Form.Group>

            {/* <div>
              <Table
                enablePagination={false}
                enableGlobalFilter={false}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                loading={loading}
                columns={columnsArquivos}
                items={items}
                funcao={(item) => handleFileClick(item.id)}
                initialState={{
                  density: "compact",
                  pagination: { pageSize: 3 },
                }}
                style={{ overflowY: "auto" }}
              />
              </div> */}

<Form>
          {/* Seção 1 */}
          <Row>
            <Col xs={6}>
              <Form.Group controlId="name">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  name="id"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="id">
                <Form.Label>ID</Form.Label>
                <Form.Control
                  type="text"
                  name="id"
                  disabled
                  value={formData.id}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
           
            {/* Adicione mais Col para outros campos na mesma linha */}
          </Row>

          {/* Seção 2 */}
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  name="E-mail"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            {/* Adicione mais Col para outros campos na mesma linha */}
          </Row>

          {/* Continue adicionando seções para os outros campos conforme necessário */}

         
        </Form>
        <div>Permissões :</div>
            <Form.Control
              type="text"
              placeholder="N/A"
              disabled
              value={formData.roles}
              onChange={() => {}}
              // Adicione os props necessários conforme sua lógica
              //ABAIXO, O MODO TABELA PARA DELETAR USUARIO
            />
            <div className="form-container-buttons">
              <Button variant="secondary" onClick={()=>{setShowDeleteUserModal(false)}}>
                Cancelar
              </Button>
              <Button
                variant="danger"
                disabled={!selectedCollaborator}
                onClick={() => {
                  handleDelete();
                  resetFormData();
                }}
              >
                Deletar
              </Button>
            </div>
          </Form>
        </div>
        
      </Modal>
    </>
  );
}

export default ModalDeleteUser;

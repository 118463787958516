import { useState, useEffect, useRef, useMemo } from "react";

import { Alert, Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";

import endpoints from "../../modules/endpoints";

import Table from "../../components/Table";
import SelectFieldFixedNew from "../../components/selectFieldFixedNew";
import "./ModalVacationCollaborator.css"
import { useFetchPerformatic } from "../../hooks/useFetchPerformatic";
import SelectFieldFetchWithId from "../../components/selectFieldFetchWithId";
import { useNavigate } from "react-router-dom";

function ModalVacationCollaborator({setMensagem}) {
 
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.colaboradores());

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const navigate = useNavigate();
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [authToken] = useState(localStorage.getItem("authToken"));
  

  const initialVacationFormData = {
    status: "",
    colaborador_id:"",
    data_entrada: null,
    data_retorno: null,
  };

  const initialFormData = {
    id: "",
    matricula: "",
    nome: "",
    cpf: "",
    pis: "",
    rg: "",
    orgao_exp: "",
    UF: "",
    data_exp: null,
    ctps: "",
    serie_ctps: "",
    uf_ctps: "",
    data_ctps: null,
    status: "EM CONTRATAÇÃO",
    filial: "",
    vendedor: 0,
    centro_de_custo: "",
    funcao_id: "",
    funcao_nome: "",
    tipo_de_contrato: "INDETERMINADO",
    dias_determinados: "",
    tipo_de_trabalho: "",
    sexo: "",
    salario: "",
    nivel_funcao: "",
    data: null,
    categorizacao: "",
    data_de_nascimento: null,
    estado_civil: "",
    nome_do_conjuge: "",
    data_conjuge: null,
    quantidade_de_filhos: 0,
    // genero_filho_1: '',
    // nome_filho_01: '',
    // data_nascimento_01: '',
    // genero_filho_2: '',
    // nome_do_filho_02: '',
    // data_nascimento_02: '',
    // genero_filho_03: '',
    // nome_do_filho_03: '',
    // data_nascimento_03: '',
    // genero_de_filho_04: '',
    // nome_do_filho_04: '',
    // data_nascimento_04: '',
    // genero_do_filho_05: '',
    // nome_do_filho_05: '',
    // data_nascimento_05: '',
    formacao_1: "",
    status_1: "",
    nivel_1: "",
    formacao_2: "",
    status_2: "",
    nivel_2: "",
    descricao: "",
    experiencia_anterior: "",
    nivel_experiencia: "",
    tempo_experiencia: "",
    endereco: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "",
    cep: "",
    telefone: "",
    email: "",
    banco: "",
    agencia: "",
    conta: "",
    tipo_conta: "",
    op: "",
    transporte: "N/A",
    alimentacao: "N/A",
    plano_saude: "N/A",
    cesta: "N/A",
    ajuda_custo: "N/A",
    beneficio_obs: "N/A",
    nome_pai: "",
    nome_mae: "",
  };

  

  const handleChangeCol = (event) => {
    const selectedId = event.target.value;
    const selected = items.find((item) => item.id === selectedId);
    setSelectedCollaborator(selected);

    if (selected) {
      // Atualiza o formData com os valores do colaborador selecionado
      setFormData({
        ...initialFormData,
        id: selected.id,
        matricula: selected.matricula,
        nome: selected.nome,
        cpf: selected.cpf,
        pis: selected.pis,
        rg: selected.rg,
        orgao_exp: selected.orgao_exp,
        UF: selected.UF,
        data_exp: selected.data_exp,
        ctps: selected.ctps,
        serie_ctps: selected.serie_ctps,
        uf_ctps: selected.uf_ctps,
        data_ctps: selected.data_ctps,
        filial: selected.filial,
        vendedor: selected.vendedor,
        centro_de_custo: selected.centro_de_custo,
        funcao_id: selected.funcao_id,
        funcao_nome: selected.funcao_nome,
        tipo_de_contrato: selected.tipo_de_contrato,
        tipo_de_trabalho: selected.tipo_de_trabalho,
        sexo: selected.sexo,
        salario: selected.salario,
        nivel_funcao: selected.nivel_funcao,
        data: selected.data,
        categorizacao: selected.categorizacao,
        data_de_nascimento: selected.data_de_nascimento,
        estado_civil: selected.estado_civil,
        nome_do_conjuge: selected.nome_do_conjuge,
        data_conjuge: selected.data_conjuge,
        quantidade_de_filhos: selected.quantidade_de_filhos,
        formacao_1: selected.formacao_1,
        status_1: selected.status_1,
        nivel_1: selected.nivel_1,
        formacao_2: selected.formacao_2,
        status_2: selected.status_2,
        nivel_2: selected.nivel_2,
        descricao: selected.descricao,
        experiencia_anterior: selected.experiencia_anterior,
        nivel_experiencia: selected.nivel_experiencia,
        tempo_experiencia: selected.tempo_experiencia,
        endereco: selected.endereco,
        bairro: selected.bairro,
        cidade: selected.cidade,
        estado: selected.estado,
        pais: selected.pais,
        cep: selected.cep,
        telefone: selected.telefone,
        email: selected.email,
        banco: selected.banco,
        agencia: selected.agencia,
        conta: selected.conta,
        tipo_conta: selected.tipo_conta,
        op: selected.op,
        transporte: selected.transporte,
        alimentacao: selected.alimentacao,
        plano_saude: selected.plano_saude,
        cesta: selected.cesta,
        ajuda_custo: selected.ajuda_custo,
        beneficio_obs: selected.beneficio_obs,
        nome_pai: selected.nome_pai,
        nome_mae: selected.nome_mae,
      });
      setvacationFormData({
        ...vacationFormData,
        colaborador_id: selected.id,
        status: 'FÉRIAS',
      });
     
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;

    setvacationFormData((prevData) => ({ ...prevData, [name]: value.toUpperCase() }));
  };

  const handleSave = async () => {
    // Verifique se o colaborador foi selecionado
    if (!selectedCollaborator) {
      // Adicione lógica de tratamento aqui (por exemplo, exibir uma mensagem de erro)
      return;
    }
    
    

    try {
      
      const response = await fetch(endpoints.vacation(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Adicione outros headers necessários aqui
        },
        body: JSON.stringify(vacationFormData),
      });
  
      // Verifique o status da resposta
      if (response.ok) {
       
        setMensagem("Solicitação realizada com sucesso.")
      } else {
        
       
        
        setMensagem("Erro ao solicitar férias.");
        console.error("Erro ao desligar colaborador:", response.statusText);
      }
    } catch (error) {
      
      console.error("Erro de rede ao desligar colaborador:", error.message);
    } finally {
      // Adicione essa chamada para exibir o Toast com base no valor de success
      handleCloseDialog();
    }
  };

  // Adicione mais opções conforme necessário


  //METODOS DOS CHECKBOX GERAL

  const [checkAllStates, setCheckAllStates] = useState({});
  const [isAdmin, setIsAdmin] = useState(false); // Novo estado para controlar se é administrador
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');
 
  //FIM DOS METODOS DE CHECK

  

 
  const [vacationFormData, setvacationFormData] = useState(initialVacationFormData);
  const [formData, setFormData] = useState(initialFormData);

 

  const resetFormData = () => {
    setFormData(initialFormData);
   
    setSelectedCollaborator(null);
    setCheckAllStates({});
    setIsAdmin(false);
  };

  const handleCloseDialog = () => {
 
    setIsEditing(false);
    setOpenDialog(false);
  };
  

  return (
    <>

<Button onClick={handleOpenDialog} className="btn-add-roles btn-success">
        Solicitar férias
      </Button>

      <Modal show={openDialog} onHide={handleCloseDialog}>
        <div className="header_modules">
          <h2 className="product-title">SOLICITAR FÉRIAS</h2>
        </div>

        <div className="form-rows">
          <Form>
            <Row>
            <Form.Group className="mb-3">
              <SelectFieldFetchWithId
                label="Colaborador"
                funcao={handleChangeCol}
                loadedVal={selectedCollaborator ? selectedCollaborator.id : ""}
                path="/collaborators"
                external_value="nome"
                fieldId="id"
                required={true}
                placeholder={"Selecione o Colaborador"}
              />
            </Form.Group>
            </Row>
            <Row>
            <Col xs={12}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data de inicio</Form.Label>
                      <Form.Control
                        value={vacationFormData.data_entrada}
                        name="data_entrada"
                        type="date"
                        onChange={handleChange}
                       
                      />
                    </Form.Group>
                  </Col>
            </Row>
            <Row>
            <Col xs={12}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data de retorno</Form.Label>
                      <Form.Control
                        value={vacationFormData.data_retorno}
                        name="data_retorno"
                        type="date"
                        onChange={handleChange}
                       
                      />
                    </Form.Group>
                  </Col>
            </Row>


            <div className="form-container-buttons">
              <Button variant="secondary" onClick={handleCloseDialog}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                disabled={!selectedCollaborator}
                onClick={() => {
                  
                  handleSave();
                  resetFormData();
                  
                }}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default ModalVacationCollaborator;

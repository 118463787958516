import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button, Modal, Table } from 'react-bootstrap';

const ExcelViewer = ({ fileContent, fileName, isViewerOpen, setIsViewerOpen }) => {
  const [xlsxData, setXLSXData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(isViewerOpen);

  useEffect(() => {
    setModalIsOpen(isViewerOpen);  // Atualize o estado quando isViewerOpen mudar
  }, [isViewerOpen]);

  const closeModal = () => {
    setModalIsOpen(false);
    setIsViewerOpen(false);
    
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const buffer = await fileContent.arrayBuffer();
        const data = new Uint8Array(buffer);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assume que há apenas uma planilha no arquivo XLSX
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Converte o conteúdo da planilha em formato de objeto legível
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setXLSXData(jsonData);

        // Abre automaticamente a modal ao carregar o conteúdo do XLSX
        setShowModal(true);
      } catch (error) {
        console.error('Erro ao processar o arquivo XLSX:', error);
      }
    };

    fetchData();
  }, [fileContent]);

  const downloadExcel = () => {
    const filename = fileName;
    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${filename}.xlsx`);
  };

  

  return (
    <div>
      {xlsxData && (
        <Modal show={modalIsOpen} onHide={closeModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Conteúdo do Excel</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            <Table striped bordered hover>
              <thead>
                <tr>{xlsxData[0].map((header, index) => <th key={index}>{header}</th>)}</tr>
              </thead>
              <tbody>
                {xlsxData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => <td key={cellIndex}>{cell}</td>)}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Fechar
            </Button>
            <Button variant="primary" onClick={downloadExcel}>
              Download XLSX
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ExcelViewer;

import React, { useEffect, useState } from 'react';

import './ChangePassword.css';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useRequest } from '../hooks/useRequest';
import endpoints from '../modules/endpoints';
import Sidebar from '../components/Sidebar';

function ChangePassword() {
  const { sendRequest, response } = useRequest();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    
    try {
      sendRequest(endpoints.changePassword(), 'POST', { old_password: oldPassword, password: newPassword, password_confirmation: newPasswordConfirmation });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (response) {
      if (response.status === 'error') {
        setWarning(null);
        setError(response.message);
      } else {
        console.log(response);
        setWarning(response.message);
        setError(null);
        setNewPassword('');
        setOldPassword('');
        setNewPasswordConfirmation('');
      }
    }
  }, [response]);

  return (
    <>
      <Sidebar />
      <div className='content'>
        {
          <div className='changepass-container'>
            <Row className=''>
              <Col xs={12}>
                <Card bg='dark changepass-card' text='light'>
                  <Card.Body className='changepass-page-form'>
                    <Form onSubmit={handleLogin}>
                      <Form.Group controlId='formBasicPassOld'>
                        <Form.Control
                          required
                          value={oldPassword}
                          type='password'
                          placeholder='Senha antiga'
                          className='changepass-input'
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId='formBasicNewPassword'>
                        <Form.Control
                          required
                          value={newPassword}
                          type='password'
                          placeholder='Senha nova'
                          className='changepass-input'
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId='formBasicNewPasswordConfirmation'>
                        <Form.Control
                          required
                          value={newPasswordConfirmation}
                          type='password'
                          placeholder='Confirmar senha'
                          className='changepass-input'
                          onChange={(e) => {
                            setNewPasswordConfirmation(e.target.value);
                          }}
                        />
                      </Form.Group>
                      {
                        <div className='error-message'>
                          <span>{error && error}</span>
                        </div>
                      }
                      {
                        <div className='warning-message'>
                          <span>{warning && warning}</span>
                        </div>
                      }

                      <Button type='submit' className='btn-submit-login w-100'>
                        TROCAR SENHA
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        }
      </div>
    </>
  );
}

export default ChangePassword;

import { useState } from 'react';
import Form from 'react-bootstrap/Form';

function FilterDate(props) {
    const [selectedOption, setSelectedOption] = useState(props.startOption);

    function handleOptionChange(event) {
        const value = event.target.value;
        setSelectedOption(value);

        const today = new Date();
        let startDate = null;
        let endDate = null;

        if (value === 'this_week') {
            const firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
            startDate = firstDayOfWeek.toISOString().substring(0, 10);
            endDate = today.toISOString().substring(0, 10);
        } else if (value === 'this_month') {
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            startDate = firstDayOfMonth.toISOString().substring(0, 10);
            endDate = today.toISOString().substring(0, 10);
        } else if (value === 'last_month') {
            const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
            startDate = lastMonth.toISOString().substring(0, 10);
            endDate = lastDayOfMonth.toISOString().substring(0, 10);
        }

        props.onDateRangeChange(startDate, endDate, value);
    }

    return (
        <div>
            <Form.Select value={selectedOption} onChange={handleOptionChange}>
                <option value="this_week">Esta Semana</option>
                <option value="this_month">Mês atual</option>
                <option value="last_month">Mês passado</option>
                <option value="all_time">Todos os registros</option>
            </Form.Select>
        </div>
    );
}
export default FilterDate

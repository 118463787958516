import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import logo from './logo.png';
import './NPSResearch.css'

import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';

import endpoints from '../../modules/endpoints';
import { useRequest } from '../../hooks/useRequest';

function NPSResearch() {
  document.title = 'Pesquisa interna';

  const [showThanks, setShowThanks] = useState(false);
  const [invalidResearch, setInvalidResearch] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [score, setScore] = useState(null);
  const [comment, setComment] = useState('');

  const { sendRequest, response } = useRequest();
  const { sendRequest: getResearch, response: researchResponse } = useRequest();

  const { rid, campanha } = useParams();

  useEffect(() => {
    getResearch(`${endpoints.pesquisaInterna()}/${campanha}/${rid}`, 'GET');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanha, rid]);

  useEffect(() => {
    if (researchResponse) {
      if (researchResponse.length > 0) {
        if (researchResponse[0].respondido_em !== null) {
          setShowThanks(true);
          setShowForm(false)
        } else {
          setShowForm(true);
        }
      } else {
        setInvalidResearch(true);
      }
    }

  }, [researchResponse])

  useEffect(() => {
    if (response && response.message === 'Resposta enviada com sucesso!') {
      setShowThanks(true);
      setShowForm(false)
    }
  }, [response]);


  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      sendRequest(endpoints.pesquisaInterna(), 'PUT', 'teste');
    }
  };


  const handleScoreChange = (selectedScore) => {
    setScore(selectedScore);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };



  return (
    <div className='content-research'>
      {invalidResearch && <>
        <Container className='thanks'>
          <Col xs={3}>
            <div></div>
          </Col>
          <Col xs={6}>
            <div className="text-center ">
              <div>
                <img src={logo} className="app-logo" alt="logo" height={120} />
                <h3 className='custom-heading'>Link de pesquisa inválido!</h3>
              </div>
            </div>
          </Col>
        </Container>
      </>}

      {showForm && <div className='back-reasearch'>
        <Container className="research ">
          <Row className="research-nps">
            <Col xs={12} md={8}>
              <div className="text-center ">
                <div className='research-header'>
                  <img src={logo} className="app-logo" alt="logo" height={120} />
                  <h3 className='custom-heading'>Pesquisa de satisfação</h3>
                  <p>Queremos te ouvir, sua contribuição é muito importante!</p>
                </div>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formIndicateToFriend" as={Row}>
                  <div className='question'>Qual a probabilidade de você recomendar nosso serviço para um parceiro?</div>
                  <Col sm={12} xs={12} md={12} >
                    <div className="score-container">
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                        <button
                          key={value}
                          type="button"
                          className={`score-button ${score === value ? 'selected' : ''} ${value <= 6 ? 'red' : value <= 8 ? 'yellow' : 'green'
                            }`}
                          onClick={() => handleScoreChange(value)}
                        >
                          {value}
                        </button>
                      ))}
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicComentario">
                  <div className='question'>Por favor, deixe seus comentários ou sugestões:</div>
                  <Form.Control as="textarea" value={comment} name='comment' rows={5} onChange={handleCommentChange} />
                </Form.Group>
                <div className=''>
                  <Button variant="primary" type="submit">
                    Enviar
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container >
      </div>}
      {showThanks ? <>
        <Container className='thanks'>
          <Col xs={3}>
            <div></div>
          </Col>
          <Col xs={6}>
            <div className="text-center ">
              <div>
                <img src={logo} className="app-logo" alt="logo" height={120} />
                <h3 className='custom-heading'>Muito Obrigado!</h3>
                <p>Agradecemos muito por ter dedicado um tempo para responder nossa pesquisa.
                  Seus comentários são muito valiosos e nos ajudarão a melhorar continuamente.</p>
              </div>
            </div>
          </Col>
        </Container>
      </> : !showForm && <Container className='thanks'>
        <Col xs={6}>
          <div></div>
        </Col>
        <Col xs={1}>
          <Spinner></Spinner>
        </Col>
      </Container>}
    </div>
  )
}

export default NPSResearch
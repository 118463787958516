import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col, Alert } from "react-bootstrap";
import SelectFieldFetchWithId from "../selectFieldFetchWithId";
import SelectFieldFixedNew from "../selectFieldFixedNew";
import InputMask from "react-input-mask";
import { validate } from "cpf-check";
import { useFetchPerformatic } from "../../hooks/useFetchPerformatic";
import endpoints from "../../modules/endpoints";
import "./AddCollaborator.css";

function AddCollaborator({setMensagem}) {

  const [authToken] = useState(localStorage.getItem("authToken"));
  const initialFormData = {
    id: "",
    matricula: "",
    nome: "",
    cpf: "",
    pis: "",
    rg: "",
    orgao_exp: "",
    UF: "",
    data_exp: null,
    ctps: "",
    serie_ctps: "",
    uf_ctps: "",
    data_ctps: null,
    status: "EM CONTRATAÇÃO",
    filial: "",
    vendedor: 0,
    centro_de_custo: "",
    funcao_id: "",
    funcao_nome: "",
    tipo_de_contrato: "INDETERMINADO",
    dias_determinados: "",
    tipo_de_trabalho: "",
    sexo: "",
    salario: "",
    nivel_funcao: "",
    data: null,
    categorizacao: "",
    data_de_nascimento: null,
    estado_civil: "",
    nome_do_conjuge: "",
    data_conjuge: null,
    cpf_conjuge:"",
    quantidade_de_filhos: 0,
    // genero_filho_1: '',
    // nome_filho_01: '',
    // data_nascimento_01: '',
    // genero_filho_2: '',
    // nome_do_filho_02: '',
    // data_nascimento_02: '',
    // genero_filho_03: '',
    // nome_do_filho_03: '',
    // data_nascimento_03: '',
    // genero_de_filho_04: '',
    // nome_do_filho_04: '',
    // data_nascimento_04: '',
    // genero_do_filho_05: '',
    // nome_do_filho_05: '',
    // data_nascimento_05: '',
    formacao_1: "",
    status_1: "",
    nivel_1: "",
    formacao_2: "",
    status_2: "",
    nivel_2: "",
    descricao: "",
    experiencia_anterior: "",
    nivel_experiencia: "",
    tempo_experiencia: "",
    endereco: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "",
    cep: "",
    telefone: "",
    email: "",
    banco: "",
    agencia: "",
    conta: "",
    tipo_conta: "",
    op: "",
    transporte: "N/A",
    alimentacao: "N/A",
    plano_saude: "N/A",
    cesta: "N/A",
    ajuda_custo: "N/A",
    beneficio_obs: "N/A",
    nome_pai: "",
    nome_mae: "",
  };

  const initialDependentData = {
    collaborator_id: "",
    nome: "",
    data_nascimento: "",
    CPF: "",
  }

  const [openDialog, setOpenDialog] = useState(false);
  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.colaboradores());
  const [isEditing, setIsEditing] = useState(false);
  const [contractType, setContractType] = useState("indeterminado"); // 'determinado' ou 'indeterminado'
  const [formData, setFormData] = useState(initialFormData);
  const [dependentData, setDependentData] = useState([initialDependentData]); //criar um vetor de dependentes, parei aqui !!!!!
  const [cpfValid, setCpfValid] = useState(true);
  const [cepValid, setCepValid] = useState(true);
  const [pisValid, setPisValid] = useState(true);
  const [bairroDisabled, setBairroDisabled] = useState(true); // Estado para controlar se o campo de bairro está habilitado ou desabilitado
  const [enderecoDisabled, setEnderecoDisabled] = useState(true); // Estado para controlar se o campo de endereço está habilitado ou desabilitado
  const [conjugeDisabled, setConjugeDisabled] = useState(false);
  const [filhoDisabled, setFilhoDisabled] = useState(false);
  const [opDisabled, setOpDisabled] = useState(true);
  const [formacaoVisible, setFormacaoVisible] = useState(false);
  const [formacao2Visible, setFormacao2Visible] = useState(false);
  
  
  const [endereco, setEndereco] = useState({
    localidade: "",
    uf: "",
    logradouro: "",
    bairro: "",
  });

  const handleAdicionarFormacao = () => {
    if (!formacaoVisible) {
      setFormacaoVisible(true);
    } else if (formacaoVisible && formacao2Visible) {
      setFormacaoVisible(false);
      setFormacao2Visible(false);
    } else {
      setFormacaoVisible(false);
    }
  };

  const handleAdicionarFormacao2 = () => {
    if (!formacao2Visible) {
      setFormacao2Visible(true);
    } else {
      setFormacao2Visible(false);
    }
  };

  const handleCepChange = (e) => {
    const { value } = e.target;
    const unmaskedCep = value.replace(/\D/g, ""); // Remove caracteres não numéricos

    if (unmaskedCep.length === 8) {
      // Ajuste para 8, pois a máscara adiciona um caractere
      const maskedCep = unmaskedCep.replace(/^(\d{5})(\d{3}).*/, "$1-$2"); // Aplica a máscara

      fetch(`https://viacep.com.br/ws/${unmaskedCep}/json/`)
        .then((response) => response.json())
        .then((data) => {
          if (data.erro) {
            throw new Error("CEP não encontrado");
          }
          setEndereco({
            localidade: data.localidade,
            uf: data.uf,
            logradouro: data.logradouro,
            bairro: data.bairro,
          });
          setCepValid(true);
          handleChange({ target: { name: "cep", value: maskedCep } }); // Atualiza o estado com o CEP formatado
          handleChange({ target: { name: "cidade", value: data.localidade } }); // Atualiza apenas o campo 'cidade'
          handleChange({
            target: { name: "endereco", value: data.logradouro },
          }); // Atualiza apenas o campo 'endereco'
          handleChange({ target: { name: "bairro", value: data.bairro } }); // Atualiza apenas o campo 'bairro'
          handleChange({ target: { name: "estado", value: data.uf } }); // Atualiza apenas o campo 'UF'

          // Verifica se o bairro e o endereço estão disponíveis, se não estiverem, habilita os campos correspondentes
          if (!data.bairro && !data.logradouro) {
            setBairroDisabled(false);
            setEnderecoDisabled(false);
          }
        })
        .catch((error) => {
          console.error("Erro ao consultar o CEP:", error);
          setEndereco({
            localidade: "",
            uf: "",
            logradouro: "",
            bairro: "",
          });
          setCepValid(false);
          handleChange({ target: { name: "cep", value: maskedCep } }); // Atualiza o estado com o CEP sem a máscara
          handleChange({ target: { name: "cidade", value: "" } }); // Limpa o campo 'cidade'
          handleChange({ target: { name: "endereco", value: "" } }); // Limpa o campo 'endereco'
          handleChange({ target: { name: "bairro", value: "" } }); // Limpa o campo 'bairro'
          handleChange({ target: { name: "estado", value: "" } }); // Limpa o campo 'UF'
          setBairroDisabled(true); // Desabilita o campo de bairro
          setEnderecoDisabled(true); // Desabilita o campo de endereço
        });
    } else {
      handleChange({ target: { name: "cep", value: unmaskedCep } }); // Atualiza o estado com o CEP sem a máscara
      setEndereco({
        localidade: "",
        uf: "",
        logradouro: "",
        bairro: "",
      });
      setCepValid(false);
      handleChange({ target: { name: "cidade", value: "" } }); // Limpa o campo 'cidade'
      handleChange({ target: { name: "endereco", value: "" } }); // Limpa o campo 'endereco'
      handleChange({ target: { name: "bairro", value: "" } }); // Limpa o campo 'bairro'
      handleChange({ target: { name: "estado", value: "" } }); // Limpa o campo 'UF'
      setBairroDisabled(true); // Desabilita o campo de bairro
      setEnderecoDisabled(true); // Desabilita o campo de endereço
    }
  };

  const handleCpfChange = (e) => {
    const { value } = e.target;
    const unmaskedCpf = value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const maskedCpf = unmaskedCpf.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
      "$1.$2.$3-$4"
    ); // Aplica a máscara
    const isValid = validate(unmaskedCpf);
    setCpfValid(isValid);
    handleChange({ target: { name: "cpf", value: maskedCpf } }); // Atualiza o estado com o CPF formatado
  };

  const handlePisChange = (e) => {
    const { value } = e.target;
    const unmaskedPis = value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const maskedPis = unmaskedPis.replace(
      /^(\d{3})(\d{5})(\d{2})(\d{1}).*/,
      "$1.$2.$3-$4"
    ); // Aplica a máscara
    handleChange({ target: { name: "pis", value: maskedPis } }); // Atualiza o estado com o CPF formatado
  };

  const handleRgChange = (e) => {
    const { value } = e.target;
    const unmaskedRg = value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const maskedRg = unmaskedRg.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{1}).*/,
      "$1.$2.$3-$4"
    ); // Aplica a máscara
    handleChange({ target: { name: "rg", value: maskedRg } }); // Atualiza o estado com o CPF formatado
  };

  const handleAgenciaChange = (e) => {
    const { value } = e.target;
    const unmaskedAgencia = value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const maskedAgencia = unmaskedAgencia.replace(/^(\d{5})(\d{1}).*/, "$1-$2"); // Aplica a máscara
    handleChange({ target: { name: "agencia", value: maskedAgencia } }); // Atualiza o estado com a agência formatada
  };

  const handleContaNumChange = (e) => {
    const { value } = e.target;
    const unmaskedAgencia = value.replace(/\D/g, ""); // Remove caracteres não numéricos
    const maskedAgencia = unmaskedAgencia.replace(/^(\d{6})(\d{1}).*/, "$1-$2"); // Aplica a máscara
    handleChange({ target: { name: "conta", value: maskedAgencia } }); // Atualiza o estado com a agência formatada
  };

  const handleCivilChange = (e) => {
    const { value } = e.target;

    if (value === "CASADO" || value === "VIÚVO") {
      setConjugeDisabled(false);
    } else {
      setConjugeDisabled(true);
      handleChange({ target: { name: "nome_do_conjuge", value: "" } });
      handleChange({ target: { name: "data_conjuge", value: "" } });
    }
    handleChange({ target: { name: "estado_civil", value: value } });
  };

  const handleContaChange = (e) => {
    const { value } = e.target;

    if (value === "CONTA POUPANÇA") {
      setOpDisabled(false);
    } else {
      setOpDisabled(true);
      handleChange({ target: { name: "op", value: "" } });
    }
    handleChange({ target: { name: "tipo_conta", value: value } });
  };

  const generoList = [
    { id: 1, value: "MASCULINO", label: "M" },
    { id: 2, value: "FEMININO", label: "F" },
  ];

  const vendedorStatus = [
    { id: 1, value: 0, label: "N" },
    { id: 2, value: 1, label: "S" },
  ];

  const filialList = [
    { id: 1, value: "Filial Maracás", label: "Filial Maracás" },
    { id: 2, value: "Filial Dias D'avilla", label: "Filial Dias D'avilla" },
    { id: 3, value: "Sede", label: "Sede" },
  ];

  const estadoCivilList = [
    { id: 1, value: "SOLTEIRO", label: "SOLTEIRO" },
    { id: 2, value: "CASADO", label: "CASADO" },
    { id: 3, value: "DIVORCIADO", label: "DIVORCIADO" },
    { id: 4, value: "SEPARADO", label: "SEPARADO" },
    { id: 5, value: "VIÚVO", label: "VIÚVO" },
  ];

  const StatusList = [
    { id: 1, value: "EM CONTRATACAO", label: "EM CONTRATAÇÃO" },
    { id: 2, value: "ATIVO", label: "ATIVO" },
    { id: 3, value: "FERIAS", label: "FÉRIAS" },
    { id: 4, value: "INATIVO", label: "INATIVO" },
  ];

  const tipoContaList = [
    { id: 1, value: "CONTA CORRENTE", label: "CONTA CORRENTE" },
    { id: 2, value: "CONTA POUPANÇA", label: "CONTA POUPANÇA" },
    { id: 3, value: "CONTA SALÁRIO", label: "CONTA SALÁRIO" },
  ];

  const getBotaoLabel = () => {
    return formacaoVisible ? 'Remover Formação' : 'Adicionar Formação';
  };

  const getBotaoSymbol = () => {
    return formacaoVisible ? '-' : '+';
  };

  const getBotaoLabel2 = () => {
    return formacao2Visible ? 'Remover Formação 2' : 'Adicionar Formação 2';
  };

  const getBotaoSymbol2 = () => {
    return formacao2Visible ? '-' : '+';
  };


  const handleChangeValor = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setFormData({ ...formData, [name]: null });
    } else {
      const rawValue = value;
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      setFormData({ ...formData, [name]: formattedValue });
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setContractType("indeterminado"); // Reseta o tipo de contrato ao fechar o diálogo
    resetDataForms();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const resetDataForms = () => {
    setFormData(initialFormData);
    setDependentData([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value.toUpperCase() }));
    
  };

  const renderCamposDosFilhos = () => {
    const camposDosFilhos = [];
    for (let i = 0; i < formData.quantidade_de_filhos; i++) {
      camposDosFilhos.push(
        <Row key={i}>
          <Col xs={3}>
            <Form.Group className="mb-1">
              <Form.Label>Nome do filho {i + 1}</Form.Label>
              <Form.Control
                name={`nome_do_filho_${i}`}
                type="text"
                onChange={(e) => handleDependentChange(i, 'nome', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group className="mb-1">
              <Form.Label>Data de nascimento: filho {i + 1}</Form.Label>
              <Form.Control
                name={`data_de_nascimento_do_filho_${i}`}
                type="date"
                onChange={(e) => handleDependentChange(i, 'data_nascimento', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group className="mb-1">
              <Form.Label>CPF do filho {i + 1}</Form.Label>
              <Form.Control
                name={`cpf_do_filho_${i}`}
                type="text"
                onChange={(e) => handleDependentChange(i, 'CPF', e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      );
    }
    return camposDosFilhos;
  };

  const handleAddDependent = () => {
    const newDependentData = [...dependentData, initialDependentData];
    setDependentData(newDependentData);
  };

  const handleDependentChange = (index, field, value) => {
    const newDependentData = [...dependentData];
    newDependentData[index] = {
      ...newDependentData[index],
      [field]: value
    };
    setDependentData(newDependentData);
  };

  const handleChangeFunction = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      const newFormData = {
        ...formData,
        salario: formData.salario ? formData.salario.replace(/,/g, "") : null,
      };

      if (newFormData.estado_civil !== "CASADO") {
        newFormData.data_conjuge = null;
      }

      if (isEditing) {
        delete newFormData.codigo_pesquisas;
         await httpConfig(newFormData, "PUT");
      } else {
        delete newFormData.id;
        delete newFormData.funcao_nome;
        
          const response = await fetch(endpoints.colaboradores(), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newFormData),
          });
          
        console.log(response);
        // Verifica se a resposta foi bem-sucedida e tem o ID do colaborador
        if (response.ok) {
          const responseData = await response.json(); // Obtenha os dados da resposta como JSON
          const colaboradorId = responseData.id; // Obtenha o ID do colaborador da resposta
          if (colaboradorId) {
              // Salvar dependentes
              await saveDependents(colaboradorId);
              const response2 = await fetch(endpoints.enviarEmailColaborador(), {
                method: 'POST',
                headers: {
                  'Authorization': `bearer ${authToken}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({id: colaboradorId}),
              });

              setMensagem('Colaborador cadastrado com sucesso');
          } else {
              setMensagem('Falha ao cadastrar o colaborador');
          }
      } else {
          setMensagem('Falha ao cadastrar o colaborador');
      }
  }

  handleCloseDialog();
  setIsEditing(false);
}

   
  };

  const saveDependents = async (colaboradorId) => {
    try {
        for (const dependent of dependentData) {
            // Crie uma cópia independente do objeto dependente atual
            const dependentDataWithColaboradorId = {
                ...dependent,
                collaborator_id: colaboradorId,
            };

            // Realize a solicitação para salvar o dependente atual
            const response = await fetch(endpoints.dependents(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dependentDataWithColaboradorId),
            });

            // Verifique se a solicitação foi bem-sucedida
            if (!response.ok) {
                throw new Error('Falha ao salvar dependente');
            }

            // Obtenha os dados do dependente da resposta
            const responseData = await response.json();
            console.log('Dependente salvo:', responseData);
        }

        // Todos os dependentes foram salvos com sucesso
        console.log('Todos os dependentes foram salvos com sucesso');
    } catch (error) {
        console.error('Erro ao salvar dependente:', error);
    }
};
  
  // UseEffect para chamar a função de salvar dependentes sempre que dependentData mudar
  useEffect(() => {
    // Aqui você pode chamar a função de salvar dependentes se desejar salvar automaticamente
  }, [dependentData]);

  return (
    <>


      <Button onClick={handleOpenDialog} className="btn-add-roles btn-success">
        Gerar contratação
      </Button>

      <Modal show={openDialog} onHide={handleCloseDialog} centered size="xl">
      
        <Form onSubmit={handleSubmit} className="form-page">
          <Modal.Header closeButton>
            {!isEditing ? (
              <Modal.Title>Novo Colaborador</Modal.Title>
            ) : (
              <Modal.Title>Editar Colaborador</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <div style={{paddingBottom:"10px"}}>
            <span className="custom-label" style={{textDecoration:"none"}}>Tipo do colaborador :</span>
            </div>
              <Row>
                <Col xs={6}>
                  <Form.Check
                    type="radio"
                    label={<span className="custom-label">PRAZO DETERMINADO</span>}
                    name="contractType"
                    checked={contractType === "determinado"}
                    onChange={() => {
                      setContractType("determinado");
                      formData.tipo_de_contrato = "DETERMINADO";
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <Form.Check
                    type="radio"
                    label={<span className="custom-label">PRAZO INDETERMINADO</span>}
                    name="contractType"
                    checked={contractType === "indeterminado"}
                    onChange={() => {
                      setContractType("indeterminado");
                      formData.tipo_de_contrato = "INDETERMINADO";
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
                    <br></br>
            {contractType === "indeterminado" && (
              <>
                {/* Campos para contrato por prazo indeterminado */}
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Matrícula</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.matricula}
                        name="matricula"
                        type="number"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        value={formData.nome}
                        name="nome"
                        type="text"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        value={formData.status}
                        name="status"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Filial"
                        setValue={handleChangeFunction}
                        value={formData.filial}
                        items={filialList}
                        name="filial"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome da mãe</Form.Label>
                      <Form.Control
                        value={formData.nome_mae}
                        name="nome_mae"
                        type="text"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome do pai</Form.Label>
                      <Form.Control
                        value={formData.nome_pai}
                        name="nome_pai"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col xs={2}>
                    <Form.Group controlId="rg">
                      <Form.Label>RG</Form.Label>
                      <InputMask
                        mask="99.999.999-9"
                        maskChar="_"
                        value={formData.rg}
                        onChange={handleRgChange}
                        required
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="rg"
                            type="text"
                            placeholder="Digite o RG"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Orgão emissor</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.orgao_exp}
                        name="orgao_exp"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.UF}
                        name="UF"
                        type="text"
                        maxLength={2} // Adicionando o atributo maxLength
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data de expedição</Form.Label>
                      <Form.Control
                        value={formData.data_exp}
                        name="data_exp"
                        type="date"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="cpf">
                      <Form.Label>CPF</Form.Label>
                      <InputMask
                        mask="999.999.999-99"
                        maskChar="_"
                        value={formData.cpf}
                        onChange={handleCpfChange}
                        required
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="cpf"
                            type="text"
                            placeholder="Digite o CPF"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                    {!cpfValid && (
                      <div style={{ color: "red" }}>CPF inválido</div>
                    )}
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="pis">
                      <Form.Label>PIS</Form.Label>
                      <InputMask
                        mask="999.99999.99-9"
                        maskChar="_"
                        value={formData.pis}
                        onChange={handlePisChange}
                        
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="pis"
                            type="text"
                            placeholder="Digite o PIS"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Número da CTPS</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.ctps}
                        name="ctps"
                        type="text"
                        maxLength={7}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Série CTPS</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.serie_ctps}
                        name="serie_ctps"
                        type="text"
                        maxLength={4}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.uf_ctps}
                        name="uf_ctps"
                        type="text"
                        maxLength={2} // Adicionando o atributo maxLength
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data de expedição</Form.Label>
                      <Form.Control
                        value={formData.data_ctps}
                        name="data_ctps"
                        type="date"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Vendedor"
                        setValue={handleChange}
                        value={formData.vendedor}
                        items={vendedorStatus}
                        name="vendedor"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      {/* <Form.Label>Função</Form.Label>
                                        <Form.Control value={formData.funcao} name='funcao' type="text" onChange={handleChange} /> */}
                      {/* <SelectFieldFetchNew label='função' name='funcao' setValue={handleChange} value={formData.funcao} path='/roles' field={'nome'} placeholder='Selecione uma função' /> */}
                      <SelectFieldFetchWithId
                        label="Função"
                        funcao={handleChangeFunction}
                        loadedVal={formData.funcao_id}
                        path="/positions"
                        external_value={"nome"}
                        fieldId={"funcao_id"}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Centro de custo</Form.Label>
                      <Form.Control
                        value={formData.centro_de_custo}
                        name="centro_de_custo"
                        type="text"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Tipo de contrato</Form.Label>
                      <Form.Control
                        value={formData.tipo_de_contrato}
                        name="tipo_de_contrato"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Tipo de trabalho</Form.Label>
                      <Form.Control
                        value={formData.tipo_de_trabalho}
                        name="tipo_de_trabalho"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Gênero"
                        setValue={handleChange}
                        value={formData.sexo}
                        items={generoList}
                        name="sexo"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Salário</Form.Label>
                      <Form.Control
                        value={formData.salario}
                        name="salario"
                        type="text"
                        onChange={handleChangeValor}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nível função</Form.Label>
                      <Form.Control
                        value={formData.nivel_funcao}
                        name="nivel_funcao"
                        type="text"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data</Form.Label>
                      <Form.Control
                        value={formData.data}
                        name="data"
                        type="date"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Categorização</Form.Label>
                      <Form.Control
                        value={formData.categorizacao}
                        name="categorizacao"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Estado civil"
                        setValue={handleCivilChange}
                        value={formData.estado_civil}
                        items={estadoCivilList}
                        name="estado_civil"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nascimento</Form.Label>
                      <Form.Control
                        value={formData.data_de_nascimento}
                        name="data_de_nascimento"
                        type="date"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome conjuge</Form.Label>
                      <Form.Control
                        value={formData.nome_do_conjuge}
                        name="nome_do_conjuge"
                        type="text"
                        onChange={handleChange}
                        disabled={conjugeDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data conjuge</Form.Label>
                      <Form.Control
                        value={formData.data_conjuge}
                        name="data_conjuge"
                        type="date"
                        onChange={handleChange}
                        disabled={conjugeDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>CPF conjuge</Form.Label>
                      <Form.Control
                        value={formData.cpf_conjuge}
                        name="cpf_conjuge"
                        type="text"
                        onChange={handleChange}
                        disabled={conjugeDisabled}

                      />
                    </Form.Group>
                  </Col>
                  </Row>
                  <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Possui filhos?</Form.Label>
                      <Form.Control
                        as="select"
                        value={filhoDisabled ? "true" : "false"}
                        name="possui_filhos"
                        onChange={(e) =>
                          setFilhoDisabled(e.target.value === "true")
                        }
                      >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  {filhoDisabled && (
                    <Col xs={1}>
                      <Form.Group className="mb-1">
                        <Form.Label>Filhos</Form.Label>
                        <Form.Control
                          value={formData.quantidade_de_filhos}
                          name="quantidade_de_filhos"
                          type="number"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    
                  )}
                </Row>
                {filhoDisabled && formData.quantidade_de_filhos > 0 && renderCamposDosFilhos()}

                <Row>
                  <Col xs={2}>
                    <Form.Group controlId="cep">
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.cep}
                        mask="99999-999"
                        maskChar="_"
                        name="cep"
                        type="text"
                        maxLength={9} // Ajustado o máximo para 9 para incluir o caractere "-"
                        onChange={handleCepChange}
                        required
                      />
                    </Form.Group>
                    {!cepValid && (
                      <div style={{ color: "red" }}>CEP inválido</div>
                    )}
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        value={formData.cidade}
                        name="cidade"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Logradouro</Form.Label>
                      <Form.Control
                        value={formData.endereco}
                        name="endereco"
                        type="text"
                        onChange={handleChange}
                        disabled={enderecoDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        value={formData.bairro}
                        name="bairro"
                        type="text"
                        onChange={handleChange}
                        disabled={bairroDisabled}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>U.F</Form.Label>
                      <Form.Control
                        value={formData.estado}
                        name="UF"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>País</Form.Label>
                      <Form.Control
                        value={formData.pais}
                        name="pais"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        value={formData.telefone}
                        name="telefone"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={formData.email}
                        name="email"
                        type="email"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <Form.Label>
                      {getBotaoLabel()}
                      <Button
                        className="btn-add-roles btn-success"
                        onClick={handleAdicionarFormacao}
                        style={{
                          padding: "7px",
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {getBotaoSymbol()}
                      </Button>
                    </Form.Label>
                  </Col>
                  {formacaoVisible && (
                    <Row>
                      <Col xs={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Formação</Form.Label>
                          <Form.Control
                            value={formData.formacao_1}
                            name="formacao_1"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={2}>
                        <Form.Group className="mb-1">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            value={formData.status_1}
                            name="status_1"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={2}>
                        <Form.Group className="mb-1">
                          <Form.Label>Nível</Form.Label>
                          <Form.Control
                            value={formData.nivel_1}
                            name="nivel_1"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <hr></hr>
                    <Row>
                      <Col  >
                      <Form.Group className="mb-1">
                    <Form.Label>
                    {getBotaoLabel2()}
                      <Button
                        className="btn-add-roles btn-success"
                        onClick={handleAdicionarFormacao2}
                        style={{
                          padding: "7px",
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {getBotaoSymbol2()}
                      </Button>
                    </Form.Label>
                    </Form.Group>
                  </Col>
                  </Row>
                    </Row>
                    
                  )}
                  {formacao2Visible && (
                    <Row>
                      <Col xs={3}>
                        <Form.Group className="mb-1">
                          <Form.Label>Formação</Form.Label>
                          <Form.Control
                            value={formData.formacao_2}
                            name="formacao_2"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={2}>
                        <Form.Group className="mb-1">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            value={formData.status_2}
                            name="status_2"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={2}>
                        <Form.Group className="mb-1">
                          <Form.Label>Nível</Form.Label>
                          <Form.Control
                            value={formData.nivel_2}
                            name="nivel_2"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Row>
                <hr></hr>
                <Row>
                  <Col xs={5}>
                    <Form.Group className="mb-1">
                      <Form.Label>Descrição formação</Form.Label>
                      <Form.Control
                        value={formData.descricao}
                        name="descricao"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={5}>
                    <Form.Group className="mb-1">
                      <Form.Label>Experiência anterior</Form.Label>
                      <Form.Control
                        value={formData.experiencia_anterior}
                        name="experiencia_anterior"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nível</Form.Label>
                      <Form.Control
                        value={formData.nivel_experiencia}
                        name="nivel_experiencia"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <Form.Label>Meses</Form.Label>
                      <Form.Control
                        value={formData.tempo_experiencia}
                        name="tempo_experiencia"
                        type="number"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Banco</Form.Label>
                      <Form.Control
                        value={formData.banco}
                        name="banco"
                        type="text"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="agencia">
                      <Form.Label>Agência</Form.Label>
                      <InputMask
                        mask="9999-9"
                        maskChar="_"
                        value={formData.agencia}
                        onChange={handleAgenciaChange}
                        required
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="agencia"
                            type="text"
                            placeholder="Digite a agência"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Tipo de conta"
                        setValue={handleContaChange}
                        value={formData.tipo_conta}
                        items={tipoContaList}
                        name="tipo_conta"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Op.</Form.Label>
                      <Form.Control
                        value={formData.op}
                        name="op"
                        type="text"
                        onChange={handleChange}
                        disabled={opDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="conta">
                      <Form.Label>Conta</Form.Label>
                      <InputMask
                        mask="99999-9"
                        maskChar="_"
                        value={formData.conta}
                        onChange={handleContaNumChange}
                        required
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="conta"
                            type="text"
                            placeholder="Digite a conta"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Alimentação</Form.Label>
                      <Form.Control
                        value={formData.alimentacao}
                        name="alimentacao"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Transporte</Form.Label>
                      <Form.Control
                        value={formData.transporte}
                        name="transporte"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Plano de saúde</Form.Label>
                      <Form.Control
                        value={formData.plano_saude}
                        name="plano_saude"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Cesta básica</Form.Label>
                      <Form.Control
                        value={formData.cesta}
                        name="cesta"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Ajuda de custo</Form.Label>
                      <Form.Control
                        value={formData.ajuda_custo}
                        name="ajuda_custo"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Benefícios</Form.Label>
                      <Form.Control
                        value={formData.beneficio_obs}
                        name="beneficio_obs"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {contractType === "determinado" && (
              <>
                {/* Campos para contrato por prazo determinado */}
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Matrícula</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.matricula}
                        name="matricula"
                        type="number"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        value={formData.nome}
                        name="nome"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        value={formData.status}
                        name="status"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Filial"
                        setValue={handleChangeFunction}
                        value={formData.filial}
                        items={filialList}
                        name="filial"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome da mãe</Form.Label>
                      <Form.Control
                        value={formData.nome_mae}
                        name="nome_mae"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome do pai</Form.Label>
                      <Form.Control
                        value={formData.nome_pai}
                        name="nome_pai"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col xs={2}>
                    <Form.Group controlId="rg">
                      <Form.Label>RG</Form.Label>
                      <InputMask
                        mask="99.999.999-9"
                        maskChar="_"
                        value={formData.rg}
                        onChange={handleRgChange}
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="rg"
                            type="text"
                            placeholder="Digite o RG"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Orgão emissor</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.orgao_exp}
                        name="orgao_exp"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.UF}
                        name="UF"
                        type="text"
                        maxLength={2} // Adicionando o atributo maxLength
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data de expedição</Form.Label>
                      <Form.Control
                        value={formData.data_exp}
                        name="data_exp"
                        type="date"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="cpf">
                      <Form.Label>CPF</Form.Label>
                      <InputMask
                        mask="999.999.999-99"
                        maskChar="_"
                        value={formData.cpf}
                        onChange={handleCpfChange}
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="cpf"
                            type="text"
                            placeholder="Digite o CPF"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                    {!cpfValid && (
                      <div style={{ color: "red" }}>CPF inválido</div>
                    )}
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="pis">
                      <Form.Label>PIS</Form.Label>
                      <InputMask
                        mask="999.99999.99-9"
                        maskChar="_"
                        value={formData.pis}
                        onChange={handlePisChange}
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="pis"
                            type="text"
                            placeholder="Digite o PIS"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Número da CTPS</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.ctps}
                        name="ctps"
                        type="text"
                        maxLength={7}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Série CTPS</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.serie_ctps}
                        name="serie_ctps"
                        type="text"
                        maxLength={4}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.uf_ctps}
                        name="uf_ctps"
                        type="text"
                        maxLength={2} // Adicionando o atributo maxLength
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data de expedição</Form.Label>
                      <Form.Control
                        value={formData.data_ctps}
                        name="data_ctps"
                        type="date"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      {/* <Form.Label>Função</Form.Label>
                                        <Form.Control value={formData.funcao} name='funcao' type="text" onChange={handleChange} /> */}
                      {/* <SelectFieldFetchNew label='função' name='funcao' setValue={handleChange} value={formData.funcao} path='/roles' field={'nome'} placeholder='Selecione uma função' /> */}
                      <SelectFieldFetchWithId
                        label="Função"
                        funcao={handleChange}
                        loadedVal={formData.funcao_id}
                        path="/positions"
                        external_value={"nome"}
                        fieldId={"funcao_id"}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Centro de custo</Form.Label>
                      <Form.Control
                        value={formData.centro_de_custo}
                        name="centro_de_custo"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Tipo de contrato</Form.Label>
                      <Form.Control
                        value={formData.tipo_de_contrato}
                        name="tipo_de_contrato"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Dias determinados</Form.Label>
                      <Form.Control
                        value={formData.dias_determinados}
                        name="dias_determinados"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Tipo de trabalho</Form.Label>
                      <Form.Control
                        value={formData.tipo_de_trabalho}
                        name="tipo_de_trabalho"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Gênero"
                        setValue={handleChange}
                        value={formData.sexo}
                        items={generoList}
                        name="sexo"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Salário</Form.Label>
                      <Form.Control
                        value={formData.salario}
                        name="salario"
                        type="text"
                        onChange={handleChangeValor}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nível função</Form.Label>
                      <Form.Control
                        value={formData.nivel_funcao}
                        name="nivel_funcao"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data</Form.Label>
                      <Form.Control
                        value={formData.data}
                        name="data"
                        type="date"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Categorização</Form.Label>
                      <Form.Control
                        value={formData.categorizacao}
                        name="categorizacao"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Estado civil"
                        setValue={handleCivilChange}
                        value={formData.estado_civil}
                        items={estadoCivilList}
                        name="estado_civil"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nascimento</Form.Label>
                      <Form.Control
                        value={formData.data_de_nascimento}
                        name="data_de_nascimento"
                        type="date"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Nome conjuge</Form.Label>
                      <Form.Control
                        value={formData.nome_do_conjuge}
                        name="nome_do_conjuge"
                        type="text"
                        onChange={handleChange}
                        disabled={conjugeDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Data conjuge</Form.Label>
                      <Form.Control
                        value={formData.data_conjuge}
                        name="data_conjuge"
                        type="date"
                        onChange={handleChange}
                        disabled={conjugeDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Possui filhos?</Form.Label>
                      <Form.Control
                        as="select"
                        value={filhoDisabled ? "true" : "false"}
                        name="possui_filhos"
                        onChange={(e) =>
                          setFilhoDisabled(e.target.value === "true")
                        }
                      >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  {filhoDisabled && (
                    <Col xs={1}>
                      <Form.Group className="mb-1">
                        <Form.Label>Filhos</Form.Label>
                        <Form.Control
                          value={formData.quantidade_de_filhos}
                          name="quantidade_de_filhos"
                          type="number"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group controlId="cep">
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        className="no-spinners"
                        value={formData.cep}
                        mask="99999-999"
                        maskChar="_"
                        name="cep"
                        type="text"
                        maxLength={9} // Ajustado o máximo para 9 para incluir o caractere "-"
                        onChange={handleCepChange}
                      />
                    </Form.Group>
                    {!cepValid && (
                      <div style={{ color: "red" }}>CEP inválido</div>
                    )}
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        value={formData.cidade}
                        name="cidade"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <Form.Label>Logradouro</Form.Label>
                      <Form.Control
                        value={formData.endereco}
                        name="endereco"
                        type="text"
                        onChange={handleChange}
                        disabled={enderecoDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        value={formData.bairro}
                        name="bairro"
                        type="text"
                        onChange={handleChange}
                        disabled={bairroDisabled}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>U.F</Form.Label>
                      <Form.Control
                        value={formData.estado}
                        name="UF"
                        type="text"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>País</Form.Label>
                      <Form.Control
                        value={formData.pais}
                        name="pais"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        value={formData.telefone}
                        name="telefone"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-1">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={formData.email}
                        name="email"
                        type="email"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Banco</Form.Label>
                      <Form.Control
                        value={formData.banco}
                        name="banco"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="agencia">
                      <Form.Label>Agência</Form.Label>
                      <InputMask
                        mask="9999-9"
                        maskChar="_"
                        value={formData.agencia}
                        onChange={handleAgenciaChange}
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="agencia"
                            type="text"
                            placeholder="Digite a agência"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixedNew
                        label="Tipo de conta"
                        setValue={handleContaChange}
                        value={formData.tipo_conta}
                        items={tipoContaList}
                        name="tipo_conta"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Op.</Form.Label>
                      <Form.Control
                        value={formData.op}
                        name="op"
                        type="text"
                        onChange={handleChange}
                        disabled={opDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group controlId="conta">
                      <Form.Label>Conta</Form.Label>
                      <InputMask
                        mask="99999-9"
                        maskChar="_"
                        value={formData.conta}
                        onChange={handleContaNumChange}
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            className="no-spinners"
                            name="conta"
                            type="text"
                            placeholder="Digite a conta"
                          />
                        )}
                      </InputMask>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Alimentação</Form.Label>
                      <Form.Control
                        value={formData.alimentacao}
                        name="alimentacao"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Transporte</Form.Label>
                      <Form.Control
                        value={formData.transporte}
                        name="transporte"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Plano de saúde</Form.Label>
                      <Form.Control
                        value={formData.plano_saude}
                        name="plano_saude"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Cesta básica</Form.Label>
                      <Form.Control
                        value={formData.cesta}
                        name="cesta"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Ajuda de custo</Form.Label>
                      <Form.Control
                        value={formData.ajuda_custo}
                        name="ajuda_custo"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className="mb-1">
                      <Form.Label>Benefícios</Form.Label>
                      <Form.Control
                        value={formData.beneficio_obs}
                        name="beneficio_obs"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Form.Group className="btn-controls">
              <Button
                className="btn-danger action-btn"
                onClick={handleCloseDialog}
              >
                Cancelar
              </Button>
              <Button className="btn-success action-btn" type="submit">
                Salvar
              </Button>
            </Form.Group>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddCollaborator;

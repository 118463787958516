import React, { useState } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeXml, BsFileEarmarkExcel, BsFiletypeXlsx } from "react-icons/bs";
import "./ProposalCard.css";
import ProposalModal from "../ProposalModal/ProposalModal";
import { Box, Rating } from "@mui/material";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import SatisfactionRating from "../saatisfactionRating/satisfactionRating";
import endpoints from "../../modules/endpoints";
import CSVReader from "react-csv-reader";
import ExcelViewer from "..//ExcelViewer/ExcelViewer";
import PDFViewer from "..//PDFViewer/PDFViewer";
import { saveAs } from "file-saver";

const ProposalCard = (props) => {
  const {
    numeroProposta,
    cliente_id,
    disciplina,
    revisao,
    tipo,
    data_solicitacao,
    data_limite_envio,
    hora_limite_envio,
    status,
    email,
    status_finalizacao,
    num_cot_os,
    servico,
    data_prev_inicio,
    data_prev_termino,
    cli_empresa_responsavel,
    cli_tecnico_responsavel,
    equipe_designada,
    obs,
    valor,
    retorno,
    num_cot_externo,
    cidade,
    estado,
    telefone,
    nomeEmpresa,
    roi,
    ncg,
    nomeComprador,
    total,
    ModalOpen,
    setModalOpen,
    onModalClose,
    showErrorAlert,
    setShowErrorAlert,
    setErrorMessage,
  } = props;
  const [authToken] = useState(localStorage.getItem("authToken"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const close = onModalClose;

  const openModal = () => {
    setSelectedFile(null);
    setFileType(null);
    setIsModalOpen(true);
    setModalOpen(true);
    setIsViewerOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalOpen(false);
    setIsViewerOpen(false);

    setSelectedFile(null);
    setFileType(null);
    close();
  };

  const formatCurencie = (value) => {
    if (value && value !== null && value !== undefined) {
      const rawValue = parseFloat(value).toFixed(2).toString();
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      return formattedValue;
    } else {
      return 0.0;
    }
  };

  const getLogoUrl = () => {
    if (nomeEmpresa === "LARGO RESOURCES") {
      return "https://mms.businesswire.com/media/20211108005402/en/924374/23/Largo-Logo-RGB.jpg";
    } else if (nomeEmpresa === "BAUMINAS") {
      return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjjhGrMr6AZ_tAuOl17CFqaEnXHIP7P1SbKr-_m9KuGw&s";
    } else if (nomeEmpresa === "CIBRA FERTIL") {
      return "https://media.licdn.com/dms/image/C4D0BAQEHufrec3NoLA/company-logo_200_200/0/1646042774144/cibra_logo?e=2147483647&v=beta&t=tTdBlUkcWFlKkFLD4s-oyS0r3Qq6lnUCkubc4PyQdHw";
    } else if (nomeEmpresa === "FERBASA") {
      return "https://pbs.twimg.com/profile_images/1136682062507851779/6ZuskXC6_400x400.png";
    } else if (nomeEmpresa === "INTERCEMENT BRASIL S.A") {
      return "https://eaemaq.com.br/wp-content/uploads/2023/11/Logo-intercement-brasil-r-1.jpg";
    } else if (nomeEmpresa === "3R PETROLEUM") {
      return "https://sindipetro.org.br/wp-content/uploads/2022/04/Nova-logo-3R.jpeg";
    } else if (nomeEmpresa === "MINASLIGAS S.A") {
      return "https://pang.net.br/biblioteca/tumbsmedios/482_logo_minas.jpg";
    } else if (nomeEmpresa === "VOTORANTIM CIMENTOS") {
      return "https://sindipetro.org.br/wp-content/uploads/2022/04/Nova-logo-3R.jpeg";
    } else if (nomeEmpresa === "ESEMA") {
      return "http://esema.com.br/img/logo.png";
    } else if (nomeEmpresa === "EQUINOX GOLD") {
      return "https://eaemaq.com.br/wp-content/uploads/2023/10/equinox-gold-r.jpg";
    } else if (nomeEmpresa === "PETRORECONCAVO") {
      return "https://petrodigital.petroreconcavo.com.br/static/media/logo.f1ba9247.png";
    } else if (nomeEmpresa === "MOSAIC FERTILIZANTES") {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Mosaic_Logo.svg/1200px-Mosaic_Logo.svg.png";
    } else if (nomeEmpresa === "ATLANTIC NICKEL") {
      return "https://atlanticnickel.com/wp-content/uploads/2019/05/Logo_AtlanticNickel_2019-1024x235-transp.png";
    } else {
      return "https://grupovolpe.ind.br/wp-content/uploads/2022/11/Logo-GRUPO-VOLPE-PNG.png";
    }
  };

  const FileViewer = ({ fileContent, fileType, fileName, isViewerOpen }) => {
    switch (fileType) {
      case "pdf":
        return (
          <div className="file-viewer">
            <div className="viewer-content">
              <PDFViewer
                fileContent={fileContent}
                fileName={fileName}
                isViewerOpen={isViewerOpen}
                setIsViewerOpen={setIsViewerOpen}
              />
            </div>
          </div>
        );
      case "text/csv":
        return (
          <div className="file-viewer">
            <div className="viewer-content">
              <CSVReader csv={fileContent} />
            </div>
          </div>
        );
      case "xlsx":
        return (
          <div className="file-viewer">
            <div className="viewer-content">
              <ExcelViewer
                fileContent={fileContent}
                fileName={fileName}
                isViewerOpen={isViewerOpen}
                setIsViewerOpen={setIsViewerOpen}
              />
            </div>
          </div>
        );
      default:
        return <div>Tipo de arquivo não suportado.</div>;
    }
  };

  const showFile = async (type) => {
    console.log("Showing file...");

    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const response = await fetch(
        `${endpoints.showLatestFile()}/${numeroProposta}/${type}`,
        {
          headers,
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const contentType = response.headers.get("Content-Type");
      const name = `${numeroProposta}/${type}`;

      console.log(name);
      const blob = await response.blob();

      if (type === "pdf") {
        setFileType(type);
        setFileName(name);
        setSelectedFile({
          content: blob,
          type: contentType,
          filename: name,
        });
        setIsViewerOpen(true);
      } else if (type === "xlsx") {
        const filename = name;
        saveAs(blob, `${filename}.xlsx`);
      } else {
      }
    } catch (error) {
      console.error("Erro ao buscar arquivo:", error);
      setErrorMessage("Não existe arquivo relacionado à proposta.");
      setShowErrorAlert(true);
    }
  };

  const calculateGradientColor = (value) => {
    const normalizedValue = value / 5;
    const red = Math.floor(255 * (1 - normalizedValue));
    const green = Math.floor(255 * normalizedValue);
    return `rgba(${red},${green},0,1)`;
  };

  return (
    <>
      <div className="proposal-card">
        <Row>
          <div className="card-logo-container">
            <img className="card-logo" src={getLogoUrl()}></img>
          </div>
        </Row>
        <Row>
          <div className="card-header">
            <p className="num-proposal" title={servico}>
              {servico.substring(0, 30)}
              {servico.length > 6 ? "..." : ""}
            </p>
          </div>
        </Row>

        <div className="card-content" onClick={openModal}>
          <Form.Group controlId="form">
            <Row className="card-row-space">
              <Col xs={12}>
                <b>Cliente: </b>

                {nomeEmpresa}
              </Col>
            </Row>
            <Row className="card-row-space">
              <Col xs={6}>
                <b>ROI: </b> {`R$${formatCurencie(roi)}`}
              </Col>
              <Col xs={6}>
                <b>NCG: </b> {`R$${formatCurencie(ncg)}`}
              </Col>
            </Row>
            <Row className="card-row-space">
              <Col xs={12} title={nomeComprador}>
                <b>Comprador: </b>
                {nomeComprador.substring(0, 15)}
                {nomeComprador.length > 15 ? "..." : ""}
              </Col>
            </Row>
            <Row className="card-row-space">
              <Col xs={12}>
                <b>Responsável: </b>

                {cli_tecnico_responsavel}
                <p></p>
              </Col>
            </Row>
          </Form.Group>
        </div>
        <div className="card-actions">
          <Form.Group>
            <Row>
              <Col xs={2}>
                <div className="button-actions">
                  <div>
                    <button
                      className="button"
                      onClick={() => {
                        showFile("pdf");
                      }}
                    >
                      <FaRegFilePdf size={26} />
                    </button>
                  </div>
                </div>
              </Col>
              <Col xs={8}>
                <Box sx={{ alignItems: "center" }}>
                  <Rating
                    name="text-feedback"
                    value={total}
                    readOnly
                    precision={0.5}
                    getLabelText={(total) => `${total}`}
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: (theme) => calculateGradientColor(total),
                      },
                      marginRight: 1,
                    }}
                  />
                  <Box sx={{ fontSize: 11, fontWeight: 500 }}>
                    Média: {total.toFixed(2)}
                  </Box>
                </Box>
              </Col>
              <Col xs={2}>
                <div className="button-actions">
                  <div>
                    <button className="button" onClick={() => showFile("xlsx")}>
                      <BsFiletypeXlsx size={26} />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form.Group>
        </div>
        <ProposalModal
          showModal={isModalOpen}
          numeroProposta={numeroProposta}
          nomeEmpresa={nomeEmpresa}
          cliente_id={cliente_id}
          disciplina={disciplina}
          revisao={revisao}
          tipo={tipo}
          data_solicitacao={data_solicitacao}
          data_limite_envio={data_limite_envio}
          hora_limite_envio={hora_limite_envio}
          status={status}
          email={email}
          status_finalizacao={status_finalizacao}
          num_cot_os={num_cot_os}
          servico={servico}
          data_prev_inicio={data_prev_inicio}
          data_prev_termino={data_prev_termino}
          cli_empresa_responsavel={cli_empresa_responsavel}
          Cli_tecnico_responsavel={cli_tecnico_responsavel}
          equipe_designada={equipe_designada}
          obs={obs}
          valor={valor}
          retorno={retorno}
          num_cot_externo={num_cot_externo}
          cidade={cidade}
          estado={estado}
          telefone={telefone}
          roi={roi}
          ncg={ncg}
          nomeComprador={nomeComprador}
          closeModal={closeModal}
        />
      </div>
      {selectedFile && (
        <FileViewer
          fileContent={selectedFile.content}
          fileType={fileType}
          fileName={fileName}
          isViewerOpen={isViewerOpen}
          onViewerClose={props.onModalClose}
        />
      )}
    </>
  );
};

export default ProposalCard;

import Form from 'react-bootstrap/Form';

function SelectFieldFixed({ funcao, loadedVal, items, label, placeholder }) {
    return (
        <>
            <Form.Label>{label}</Form.Label>
            <Form.Select value={loadedVal}
                onChange={(event) => funcao(event.target.value)}
            >
                {items && ( placeholder !== undefined ?<option value="">{placeholder}</option> : <option value="">Selecione o {label}</option>)}

                {items ? items.map((option) => (
                    <option key={option['id']} value={option['value']}>
                        {option['label']}
                    </option>
                )) : <option value=''>{loadedVal}</option>}
            </Form.Select>
        </>
    )
}

export default SelectFieldFixed
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import SelectFieldFixedNew from '../selectFieldFetchNew'; // Importe todos os componentes necessários
import SelectFieldFetchWithId from '../selectFieldFetchWithId';
import SelectFieldFixed from '../selectFieldFixed';


function EditCollaboratorModal({ show, onHide, isEditing, handleSubmit, handleChange, handleChangeValor, handleChangeFunction, handleChangeOthers, formData, StatusList, vendedorStatus, generoList }) {
  
   
   

    return (
    <Modal show={show} onHide={onHide} centered size='xl'>
      <Form onSubmit={handleSubmit} className='form-page'>
        <Modal.Header closeButton>
          {!isEditing ? <Modal.Title>Novo Colaborador</Modal.Title> : <Modal.Title>Editar Colaborador</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
        <Row>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Matrícula</Form.Label>
                      <Form.Control className='no-spinners' value={formData.matricula} name='matricula' type='number' onChange={handleChange} disabled/>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control value={formData.nome} name='nome' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className="mb-1">
                      <SelectFieldFixed
                        label="Status"
                        name="status"
                        funcao={handleChangeFunction}
                        value={formData.status}
                        items={StatusList}
                        loadedVal={formData.status}
                        
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Filial</Form.Label>
                      <Form.Control value={formData.filial} name='filial' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <SelectFieldFixedNew label='Vendedor' setValue={handleChange} value={formData.vendedor === 0 ? "N" : formData.vendedor === 1 ? "S" : formData.vendedor} items={vendedorStatus} name='vendedor' />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      {/* <Form.Label>Função</Form.Label>
                                        <Form.Control value={formData.funcao} name='funcao' type="text" onChange={handleChange} /> */}
                      {/* <SelectFieldFetchNew label='função' name='funcao' setValue={handleChange} value={formData.funcao} path='/roles' field={'nome'} placeholder='Selecione uma função' /> */}
                      <SelectFieldFetchWithId label='Função' funcao={handleChange} loadedVal={formData.funcao_id} path='/positions' external_value={'nome'} fieldId={'funcao_id'} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Centro de custo</Form.Label>
                      <Form.Control value={formData.centro_de_custo} name='centro_de_custo' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Tipo de contrato</Form.Label>
                      <Form.Control value={formData.tipo_de_contrato} name='tipo_de_contrato' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Tipo de trabalho</Form.Label>
                      <Form.Control value={formData.tipo_de_trabalho} name='tipo_de_trabalho' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <SelectFieldFixedNew label='Gênero' setValue={handleChange} value={formData.sexo} items={generoList} name='sexo' />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Salário</Form.Label>
                      <Form.Control value={formData.salario} name='salario' type='text' onChange={handleChangeValor} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nível função</Form.Label>
                      <Form.Control value={formData.nivel_funcao} name='nivel_funcao' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Data</Form.Label>
                      <Form.Control value={formData.data} name='data' type='date' onChange={handleChange} required />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Categorização</Form.Label>
                      <Form.Control value={formData.categorizacao} name='categorizacao' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Estado cívil</Form.Label>
                      <Form.Control value={formData.estado_civil} name='estado_civil' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nascimento</Form.Label>
                      <Form.Control value={formData.data_de_nascimento} name='data_de_nascimento' type='date' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={5}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nome conjuge</Form.Label>
                      <Form.Control value={formData.nome_do_conjuge} name='nome_do_conjuge' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Data conjuge</Form.Label>
                      <Form.Control value={formData.data_conjuge} name='data_conjuge' type='date' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Filhos</Form.Label>
                      <Form.Control value={formData.quantidade_de_filhos} name='quantidade_de_filhos' type='number' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control value={formData.endereco} name='endereco' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control value={formData.telefone} name='telefone' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control value={formData.email} name='email' type='email' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Formação</Form.Label>
                      <Form.Control value={formData.formacao_1} name='formacao_1' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Status</Form.Label>
                      <Form.Control value={formData.status_1} name='status_1' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nível</Form.Label>
                      <Form.Control value={formData.nivel_1} name='nivel_1' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Formação</Form.Label>
                      <Form.Control value={formData.formacao_2} name='formacao_2' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Status</Form.Label>
                      <Form.Control value={formData.status_2} name='status_2' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nível</Form.Label>
                      <Form.Control value={formData.nivel_2} name='nivel_2' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={5}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Descrição formação</Form.Label>
                      <Form.Control value={formData.descricao} name='descricao' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={5}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Experiência anterior</Form.Label>
                      <Form.Control value={formData.experiencia_anterior} name='experiencia_anterior' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nível</Form.Label>
                      <Form.Control value={formData.nivel_experiencia} name='nivel_experiencia' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Meses</Form.Label>
                      <Form.Control value={formData.tempo_experiencia} name='tempo_experiencia' type='number' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <hr></hr> */}
                {/* <Row>
                  <Col xs={12}>
                    {' '}
                    <Form.Label>Filhos</Form.Label>
                  </Col>
                </Row> */}

                {/* <Row>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <SelectFieldFixedNew label='Gênero' setValue={handleChange} name='genero_filho_1' value={formData.genero_filho_1} items={generoList} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control value={formData.nome_filho_01} name='nome_filho_01' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Data</Form.Label>
                      <Form.Control value={formData.data_nascimento_01} name='data_nascimento_01' type='date' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <SelectFieldFixedNew label='Gênero' setValue={handleChange} value={formData.genero_filho_2} name='genero_filho_2' items={generoList} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control value={formData.nome_do_filho_02} name='nome_do_filho_02' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Data</Form.Label>
                      <Form.Control value={formData.data_nascimento_02} name='data_nascimento_02' type='date' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <SelectFieldFixedNew label='Gênero' setValue={handleChange} value={formData.genero_filho_03} name='genero_filho_03' items={generoList} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control value={formData.nome_do_filho_03} name='nome_do_filho_03' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Data</Form.Label>
                      <Form.Control value={formData.data_nascimento_03} name='data_nascimento_03' type='date' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={1}>
                    <Form.Group className='mb-1'>
                      <SelectFieldFixedNew label='Gênero' setValue={handleChange} value={formData.genero_de_filho_04} name='genero_de_filho_04' items={generoList} />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control value={formData.nome_do_filho_04} name='nome_do_filho_04' type='text' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group className='mb-1'>
                      <Form.Label>Data</Form.Label>
                      <Form.Control value={formData.data_nascimento_04} name='data_nascimento_04' type='date' onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </Row> */}
        </Modal.Body>
        <Modal.Footer>
                <Form.Group className='btn-controls'>
                  <Button className='btn-danger action-btn' onClick={onHide}>
                    Cancelar
                  </Button>
                  <Button className='btn-success action-btn' type='submit'>
                    {' '}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>

      </Form>
    </Modal>
  );
}

export default EditCollaboratorModal;

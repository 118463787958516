import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import UserMenuCard from '../UserMenuCard/UserMenuCard';
import './UserMenu.css';

const UserMenu = ({ menuOptions }) => {
  console.log(menuOptions)
  return (
    <>
   
   <div className='menu-container'>
    
    <Row>
      {menuOptions.map((option, index) => (
        <UserMenuCard
          key={index}
          iconName={option.iconName}
          name={option.name}
          path={option.path}
          onClick={option.onClick}
          disabledMenu={option.disabledMenu}
        />
      ))}
    </Row>
    </div>
    </>
  );
};

export default UserMenu;

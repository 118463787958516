import React, { useEffect, useState } from 'react';

import './ModalChangePassword.css';
import { Row, Col, Form, Button, Card, Modal, Alert } from 'react-bootstrap';
import { useRequest } from '../../hooks/useRequest';
import endpoints from '../../modules/endpoints';
import { useNavigate } from 'react-router-dom';


function ModalChangePassword({show, handleClose}) {
  const { sendRequest, response } = useRequest();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    
    try {
      sendRequest(endpoints.changePassword(), 'POST', { old_password: oldPassword, password: newPassword, password_confirmation: newPasswordConfirmation });
    } catch (error) {
      console.log(error);
    }
  

  };

  useEffect(() => {
    if (response) {
      if (response.status === 'error') {
        setWarning(null);
        setError(response.message);
      } else {
        console.log(response);
        setWarning(response.message);
        setError(null);
        setNewPassword('');
        setOldPassword('');
        setNewPasswordConfirmation('');
        
           // Mostrar o alerta antes de deslogar
        setShowAlert(true);

        // Deslogar e recarregar a página após um tempo
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000); // Exibe o alerta por 3 segundos antes de deslogar
        
      }
    }
  }, [response]);

  return (
    <>
      
      <Modal show={show} onHide={handleClose}>
      
        {
          
            <Row className=''>
              <Col xs={12}>
                <Card bg='dark changepass-card' text='light'>
                  <Card.Body className='changepass-page-form'>
                    <Form onSubmit={handleLogin}>
                      <Form.Group controlId='formBasicPassOld'>
                        <Form.Control
                          required
                          value={oldPassword}
                          type='password'
                          placeholder='Senha antiga'
                          className='changepass-input'
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId='formBasicNewPassword'>
                        <Form.Control
                          required
                          value={newPassword}
                          type='password'
                          placeholder='Senha nova'
                          className='changepass-input'
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId='formBasicNewPasswordConfirmation'>
                        <Form.Control
                          required
                          value={newPasswordConfirmation}
                          type='password'
                          placeholder='Confirmar senha'
                          className='changepass-input'
                          onChange={(e) => {
                            setNewPasswordConfirmation(e.target.value);
                          }}
                        />
                      </Form.Group>
                      {
                        <div className='error-message'>
                          <span>{error && error}</span>
                        </div>
                      }
                      {
                        <div className='warning-message'>
                          <span>{warning && warning}</span>
                        </div>
                      }
                      
                      <Button type='submit' className='btn-submit-login w-100'>
                        TROCAR SENHA
                      </Button>
                      
                      <Button onClick={handleClose} className='btn-submit-login w-100' style={{marginTop:'5px'}}>
                        CANCELAR
                      </Button>
                      <Alert show={showAlert} variant='success'>
        Senha alterada com sucesso! Retornando à página de login.
      </Alert>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            
          
        }
     
      </Modal>
      
    </>
  );
}

export default ModalChangePassword;

import { useState, useEffect } from 'react';
import endpoints from '../modules/endpoints';
import { useNavigate } from 'react-router-dom';

export const useRequestUserInfos = () => {
  const navigate = useNavigate();
  const [userInfos, setUserInfos] = useState(null);
  const [token, setToken] = useState(null);

  const getUserInfos = (token) => {
    setToken(token);
  };

  useEffect(() => {
    const httpRequest = async () => {
      const res = await fetch(endpoints.userRoles(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 401) {
        localStorage.clear();
        navigate('/');
      } else {
        const json = await res.json();
        setUserInfos(json);
      }
    };
    if (token !== null) {
      httpRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { getUserInfos, userInfos };
};

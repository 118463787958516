import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRequest = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState(null);
  const [data, setData] = useState(null);
  const [url, setUrl] = useState(null);
  const [method, setMethod] = useState(null);
  const [authToken] = useState(localStorage.getItem('authToken'));

  const sendRequest = (url, method, data) => {
    setUrl(url);
    setMethod(method);
    setData(data);
  };

  useEffect(() => {
    const httpRequest = async () => {
      if (method === 'POST') {
        const res = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        if (res.status === 401 && url.indexOf('/session') === -1) {
          localStorage.clear();
          navigate('/');
        } else {
          const json = await res.json();
          setResponse(json);
        }
      } else if (method === 'PUT') {
        const res = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        if (res.status === 401) {
          localStorage.clear();
          navigate('/');
        } else {
          const json = await res.json();
          setResponse(json);
        }
      } else if (method === 'DELETE') {
        const res = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        if (res.status === 401) {
          localStorage.clear();
          navigate('/');
        } else {
          const json = await res.json();
          setResponse(json);
        }
      } else if (method === 'GET') {
        const fullUrl = data ? `${url}/${data}` : `${url}`;
        const res = await fetch(fullUrl, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (res.status === 401) {
          localStorage.clear();
          navigate('/');
        } else {
          const json = await res.json();
          setResponse(json);
        }
      }
    };
    if (data !== null) {
      httpRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { sendRequest, response };
};

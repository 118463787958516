import "./App.css";
import "react-pdf/dist/esm/Page/AnnotationLayer";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import Logout from "./pages/Logout";

import NotFound from "./pages/NotFound";

// ADM
import Roles from "./pages/Funcoes";
import Clientes from "./pages/Clientes";
import Medicoes from "./pages/Medicoes";
import Prospeccao from "./pages/prospeccao";
import EmployeeResearch from "./pages/researches/EmployeeResearch";
import Colaboradores from "./pages/Colaboradores";
import Reports from "./pages/Reports";
import Grupos from "./pages/Grupos";
import ChangePassword from "./pages/ChangePassword";
import PesquisasColaboradores from "./pages/PesquisasColaboradores";
import SentResearchesEmployees from "./pages/SentResearchesEmployees";
import NPSResearch from "./pages/researches/NPSResearch";
import RecruitmentForm from "./pages/forms/Recrutamento";
import Recruitment from "./pages/Recruitment";
// import CallbackPage from "./pages/Callback";
import ServiceResearch from "./pages/researches/ServicesResearch";
import PowerBIDashboardSales from "./components/PowerBIDashboard";
import Propostas from "./pages/propostas";
import ProposalsRanking from "./pages/ProposalsRanking";
import PowerBIDashboardRH from "./components/PowerBIDashboardRH";
import PowerBIDashboardMeasurements from "./components/PowerBIDashboardMeasurements";
import PowerBIDashboardSalesFunnel from "./components/PowerBIDashboardSalesFunnel";
import PowerBIDashboardMarketClients from "./components/PowerBIDashboardMarketClients";
import UserPanel from "./pages/UserPanel";
import ModalChangePassword from "./components/ModalChangePassword/ModalChangePassword";


const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

const AppRoutes = () => {
  return (
    <div className="page">
      <Routes>
        <Route path="/" element={<LoginPage />} />
       
        
        <Route path="/adm/medicoes" element={<Medicoes />} />
        <Route path="/adm/grupos" element={<Grupos />} />

        <Route path="/pessoas/colaboradores" element={<Colaboradores />} />
        <Route path="/pessoas/pesquisas" element={<PesquisasColaboradores />} />
        <Route path="/pessoas/pesquisaInterna" element={<SentResearchesEmployees />} />
        <Route path="/pessoas/formulario" element={<RecruitmentForm />} />
        <Route path="/pessoas/recrutamentos" element={<Recruitment />} />
        <Route path="/pessoas/funcoes" element={<Roles />} />

        <Route path="/clientes/prospeccoes" element={<Prospeccao />} />
        <Route path="/clientes/prospeccoes/:rid" element={<Prospeccao />} />
        <Route path="/clientes/registros" element={<Clientes />} />
        <Route path="/clientes/propostas" element={<Propostas />} />
        <Route path="/clientes/proposalsranking" element={<ProposalsRanking />} />
        <Route path="/clientes/reports" element={<Reports />} />
        <Route path="/clientes/propostas/:rid" element={<Propostas />} />
        <Route path="/clientes/pesquisaNPS/:campanha/:rid" element={<NPSResearch />} />
        <Route path="/clientes/pesquisaServico/:venda/:colaborador" element={<ServiceResearch />} />
        <Route path="/clientes/salesbi" element={<PowerBIDashboardSales />} />
        <Route path="/clientes/rhbi" element={<PowerBIDashboardRH />} />
        <Route path="/clientes/controle-pagamento" element={<PowerBIDashboardMeasurements/>}/>
        <Route path="/clientes/funil-vendas" element={<PowerBIDashboardSalesFunnel/>}/>
        <Route path="/clientes/mercado-clientes" element={<PowerBIDashboardMarketClients/>}/>
        <Route path="/pesquisas/:campanha/:rid" element={<EmployeeResearch />} />


        
        <Route path="/userPanel" element={<UserPanel />} />
        <Route path="/trocarSenha" element={<ChangePassword />} />
        <Route path="/logout" element={<Logout />} />

        {/* <Route path="/callback" component={<CallbackPage />} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;

import { useState, useEffect, useRef, useMemo } from "react";
import { Alert, Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";
import endpoints from "../../modules/endpoints";
import "./ModalEditUser.css";
import { useFetchPerformatic } from "../../hooks/useFetchPerformatic";
import SelectFieldFetchWithId from "../../components/selectFieldFetchWithId";
import { useNavigate } from "react-router-dom";

function ModalEditUser({
  show,
  handleClose,
  setSuccess,
  handleShowToast,
  success,
  setToastBg,
  setToastMessage,
  handleCancel,
  setShowDeleteUserModal,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.addNewUser(), "GET");

  const navigate = useNavigate();
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [authToken] = useState(localStorage.getItem("authToken"));
  const [userType, setUserType] = useState("");
  const [selectedUserRoles, setSelectedUserRoles] = useState("");
  const [newUserRoles, setNewUserRoles] = useState([]);

  
  


  const resetFormData = () => {
    setFormData(initialFormData);
    setUserType("");
    setSelectedCollaborator(null);
    setCheckAllStates({});
    setIsAdmin(false);
  };

   const handleCheckboxChange = (value) => {
    // Verifica se o valor já está no array
    if (selectedUserRoles.includes(value)) {
      // Se estiver, remove
      setSelectedUserRoles((prevUserType) =>
        prevUserType.filter((type) => type !== value)
      );
    } else {
      // Se não estiver, adiciona
      setSelectedUserRoles((prevUserType) => [...prevUserType, value]);
    }
  };
  
  

  const handleChangeCol = (event) => {
    // Lógica para lidar com a mudança de colaborador, se necessário
    const selectedId = event.target.value;
    const selected = items.find((item) => item.id === selectedId);
    setSelectedCollaborator(selected);
    // ... Outras lógicas que você possa precisar ...
  };

  const [checkAllStates, setCheckAllStates] = useState({});
  const [isAdmin, setIsAdmin] = useState(false); // Novo estado para controlar se é administrador

  const handleChangeUserType = (e) => {
    const selectedValue = e.target.value;

    // Atualiza o estado isAdmin com base na escolha do usuário
    setIsAdmin(selectedValue === "admin");
    setUserType(selectedValue);
  };

  

  const handleCheckAll = (column) => {
    // Lógica para "Marcar Todos"
    const allItems = getAllItemsForColumn(column);

    if (checkAllStates[column]) {
      setUserType((prevUserType) =>
        prevUserType.filter((type) => !allItems.includes(type))
      );
    } else {
      setUserType((prevUserType) => [...prevUserType, ...allItems]);
    }

    // Alternar o estado do checkbox "Marcar Todos"
    setCheckAllStates((prevStates) => ({
      ...prevStates,
      [column]: !prevStates[column],
    }));
  };

  const checkAllColumn = (column) => {
    return checkAllStates[column] || false;
  };

  // Função para obter todos os itens da coluna
  const getAllItemsForColumn = (column) => {
    const itemsForColumn = {
      clientes: ["clientes", "propostas"],
      pessoas: ["banco-cv", "colaboradores", "funcoes"],
      administracao: ["medicoes", "paineladministrativo"], // Adicione os itens corretos para cada coluna
      // Adicione mais colunas conforme necessário
    };

    return itemsForColumn[column] || [];
  };

  const columnsArquivos = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Nome",
        size: 100,
        minSize: 100,
      },
    ],
    []
  );

  const handleEdit = async () => {
    // Verifique se o colaborador foi selecionado
    if (!selectedCollaborator || selectedUserRoles.length === 0) {
      // Adicione lógica de tratamento aqui (por exemplo, exibir uma mensagem de erro)
      return;
    }
  
    // Crie o corpo da requisição
    const requestBody = {
      user_id: selectedCollaborator.id,
      roles: selectedUserRoles,
    };
  
    try {
      // Envie a requisição para a rota de edição do colaborador
      const response = await fetch(endpoints.updateUserRoles(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`,
          // Adicione outros headers necessários aqui
        },
        body: JSON.stringify(requestBody),
      });
  
      // Verifique o status da resposta
      if (response.ok) {
        setSuccess(true);
      } else {
        setSuccess(null);
        handleCancel(true);
        console.error("Erro ao editar usuário:", response.statusText);
      }
    } catch (error) {
      setSuccess(false);
      console.error("Erro de rede ao editar usuário:", error.message);
    } finally {
      // Adicione essa chamada para exibir o Toast com base no valor de success
      handleClose();
    }
  };

  const initialFormData = {
    id: "",
    matricula: "",
    nome: "",
    status: "",
    filial: "",
    centro_de_custo: "",
    funcao_id: "",
    funcao_nome: "",
    tipo_de_contrato: "",
    tipo_de_trabalho: "",
    sexo: "",
    salario: "",
    nivel_funcao: "",
    data: null,
    categorizacao: "",
    data_de_nascimento: null,
    estado_civil: "",
    nome_do_conjuge: "",
    data_conjuge: null,
    quantidade_de_filhos: 0,
    // genero_filho_1: '',
    // nome_filho_01: '',
    // data_nascimento_01: '',
    // genero_filho_2: '',
    // nome_do_filho_02: '',
    // data_nascimento_02: '',
    // genero_filho_03: '',
    // nome_do_filho_03: '',
    // data_nascimento_03: '',
    // genero_de_filho_04: '',
    // nome_do_filho_04: '',
    // data_nascimento_04: '',
    // genero_do_filho_05: '',
    // nome_do_filho_05: '',
    // data_nascimento_05: '',
    formacao_1: "",
    status_1: "",
    nivel_1: "",
    formacao_2: "",
    status_2: "",
    nivel_2: "",
    descricao: "",
    experiencia_anterior: "",
    nivel_experiencia: "",
    tempo_experiencia: "",
    endereco: "",
    telefone: "",
    email: "",
    vendedor: 0,
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    // Atualiza o formulário quando selectedColaborator é alterado
    if (selectedCollaborator) {
      setFormData(selectedCollaborator);
    }
  }, [selectedCollaborator]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Atualiza o formulário quando selectedColaborator é alterado
    if (selectedCollaborator) {
      const formattedRoles = selectedCollaborator.roles
        .replace(/[\[\]"]/g, '') // Remove colchetes e aspas simples
        .split(',')
        .map((role) => role.trim())
        .filter((role) => role !== ''); // Remove strings vazias
  
      setSelectedUserRoles(formattedRoles);
  
      // Verifica se o usuário é um administrador
      const isAdminUser = formattedRoles.includes('admin');
      setIsAdmin(isAdminUser);
      console.log(isAdmin)
  
      // Se não for admin, marca como colaborador
      //   if (!isAdminUser) {
      //     setUserType(["colaborador"]);
      //   }
    }
  }, [selectedCollaborator]);

  useEffect(()=>{
    setNewUserRoles(selectedUserRoles);
    if (isAdmin){
        setSelectedUserRoles("admin");
    }else {
        setSelectedUserRoles(newUserRoles)
    }
  }, [isAdmin])
  
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className="header_modules">
          <h2 className="product-title">CONFIGURAR PERMISSÕES DE USUÁRIO</h2>
        </div>

        <div className="form-rows">
          <Form>
            <Form.Group className="mb-3">
              <SelectFieldFetchWithId
                label="Colaborador"
                funcao={handleChangeCol}
                loadedVal={selectedCollaborator ? selectedCollaborator.id : ""}
                path="/users/list"
                external_value="name"
                fieldId="name"
                required={true}
                placeholder={"Selecione o Usuário"}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tipo do usuário:</Form.Label>
              <Row>
                <Col xs={6}>
                  <Form.Check
                    type="radio"
                    label="Administrador"
                    name="userType"
                    value="admin"
                    checked={isAdmin} // Se for admin, marca
                    onChange={handleChangeUserType}
                  />
                </Col>
                <Col xs={6}>
                  <Form.Check
                    type="radio"
                    label="Colaborador"
                    name="userType"
                    value=""
                    checked={!isAdmin} // Se não for admin, marca
                    onChange={handleChangeUserType}
                  />
                </Col>
              </Row>
            </Form.Group>
            {isAdmin ? null : (
              <Form.Group className="mb-3">
                {/* Adicione o campo para o tipo de usuário */}
                <Form.Label>Acessos do Usuário</Form.Label>
                <Row>
                  <Col xs={4}>
                    <div className="acessos-column">
                      CLIENTES
                      <Form.Check
                        type="switch"
                        checked={checkAllColumn("clientes")}
                        onChange={() => handleCheckAll("clientes")}
                      />
                    </div>

                    <Form.Check
                      type="checkbox"
                      label="Clientes"
                      checked={selectedUserRoles.includes("clientes")}
                      onChange={() => handleCheckboxChange("clientes")}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Propostas"
                      checked={selectedUserRoles.includes("propostas")}
                      onChange={() => handleCheckboxChange("propostas")}
                    />
                  </Col>
                  {/* Adicione mais checkboxes conforme necessário */}

                  <Col xs={4}>
                    <div className="acessos-column">
                      PESSOAS
                      <Form.Check
                        type="switch"
                        checked={checkAllColumn("pessoas")}
                        onChange={() => handleCheckAll("pessoas")}
                      />
                    </div>

                    <Form.Check
                      type="checkbox"
                      label="Banco de CV's"
                      checked={selectedUserRoles.includes("banco-cv")}
                      onChange={() => handleCheckboxChange("banco-cv")}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Colaboradores"
                      checked={selectedUserRoles.includes("colaboradores")}
                      onChange={() => handleCheckboxChange("colaboradores")}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Funcões"
                      checked={selectedUserRoles.includes("funcoes")}
                      onChange={() => handleCheckboxChange("funcoes")}
                    />
                  </Col>

                  <Col xs={4}>
                    <div className="acessos-column">
                      ADMIN.
                      <Form.Check
                        type="switch"
                        checked={checkAllColumn("administracao")}
                        onChange={() => handleCheckAll("administracao")}
                      />
                    </div>

                    <Form.Check
                      type="checkbox"
                      label="Medições"
                      checked={selectedUserRoles.includes("medicoes")}
                      onChange={() => handleCheckboxChange("medicoes")}
                    />

                    <Form.Check
                      type="checkbox"
                      label="Painel adm."
                      checked={selectedUserRoles.includes(
                        "paineladministrativo"
                      )}
                      onChange={() =>
                        handleCheckboxChange("paineladministrativo")
                      }
                    />
                  </Col>
                  {/* Adicione mais checkboxes conforme necessário */}
                </Row>
              </Form.Group>
            )}
            <div>Permissões :</div>
            <Form.Control
              type="text"
              placeholder="N/A"
              disabled
              value={selectedUserRoles} // Use join para exibir as permissões como uma string
              onChange={(e) => setSelectedUserRoles(e.target.value)}
            />

            <div className="form-container-buttons">
              <Button
                variant="secondary"
                onClick={() => {
                  setShowDeleteUserModal(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                disabled={!selectedCollaborator || selectedUserRoles === selectedCollaborator.roles}
                onClick={() => {
                  handleEdit();
                  resetFormData();
                }}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}
export default ModalEditUser;

import { useState, useEffect, useRef, useMemo } from "react";

import { Alert, Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";

import endpoints from "../../modules/endpoints";

import Table from "../../components/Table";
import SelectFieldFixedNew from "../../components/selectFieldFixedNew";
import "./ModalAddNewUser.css"
import { useFetchPerformatic } from "../../hooks/useFetchPerformatic";
import SelectFieldFetchWithId from "../../components/selectFieldFetchWithId";
import { useNavigate } from "react-router-dom";
import SelectFieldFetchNew from "../selectFieldFetchNew";

function ModalAddNewUser({ show, handleClose, setSuccess, handleShowToast,setShowNewUserModal, success, setToastBg, setToastMessage, handleCancel }) {
  const [userType, setUserType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    data: items,
    httpConfig,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.colaboradores());
  const navigate = useNavigate();
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);

  const handleCheckboxChange = (value) => {
    // Verifica se o valor já está no array
    if (userType.includes(value)) {
      // Se estiver, remove
      setUserType((prevUserType) =>
        prevUserType.filter((type) => type !== value)
      );
    } else {
      // Se não estiver, adiciona
      setUserType((prevUserType) => [...prevUserType, value]);
    }
  };

  const showToast = (message, type) => {
    setToastBg(type);
    setToastMessage(message);
  };

  const handleChangeCol = (event) => {
    // Lógica para lidar com a mudança de colaborador, se necessário
    const selectedId = event.target.value;
    const selected = items.find((item) => item.id === selectedId);
    setSelectedCollaborator(selected);
    // ... Outras lógicas que você possa precisar ...
  };

  const handleSave = async () => {
    // Verifique se o colaborador e o tipo de usuário foram selecionados
    if (!selectedCollaborator || !userType) {
      // Adicione lógica de tratamento aqui (por exemplo, exibir uma mensagem de erro)
      return;
    }

    // Crie o corpo da requisição
    const requestBody = {
      name: selectedCollaborator.nome,
      email: selectedCollaborator.email,
      password: selectedCollaborator.matricula.toString(),
      
    };

    if (isAdmin) {
      requestBody.roles = ["admin"];
    } else {
      // Se não for administrador, use o userType como está
      requestBody.roles = userType;
    }

    try {
      // Envie a requisição para a rota endpoints.addNewUser()
      const response = await fetch(endpoints.addNewUser(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          // Adicione outros headers necessários aqui
        },
        body: JSON.stringify(requestBody),
      });

      // Verifique o status da resposta
      if (response.ok) {
        setSuccess(true);
        showToast("Usuário adicionado com sucesso.", "success");
      } else {
        setSuccess(null);
        handleCancel(true);
        showToast("Erro ao adicionar usuário. Tente novamente.", "danger");
        console.error("Erro ao salvar novo usuário:", response.statusText);
      }
    } catch (error) {
      setSuccess(false);
      console.error("Erro de rede ao salvar novo usuário:", error.message);
    } finally {
      

      // Adicione essa chamada para exibir o Toast com base no valor de success
      
      handleClose();
    }
  };

  // Adicione mais opções conforme necessário


  //METODOS DOS CHECKBOX GERAL

  const [checkAllStates, setCheckAllStates] = useState({});
  const [isAdmin, setIsAdmin] = useState(false); // Novo estado para controlar se é administrador

  const handleChangeUserType = (e) => {
    setUserType(e.target.value);
    setIsAdmin(e.target.value === 'admin'); // Se for 'admin', define como administrador
  };
  
const handleCheckAll = (column) => {
  // Lógica para "Marcar Todos"
  const allItems = getAllItemsForColumn(column);

  if (checkAllStates[column]) {
    setUserType((prevUserType) => prevUserType.filter((type) => !allItems.includes(type)));
  } else {
    setUserType((prevUserType) => [...prevUserType, ...allItems]);
  }

  // Alternar o estado do checkbox "Marcar Todos"
  setCheckAllStates((prevStates) => ({ ...prevStates, [column]: !prevStates[column] }));
};

const checkAllColumn = (column) => {
  return checkAllStates[column] || false;
};

// Função para obter todos os itens da coluna
const getAllItemsForColumn = (column) => {
    // Implemente a lógica para retornar todos os itens da coluna
    // com base no nome da coluna (por exemplo, "clientes", "propostas", etc.)
    const itemsForColumn = {
      "clientes": ["clientes", "propostas"],
      "pessoas": ["banco-cv", "colaboradores", "funcoes"],
      "administracao": ["medicoes", "paineladministrativo"],  // Adicione os itens corretos para cada coluna
      // Adicione mais colunas conforme necessário
    };
  
    return itemsForColumn[column] || [];
  };

  //FIM DOS METODOS DE CHECK

  const initialFormData = {
    id: "",
    matricula: "",
    nome: "",
    status: "",
    filial: "",
    centro_de_custo: "",
    funcao_id: "",
    funcao_nome: "",
    tipo_de_contrato: "",
    tipo_de_trabalho: "",
    sexo: "",
    salario: "",
    nivel_funcao: "",
    data: null,
    categorizacao: "",
    data_de_nascimento: null,
    estado_civil: "",
    nome_do_conjuge: "",
    data_conjuge: null,
    quantidade_de_filhos: 0,
    // genero_filho_1: '',
    // nome_filho_01: '',
    // data_nascimento_01: '',
    // genero_filho_2: '',
    // nome_do_filho_02: '',
    // data_nascimento_02: '',
    // genero_filho_03: '',
    // nome_do_filho_03: '',
    // data_nascimento_03: '',
    // genero_de_filho_04: '',
    // nome_do_filho_04: '',
    // data_nascimento_04: '',
    // genero_do_filho_05: '',
    // nome_do_filho_05: '',
    // data_nascimento_05: '',
    formacao_1: "",
    status_1: "",
    nivel_1: "",
    formacao_2: "",
    status_2: "",
    nivel_2: "",
    descricao: "",
    experiencia_anterior: "",
    nivel_experiencia: "",
    tempo_experiencia: "",
    endereco: "",
    telefone: "",
    email: "",
    vendedor: 0,
  };

  const formatCurencie = (value) => {
    if (value && value !== null && value !== undefined) {
      const rawValue = value.toFixed(2).toString();
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      return formattedValue;
    } else {
      return null;
    }
  };

  const handleChangeValor = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setFormData({ ...formData, [name]: null });
    } else {
      const rawValue = value;
      const sanitizedValue = rawValue.replace(/[^0-9]/g, "");

      let formattedValue = "";

      if (sanitizedValue.length > 2) {
        const cents = sanitizedValue.slice(-2);
        const dollars = sanitizedValue.replace(/^0+/, "").slice(0, -2);

        const dollarsWithSeparator = dollars.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );

        formattedValue = `${
          dollarsWithSeparator.length > 0 ? dollarsWithSeparator : "0"
        }.${cents}`;
      } else {
        formattedValue = `0.${sanitizedValue}`;
      }

      setFormData({ ...formData, [name]: formattedValue });
    }
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
    setUserType('');
    setSelectedCollaborator(null);
    setCheckAllStates({});
    setIsAdmin(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== "funcao_id") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  function editItem(item) {
    setIsEditing(true);
    setFormData(item);

    const formattedValue = formatCurencie(item.salario);

    setFormData((prevFormData) => ({
      ...prevFormData,
      salario: formattedValue,
    }));

    handleOpenDialog();
  }

  return (
    <>
      <Modal show={show} onHide={()=>{setShowNewUserModal(false)}}>
        <div className="header_modules">
          <h2 className="product-title">ADICIONAR NOVO USUÁRIO</h2>
        </div>

        <div className="form-rows">
          <Form>
           
            <Form.Group className="mb-3">
              <SelectFieldFetchNew
                label="Colaborador"
                setValue={handleChangeCol}
                value={selectedCollaborator ? selectedCollaborator.id : ""}
                path="/collaborators/sellers/active"
                field={"nome"}
                valueById
                
                required={true}
                placeholder={"Selecione o Colaborador"}
              />
            </Form.Group>
            <Form.Group className="mb-3">
            <Form.Label>Tipo do usuário:</Form.Label>
            <Row>
                <Col xs={6}>
            <Form.Check
                type="radio"
                label="Administrador"
                name="userType"
                value="admin"
                checked={isAdmin} // Se for admin, marca
                onChange={handleChangeUserType}
              />
              </Col>
              <Col xs={6}>
              <Form.Check
                type="radio"
                label="Colaborador"
                name="userType"
                value=""
                checked={!isAdmin} // Se não for admin, marca
                onChange={handleChangeUserType}
              />
              </Col>
             </Row>
            </Form.Group>
            {isAdmin ? null : (
            <Form.Group className="mb-3">
              {/* Adicione o campo para o tipo de usuário */}
              <Form.Label>Acessos do Usuário</Form.Label>
              <Row>
                
                <Col xs={4}>
                <div className="acessos-column">  
                    
                CLIENTES
                    <Form.Check
                     
                    
                    type="switch"
                    
                    checked={checkAllColumn("clientes")}
                    onChange={() => handleCheckAll("clientes")}
                  /></div>
                
                  <Form.Check
                    type="checkbox"
                    label="Clientes"
                    checked={userType.includes("clientes")}
                    onChange={() => handleCheckboxChange("clientes")}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Propostas"
                    checked={userType.includes("propostas")}
                    onChange={() => handleCheckboxChange("propostas")}
                  />
                </Col>
                {/* Adicione mais checkboxes conforme necessário */}
              
                
                <Col xs={4}>
                <div  className="acessos-column"> 
                    PESSOAS    
                    <Form.Check
                    
                    type="switch"
                    
                    checked={checkAllColumn("pessoas")}
                    onChange={() => handleCheckAll("pessoas")}
                  /></div>
                
                  <Form.Check
                    type="checkbox"
                    label="Banco de CV's"
                    checked={userType.includes("banco-cv")}
                    onChange={() => handleCheckboxChange("banco-cv")}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Colaboradores"
                    checked={userType.includes("colaboradores")}
                    onChange={() => handleCheckboxChange("colaboradores")}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Funcões"
                    checked={userType.includes("funcoes")}
                    onChange={() => handleCheckboxChange("funcoes")}
                  />
                </Col>

                <Col xs={4}>
                <div className="acessos-column">  
                ADMIN.
                    <Form.Check
                    
                    type="switch"
                    
                    checked={checkAllColumn("administracao")}
                    onChange={() => handleCheckAll("administracao")}
                  /></div>
                
                  <Form.Check
                    type="checkbox"
                    label="Medições"
                    checked={userType.includes("medicoes")}
                    onChange={() => handleCheckboxChange("medicoes")}
                  />

                  <Form.Check
                    type="checkbox"
                    label="Painel adm."
                    checked={userType.includes("paineladministrativo")}
                    onChange={() => handleCheckboxChange("paineladministrativo")}
                  />
                </Col>
                {/* Adicione mais checkboxes conforme necessário */}
              </Row>
              
              
              
            </Form.Group>
            )}
            <div>Permissões :</div>
              <Form.Control
                type="text"
                placeholder="N/A"
                disabled
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                // Adicione os props necessários conforme sua lógica
              />

            <div className="form-container-buttons">
              <Button variant="secondary" onClick={()=>{setShowNewUserModal(false)}}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                disabled={!selectedCollaborator || !userType}
                onClick={() => {
                  
                  handleSave();
                  resetFormData();
                  
                }}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default ModalAddNewUser;

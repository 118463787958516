import React, { useEffect, useState } from "react";
import "./Recrutamento.css";
import logo from "./logo.png";

import { Container, Row, Col, Form, Button, Card, Spinner, InputGroup } from "react-bootstrap";

import endpoints from "../../modules/endpoints";
import { useRequest } from "../../hooks/useRequest";

function RecruitmentForm() {
  document.title = "Banco de currículos";

  const [showThanks, setShowThanks] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isEnabled, setIsEnabled] = useState(true);

  const [fileName, setFileName] = useState(undefined);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [thanks, setThanks] = useState(false);
  const [res, setRes] = useState("");

  const { sendRequest, response } = useRequest();

  const initialFormData = {
    nome_completo: "",
    email: "",
    local_de_residencia: "",
    telefone: "",
    funcoes: [],
    cv_path: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        funcoes: [...prevFormData.funcoes, value],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        funcoes: prevFormData.funcoes.filter((item) => item !== value),
      }));
    }
  };

  const resetFormData = () => {
    setFormData({ ...initialFormData });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const isPDF = selectedFile.name.toLowerCase().endsWith(".pdf");

      if (isPDF) {
        setFileName(selectedFile ? selectedFile.name : "Selecione o arquivo...");
        setUploadedFile(selectedFile);
        setFormData({ ...formData, file: selectedFile });
        setIsEnabled(true);
      } else {
        setIsEnabled(false);
        setFileName("Selecione o arquivo...");
        setErrorMessage("formato do arquivo inválido, o arquivo enviado deve ser .pdf");
        setShowError(true);
      }
    } else {
      setIsEnabled(false);
      setUploadedFile(null);
      setFileName("Selecione o arquivo...");
    }
  };

  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      if (uploadedFile) {
        const formFile = new FormData();
        formFile.append("file", uploadedFile);

        fetch(endpoints.recruitmentFile(), {
          method: "POST",
          body: formFile,
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            const form = {
              nome_completo: formData.nome_completo,
              email: formData.email,
              local_de_residencia: formData.local_de_residencia,
              telefone: formData.telefone,
              funcoes: formData.funcoes,
              filename: `${data.filename}`,
            };

            const newRecruit = { ...form };

            sendRequest(endpoints.recrutamento(), "POST", newRecruit);
            console.log(response);
             setRes("Dados enviados com sucesso");
          })
          .catch((err) => console.error(err));
      }
    }
  };

  useEffect(() => {
    if (res === "Dados enviados com sucesso") {
      setShowThanks(true);
      setShowForm(false);
    }
  }, [res]);

  const locais = [
    "MARACÁS",
    "POJUCA",
    "CATU",
    "MATA DE SÃO JOÃO",
    "DIAS D'ÁVILA",
    "CAMAÇARI",
    "SERGIPE/NOSSA SENHORA DO SOCORRO",
    "SERGIPE/LARANJEIRAS",
    "SERGIPE/ARACAJU",
    "OUTRO",
  ];

  const funcoes = [
    "ELETRICISTA INDUSTRIAL",
    "SOLDADOR",
    "CALDEIREIRO",
    "MECÂNICO INDUSTRIAL",
    "VULCANIZADOR",
    "PLANEJADOR DE OBRAS INDUSTRIAIS",
    "TÉCNICO DE SEGURANÇA",
    "ENCARREGADO DE OBRAS INDUSTRIAIS",
    "OUTROS",
  ];

  return (
    <div className="content-research">
      {showForm && (
        <div className="back-reasearch">
          <Container className="research ">
            <Row className="research-employee">
              <Col xs={12} md={8}>
                <div className="text-center ">
                  <div className="research-header">
                    <img src={logo} className="app-logo" alt="logo" height={120} />
                    <h4>Deixe suas informações e currículo conosco!</h4>
                    <p>
                      Somos uma empresa especializada em manutenção de ativos industriais. Estamos sempre em busca de
                      profissionais talentosos para fazer parte da nossa equipe. Preencha o formulário abaixo e envie
                      seu currículo para avaliação. Valorizamos seu interesse em trabalhar conosco!
                    </p>
                    <p className="research-warning">Obrigatório preencher todos os campos</p>
                  </div>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Card className="mb-1 research-employee">
                    <Card.Body className="employee-card-body">
                      <Form.Group controlId="formName">
                        <Form.Label>
                          Nome completo?
                          <span className="research-warning"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="nome_completo"
                          value={formData.nome_completo}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-1 research-employee">
                    <Card.Body className="employee-card-body">
                      <Form.Group controlId="formEmail">
                        <Form.Label>
                          Email
                          <span className="research-warning"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-1 research-employee">
                    <Card.Body className="employee-card-body">
                      <Form.Group controlId="formTelefone">
                        <Form.Label>
                          Telefone
                          <span className="research-warning"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="telefone"
                          value={formData.telefone}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-1 research-employee">
                    <Card.Body className="employee-card-body">
                      <Form.Group controlId="formResidencia" as={Row}>
                        <Form.Label>
                          Local de residência
                          <span className="research-warning"> *</span>
                        </Form.Label>
                        <Col sm={12} xs={12} md={12} className="radio-employee">
                          {locais.map((option) => (
                            <Form.Check
                              inline
                              key={option}
                              type="radio"
                              label={option}
                              name="local_de_residencia"
                              value={option}
                              checked={formData.local_de_residencia !== null && formData.local_de_residencia === option}
                              onChange={handleChange}
                              required
                            />
                          ))}
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-1 research-employee">
                    <Card.Body className="employee-card-body">
                      <Form.Group controlId="formFuncoesCk" as={Row}>
                        <Form.Label>
                          Escolha sua função/funções
                          <span className="research-warning"> *</span>
                        </Form.Label>
                        <Col sm={12} xs={12} md={12} className="radio-employee">
                          {funcoes.map((option) => (
                            <Form.Check
                              key={option}
                              type="checkbox"
                              label={option}
                              value={option}
                              checked={formData.funcoes.includes(option)}
                              onChange={handleCheckboxChange}
                            />
                          ))}
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <Card className="mb-1 research-employee">
                    <Card.Body className="employee-card-body">
                      <Form.Group controlId="formCV" as={Row}>
                        <Form.Label>
                          Deixe seu currículo aqui
                          <span className="research-warning"> *</span>
                        </Form.Label>
                        <Col sm={6} xs={6} md={6}>
                          <Form.Control type="file" hidden id="file-input" accept=".pdf" onChange={handleFileChange} />

                          <InputGroup className="mb-3">
                            <Form.Control
                              type="text"
                              className="mr-2"
                              readOnly
                              accept=".pdf"
                              value={fileName}
                              onClick={() => {
                                setShowError(false);
                                document.getElementById("file-input").click();
                              }}
                            />
                            <Button
                              variant="outline-dark"
                              onClick={() => {
                                setShowError(false);
                                document.getElementById("file-input").click();
                              }}
                            >
                              Importar
                            </Button>
                          </InputGroup>
                          <span className="research-warning">* Formato do currículo deve ser PDF</span>
                          {showError && (
                            <Form.Label
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMessage}
                            </Form.Label>
                          )}
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Card>

                  <div className="form-space-betwwen">
                    <Button variant="primary" type="submit" disabled={!isEnabled}>
                      Enviar
                    </Button>

                    <Button variant="link" onClick={resetFormData}>
                      Limpar formulário
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showThanks ? (
        <>
          <Container className="thanks">
            <Col xs={3}>
              <div></div>
            </Col>
            <Col xs={6}>
              <div className="text-center ">
                <div>
                  <img src={logo} className="app-logo" alt="logo" height={120} />
                  <h3 className="custom-heading">Muito Obrigado!</h3>
                  <p>Agradecemos muito por ter dedicado um tempo para nos enviar seus dados!</p>
                </div>
              </div>
            </Col>
          </Container>
        </>
      ) : (
        !showForm && (
          <Container className="thanks">
            <Col xs={6}>
              <div></div>
            </Col>
            <Col xs={1}>
              <Spinner></Spinner>
            </Col>
          </Container>
        )
      )}
    </div>
  );
}

export default RecruitmentForm;

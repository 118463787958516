import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './LoginPage.css';
import { Container, Row, Col, Form, Button, Card, Image } from 'react-bootstrap';
import { useRequest } from '../hooks/useRequest';
import endpoints from '../modules/endpoints';
import { useRequestUserInfos } from '../hooks/useRequestUserInfos';

function LoginPage() {
  document.title = 'ERP Volpe';
  const navigate = useNavigate();

  const { sendRequest, response } = useRequest();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { getUserInfos, userInfos } = useRequestUserInfos();

  const handleLogin = (e) => {
    e.preventDefault();
    try {
      sendRequest(endpoints.login(), 'POST', { email, password });
    } catch (error) {
      localStorage.removeItem('authToken');
    }
  };

  useEffect(() => {
    if (userInfos) {
      if (userInfos.status === 'error') {
        localStorage.clear();
        navigate('/');
      } else {
        const roles = JSON.parse(userInfos.roles);
        if (roles[0] === 'admin' || roles.includes('propostas')) {
          navigate(`/clientes/proposalsRanking`);
        } else {
          if (roles.includes('banco-cv')){
            navigate(`/pessoas/recrutamentos`);
          }
          else if (roles.includes('medicoes')){
            navigate(`/adm/medicoes`);
          
        } else {
          navigate(`/${roles[0]}`);
        }
        }
      }
    }
  }, [navigate, userInfos]);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      getUserInfos(authToken);
    }
  });

  useEffect(() => {
    if (response) {
      if (response.token) {
        localStorage.setItem('authToken', response.token);
        
        getUserInfos(response.token);
        setError(null);
      } else {
        setError(response.message);
      }
    }
  }, [getUserInfos, response]);

  return (
    <>
      {!localStorage.getItem('authToken') && (
        <Container fluid className='min-vh-100 login-back'>
          <Row className='justify-content-center align-items-center min-vh-100 '>
            <Col xs={12}>
              <Card className='login-form' text='light'>
                <Card.Header className='login-page-form'>
                  <Image src='https://grupovolpe.ind.br/wp-content/uploads/2022/11/Logo-GRUPO-VOLPE-PNG.png' className='login-img ' height={140}></Image>
                </Card.Header>

                <Card.Body className='login-page-form'>
                  <Form onSubmit={handleLogin}>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Control
                        required
                        type='email'
                        placeholder='Email'
                        className='input-login'
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group controlId='formBasicPassword'>
                      <Form.Control
                        required
                        type='password'
                        placeholder='Senha'
                        className='input-login'
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {
                      <div className='error-message'>
                        <span>{error && error}</span>
                      </div>
                    }

                    <Button type='submit' className='btn-submit-login w-100'>
                      ENTRAR
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default LoginPage;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logo from './logo.png';
import './ServicesResearch.css';

import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';

import endpoints from '../../modules/endpoints';
import { useRequest } from '../../hooks/useRequest';

function ServiceResearch() {
  document.title = 'Pesquisa de serviço';

  const [showThanks, setShowThanks] = useState(false);
  const [invalidResearch, setInvalidResearch] = useState(false);
  const [showForm, setShowForm] = useState(false); //change to false

  const { sendRequest, response } = useRequest();
  const { sendRequest: getResearch, response: researchResponse } = useRequest();
  const {response:name, sendRequest:setName} = useRequest(); //nome do colaborador
  const {response :proposta, sendRequest:setProposta} = useRequest();
  const {response :total, sendRequest:setTotal} = useRequest();
  const { venda, colaborador } = useParams();
 

  const initialFormData = {
    id: '',
    relacionamento: 0,
    concorrencia: 0,
    complexidade_tecnica: 0,
    necessidade_do_cliente: 0,
    chance_de_fechar: 0,
    total: 0,
    proposta_id: venda,
    colaborador_id: colaborador,
    servico: '',
    cliente: '',
    tecnico: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    getResearch(`${endpoints.pesquisaRanking()}/${venda}/${colaborador}`, 'GET');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venda, colaborador]);

  useEffect(() => {
    if (researchResponse) {
      if (researchResponse.status && researchResponse.status === 'error') {
        setInvalidResearch(true);
        setShowForm(false);
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          id: researchResponse.id,
          relacionamento: researchResponse.relacionamento,
          concorrencia: researchResponse.concorrencia,
          complexidade_tecnica: researchResponse.complexidade_tecnica,
          necessidade_do_cliente: researchResponse.necessidade_do_cliente,
          chance_de_fechar: researchResponse.chance_de_fechar,
          total: researchResponse.total,
          servico: researchResponse.servico,
          cliente: researchResponse.razao_social,
          tecnico: researchResponse.cliente_tecnico_responsavel,
        }));
        
          setProposta(endpoints.proposals(), 'GET', researchResponse.proposta_id);
          setName(endpoints.colaboradores(), 'GET', colaborador);
          setTotal(`${endpoints.calculateRanking()}/${researchResponse.proposta_id}`, 'GET');
          if (total){
            console.log(total.totalSum);
          }
          
          
        
        setShowForm(true);
        setInvalidResearch(false);
      }
      
    }
  }, [researchResponse]);

  useEffect(() => {
    if (response && response.message === 'Resposta enviada com sucesso!') {
      setShowThanks(true);
      setShowForm(false);
    }
  }, [response]);

  const calculateTotal = () => {
    const totalGrades = formData.relacionamento + formData.concorrencia + formData.complexidade_tecnica + formData.necessidade_do_cliente + formData.chance_de_fechar;

    return totalGrades;
  };

  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      const total = calculateTotal();

      const updatedFormData = {
        ...formData,
        total: total,
      };

      const grades = updatedFormData;

      sendRequest(endpoints.updateRanking(), 'PUT', grades);
    }
  };

  const handleScoreChange = (e) => {
    const { name, value } = e.target;
    if (!(value === `${formData[name]}`)) {
      setFormData({ ...formData, [name]: parseInt(value) });
    } else {
      setFormData({ ...formData, [name]: null });
    }
  };

  const arrayGrades = [1, 2, 3, 4, 5];

  return (
    <div className='content-research'>
      {invalidResearch && (
        <>
          <Container className='thanks'>
            <Col xs={3}>
              <div></div>
            </Col>
            <Col xs={6}>
              <div className='text-center '>
                <div>
                  <img src={logo} className='app-logo' alt='logo' height={120} />
                  <h3 className='custom-heading'>Link de pesquisa inválido!</h3>
                </div>
              </div>
            </Col>
          </Container>
        </>
      )}

      {showForm && (
        <div className='back-reasearch'>
          <Container className='research text-center'>
            <Row className='research-service'>
              
              <Col xs={12} md={8}>
                <div className='text-center '>
                  <div className='research-header'>
                    <img src={logo} className='app-logo' alt='logo' height={120} />
                    <h3 className='custom-heading'>Pesquisa de serviço</h3>
                    <h5>{proposta && `Cliente: ${proposta.cliente.razao_social}`}</h5>
                    <h5>{proposta && `Serviço: ${proposta.servico}`}</h5>
                    <h5>{proposta && `Técnico: ${proposta.cliente_tecnico_responsavel}`}</h5>
                    <h5>{name && `Colaborador: ${name.nome}`}</h5>
                  </div>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId='formIndicateToFriend' as={Row}>
                    <Col sm={12} xs={12} md={12}>
                      <div className='question'>Relacionamento?</div>
                      <div className='score-container'>
                        {arrayGrades.map((value) => (
                          <button
                            key={value}
                            type='button'
                            name='relacionamento'
                            className={`score-button ${formData.relacionamento === value ? 'selected' : ''} ${value <= 2 ? 'red' : value <= 4 ? 'yellow' : 'green'}`}
                            value={value}
                            onClick={(e) => handleScoreChange(e)}
                          >
                            {value}
                          </button>
                        ))}
                      </div>
                    </Col>
                    <Col sm={12} xs={12} md={12}>
                      <div className='question'>Concorrência?</div>
                      <div className='score-container'>
                        {arrayGrades.map((value) => (
                          <button
                            key={value}
                            type='button'
                            name='concorrencia'
                            className={`score-button ${formData.concorrencia === value ? 'selected' : ''} ${value <= 2 ? 'red' : value <= 4 ? 'yellow' : 'green'}`}
                            value={value}
                            onClick={(e) => handleScoreChange(e)}
                          >
                            {value}
                          </button>
                        ))}
                      </div>
                    </Col>
                    <Col sm={12} xs={12} md={12}>
                      <div className='question'>Complexidade técnica?</div>
                      <div className='score-container'>
                        {arrayGrades.map((value) => (
                          <button
                            key={value}
                            type='button'
                            name='complexidade_tecnica'
                            className={`score-button ${formData.complexidade_tecnica === value ? 'selected' : ''} ${value <= 2 ? 'red' : value <= 4 ? 'yellow' : 'green'}`}
                            value={value}
                            onClick={(e) => handleScoreChange(e)}
                          >
                            {value}
                          </button>
                        ))}
                      </div>
                    </Col>
                    <Col sm={12} xs={12} md={12}>
                      <div className='question'>Necessidade do cliente?</div>
                      <div className='score-container'>
                        {arrayGrades.map((value) => (
                          <button
                            key={value}
                            type='button'
                            name='necessidade_do_cliente'
                            className={`score-button ${formData.necessidade_do_cliente === value ? 'selected' : ''} ${value <= 2 ? 'red' : value <= 4 ? 'yellow' : 'green'}`}
                            value={value}
                            onClick={(e) => handleScoreChange(e)}
                          >
                            {value}
                          </button>
                        ))}
                      </div>
                    </Col>
                    <Col sm={12} xs={12} md={12}>
                      <div className='question'>Chance de fechar?</div>
                      <div className='score-container'>
                        {arrayGrades.map((value) => (
                          <button
                            key={value}
                            type='button'
                            name='chance_de_fechar'
                            className={`score-button ${formData.chance_de_fechar === value ? 'selected' : ''} ${value <= 2 ? 'red' : value <= 4 ? 'yellow' : 'green'}`}
                            value={value}
                            onClick={(e) => handleScoreChange(e)}
                          >
                            {value}
                          </button>
                        ))}
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='formBasicComentario'>
                    <Button id='btn-save-ranking' variant='success' type='submit'>
                      Salvar
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {showThanks ? (
        <>
          <Container className='thanks'>
            <Col xs={3}>
              <div></div>
            </Col>
            <Col xs={6}>
              <div className='text-center '>
                <div>
                  <img src={logo} className='app-logo' alt='logo' height={120} />
                  <h3 className='custom-heading'>Muito Obrigado!</h3>
                  <p>Agradecemos muito por ter dedicado um tempo para responder nossa pesquisa.</p>
                </div>
              </div>
            </Col>
          </Container>
        </>
      ) : (
        !showForm && (
          <Container className='thanks'>
            <Col xs={6}>
              <div></div>
            </Col>
            <Col xs={1}>
              <Spinner></Spinner>
            </Col>
          </Container>
        )
      )}
    </div>
  );
}

export default ServiceResearch;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useFetchById = () => {
  const navigate = useNavigate();
  const [data, setData] = useState("");

  const [config, setConfig] = useState("");
  const [method, setMethod] = useState("");
  const [callFetch, setCallFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [postUrl, setPostUrl] = useState("");
  const [getUrl, setGettUrl] = useState("");

  const [authToken] = useState(localStorage.getItem("authToken"));

  const httpConfig = (postUrl, getUrl, data, method) => {
    setPostUrl(postUrl);
    setGettUrl(getUrl);
    if (method === "POST") {
      setConfig({
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      });
      setMethod(method);
    } else if (method === "PUT") {
      setConfig({
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      });
      setMethod(method);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (getUrl === "") {
        return;
      }
      setLoading(true);
      try {
        const res = await fetch(`${getUrl}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (res.status === 401) {
          localStorage.clear();
          navigate("/");
        } else {
          const json = await res.json();
          setData(json);
        }
      } catch (error) {
        setError("Desculpa :( erro ao carregar dados, tente novamente em alguns instantes!");
      }

      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUrl, callFetch]);

  useEffect(() => {
    const httpRequest = async () => {
      if (method === "POST") {
        let fetchOptions = [postUrl, config];

        const res = await fetch(...fetchOptions);
        if (res.status === 401) {
          localStorage.clear();
          navigate("/");
        } else {
          const json = await res.json();
          setCallFetch(json);
        }
      } else if (method === "PUT") {
        let fetchOptions = [postUrl, config];

        const res = await fetch(...fetchOptions);
        if (res.status === 401) {
          localStorage.clear();
          navigate("/");
        } else {
          const json = await res.json();
          setCallFetch(json);
        }
      }
    };
    httpRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, method, postUrl]);

  return { data, httpConfig, loading, error };
};

import { useState, useEffect, useRef, useMemo } from 'react';
import { useFetch } from '../hooks/useFetch';

import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Table from '../components/Table';
import FilterDate from '../components/FilterDate';
import endpoints from '../modules/endpoints';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Sidebar from '../components/Sidebar';
import { useNavigate } from 'react-router-dom';

function Medicoes() {
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [openModalImport, setOpenModalImport] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDimiAlert, setShowDimiAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate= useNavigate();
  const today = new Date();
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1).toISOString().substring(0, 10));
  const [endDate, setEndDate] = useState(today.toISOString().substring(0, 10));
  const [currentDateOption, setCurrentDateOption] = useState('this_month');

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('Selecione o arquivo...');
  const [fileLoading, setFileLoading] = useState(false);

  const [url, setUrl] = useState(`${endpoints.medicoes()}?filter=${currentDateOption}`); //alteração da exibição de registros para o mes atual
  const { data: items, httpConfig, loading, error } = useFetch(url);

  const initialFormData = {
    id: '',
    check: '',
    zona: '',
    coordernador: '',
    centro_custo: '',
    registro: '',
    cod: '',
    servico: '',
    pedido_compra: '',
    competencia: '',
    solicitante: '',
    prestadora: '',
    cliente: '',
    cnpj: '',
    valor_medicao: '',
    desconto_acrescimo: '',
    valor_medicao_final: '',
    valor_pedidos_regulares: '',
    valor_faturado: '',
    valor_pedidos_irregulares: '',
    nota_fiscal: '',
    faturar: '',
    regularidade: '',
    inicio_servico: '',
    previsao_termino_servico: '',
    termino_servico: '',
    entrega_boletim_cliente: '',
    dias_atraso_entrega_boletim: '',
    aprovacao_boletim: '',
    resposta_aprovacao: '',
    dias_atraso_aprovacao: '',
    data_emissao_nota_fiscal: '',
    data_pagamento_nota_fiscal: '',
    dias_atraso_pagamento: '',
    observacao: '',
    previsao_faturamento: '',
    previsao_recebimento: '',
    valor_liquido: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const [authToken] = useState(localStorage.getItem('authToken'));

  function handleDateRangeChange(start, end, currentDateOption) {
    setStartDate(start);
    setEndDate(end);
    setCurrentDateOption(currentDateOption);
      setUrl(`${endpoints.medicoes()}?filter=${currentDateOption}`);
    
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleOpenModalImport = () => {
    setOpenModalImport(true);
    setShowDimiAlert(false);
  };

  const handleCloseModalImport = () => {
    setFile(null);
    setFileName('Selecione o arquivo...');
    setOpenModalImport(false);
  };

  const handleSubmitFile = async (e) => {
    setShowError(false);
    if (e && e.preventDefault) {
      e.preventDefault();
      console.log(file);
      if (!file) {
        setErrorMessage('Por favor, selecione um arquivo');
        setShowError(true);
        return;
      }
      const measurements = new FormData();
      measurements.append('measurements', file);

      try {
        setFileLoading(true);
        const response = await fetch(endpoints.uploadCSV(), {
          method: 'POST',
          body: measurements,

          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        console.log(response);

        if (response.ok) {
          const resp = await response.json();
          if (!resp.error) {
            setShowAlert(true);
          } else {
            setShowDimiAlert(true);
          }
          handleCloseModalImport();
          setFileLoading(false);
        } else {
          setFileLoading(false);
          setShowError(true);
          setErrorMessage('Ocorreu um erro ao enviar o arquivo CSV para o servidor!');
        }
      } catch (error) {
        setFileLoading(false);
        setShowError(true);
        setErrorMessage('Ocorreu um erro ao enviar o arquivo CSV para o servidor!!');
      }
    }
    setTimeout(() => {
      setShowAlert(false);
      setShowAlertEdit(false);
    }, 5000);
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : 'Selecione o arquivo...');
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      if (isEditing) {
        httpConfig(formData, 'PUT');
      } else {
        httpConfig(formData, 'POST');
      }

      handleCloseDialog();

      if (!isEditing) {
        setShowAlert(true);
      } else {
        setShowAlertEdit(true);
      }

      setIsEditing(false);

      setTimeout(() => {
        setShowAlert(false);
        setShowAlertEdit(false);
      }, 5000);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.id,
        header: 'Item',
        size: 30,
      },
      {
        accessorFn: (row) => row.competencia,
        header: 'Comp.',
      },
      {
        accessorFn: (row) => row.zona,
        header: 'Zona',
        enableHiding: false,
        size: 30,
      },
      {
        accessorFn: (row) => row.coordenador,
        header: 'Coordenador',
        size: 40,
      },
      {
        accessorFn: (row) => row.centro_custo,
        header: 'Centro de custo',
        size: 60,
      },
      {
        accessorFn: (row) => row.servico,
        header: 'Serviço',
      },
    ],
    []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  function editItem(item) {
    setIsEditing(true);

    setFormData(item);

    handleOpenDialog();
  }

  return (
    <>
      <Sidebar />
      <div className='content' ref={elementRef}>
        {showAlertEdit && (
          <Alert key='success' variant='success'>
            Registro editado com sucesso!
          </Alert>
        )}
        {showAlert && (
          <Alert key='success' variant='success'>
            Registros enviados com sucesso!
          </Alert>
        )}

        {showDimiAlert && (
          <Alert
            variant='danger'
            onClose={() => {
              setShowDimiAlert(false);
            }}
            dismissible
          >
            <Alert.Heading>Falha ao importar registros!</Alert.Heading>
          </Alert>
        )}

        <div className='header_modules_filtered'>
          <Row>
            <h2 className='product-title'>MEDIÇÕES</h2>
          </Row>
          {!error && !loading ? (
            <>
              {/* <Button onClick={handleOpenDialog} className='btn-add-roles btn-success' >Nova Medição</Button> */}
              <Row>
                <Col xs={2}>
                  <FilterDate onDateRangeChange={handleDateRangeChange} startOption={currentDateOption} />
                </Col>
                <Col>
                
                  <Button onClick={handleOpenModalImport} className='btn-add-roles btn-success'>
                    Importar Medições
                  </Button>
                  {/* <Button
                  onClick={() => {
                    navigate("/clientes/controle-pagamento");
                  }}
                  className="btn-add-roles btn-primary"
                >
                  Métricas
                </Button> */}
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <Button className='btn-add-roles btn-success' disabled>
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  <span>Loading...</span>
                </Button>
              </Col>
            </Row>
          )}
          
        </div>
        <div className='table_funcoes'>
          <Table elementHeight={height - 250} error={error} loading={loading} columns={columns} items={items} funcao={editItem} initialState={{ density: 'compact', pagination: { pageSize: 100 } }} />
        </div>
        <div>
          <Modal show={openDialog} onHide={handleCloseDialog} centered size='xl'>
            <Form onSubmit={handleSubmit} className='form-page'>
              <Modal.Header closeButton>{<Modal.Title>ITEM: {formData.id}</Modal.Title>}</Modal.Header>
              <Modal.Body>
                <Form.Group className='mb-3' controlId='formBasicNome'>
                  <Row>
                    <Col>
                      <Form.Label>Zona</Form.Label>
                      <Form.Control disabled value={formData.zona} name='zona' type='text' onChange={handleChange} />
                    </Col>

                    <Col>
                      <Form.Label>Coordenador</Form.Label>
                      <Form.Control disabled value={formData.coordenador} name='coordenador' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Centro de custo</Form.Label>
                      <Form.Control disabled value={formData.centro_de_custo} name='centro_de_custo' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Registro</Form.Label>
                      <Form.Control disabled value={formData.registro} name='registro' type='text' onChange={handleChange} />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Competência</Form.Label>
                      <Form.Control disabled value={formData.competencia} name='competencia' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Pedido de compra</Form.Label>
                      <Form.Control disabled value={formData.pedido_compra} name='pedido_compra' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Cod.</Form.Label>
                      <Form.Control disabled value={formData.cod} name='cod' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Serviço</Form.Label>
                      <Form.Control disabled value={formData.servico} name='servico' type='text' onChange={handleChange} />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Solicitante</Form.Label>
                      <Form.Control disabled value={formData.solicitante} name='solicitante' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Prestadora</Form.Label>
                      <Form.Control disabled value={formData.prestadora} name='prestadora' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Cliente</Form.Label>
                      <Form.Control disabled value={formData.cliente} name='cliente' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>CNPJ</Form.Label>
                      <Form.Control disabled value={formData.cnpj} name='cnpj' type='text' onChange={handleChange} />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Form.Label>Valor Medição</Form.Label>
                      <Form.Control disabled value={formData.valor_medicao} name='valor_medicao' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Desconto/Acréscimo</Form.Label>
                      <Form.Control disabled value={formData.desconto_acrescimo} name='desconto_acrescimo' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Medição 'FINAL'</Form.Label>
                      <Form.Control disabled value={formData.valor_medicao_final} name='valor_medicao_final' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Pedidos Reguares</Form.Label>
                      <Form.Control disabled value={formData.valor_pedidos_regulares} name='valor_pedidos_regulares' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Pedidos Irreg.</Form.Label>
                      <Form.Control disabled value={formData.valor_pedidos_irregulares} name='valor_pedidos_irregulares' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Faturado</Form.Label>
                      <Form.Control disabled value={formData.valor_faturado} name='valor_faturado' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>A Faturar</Form.Label>
                      <Form.Control disabled value={formData.faturar} name='faturar' type='text' onChange={handleChange} />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Nota Fiscal</Form.Label>
                      <Form.Control disabled value={formData.nota_fiscal} name='nota_fiscal' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Emissão Nota Fiscal</Form.Label>
                      <Form.Control disabled value={formData.data_emissao_nota_fiscal} name='data_emissao_nota_fiscal' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Pag. Nota Fiscal</Form.Label>
                      <Form.Control disabled value={formData.data_pagamento_nota_fiscal} name='data_pagamento_nota_fiscal' type='text' onChange={handleChange} />
                    </Col>

                    <Col>
                      <Form.Label>Previsão faturamento</Form.Label>
                      <Form.Control disabled value={formData.previsao_faturamento} name='previsao_faturamento' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Previsãorecebimento</Form.Label>
                      <Form.Control disabled value={formData.previsao_recebimento} name='previsao_recebimento' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Valor liquido</Form.Label>
                      <Form.Control disabled value={formData.valor_liquido} name='valor_liquido' type='text' onChange={handleChange} />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Form.Label>Regularidade</Form.Label>
                      <Form.Control disabled value={formData.regularidade} name='regularidade' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Início serviço</Form.Label>
                      <Form.Control disabled value={formData.inicio_servico} name='inicio_servico' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Previsão Término</Form.Label>
                      <Form.Control disabled value={formData.previsao_termino_servico} name='previsao_termino_servico' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Término do serviço</Form.Label>
                      <Form.Control disabled value={formData.termino_servico} name='termino_servico' type='text' onChange={handleChange} />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Entrega boletim</Form.Label>
                      <Form.Control disabled value={formData.entrega_boletim_cliente} name='entrega_boletim_cliente' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Aprovação boletim</Form.Label>
                      <Form.Control disabled value={formData.aprovacao_boletim} name='aprovacao_boletim' type='text' onChange={handleChange} />
                    </Col>
                    <Col>
                      <Form.Label>Respota aprovação</Form.Label>
                      <Form.Control disabled value={formData.resposta_aprovacao} name='resposta_aprovacao' type='text' onChange={handleChange} />
                    </Col>
                  </Row>

                  <hr />
                  <Form.Label>Observações</Form.Label>
                  <Form.Control as='textarea' rows={2} type='text' value={formData.observacao} name='observacao' onChange={handleChange} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className='btn-controls'>
                  <Button className='btn-danger action-btn' onClick={handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button className='btn-success action-btn' type='submit'>
                    {' '}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>

        <div>
          <Modal show={openModalImport} onHide={handleCloseModalImport} centered>
            <Form onSubmit={handleSubmitFile} className='form-page'>
              <Modal.Header closeButton>
                <Modal.Title>Enviar Medições</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Control type='file' hidden id='file-input' accept='.csv' onChange={onFileChange} />

                {!fileLoading ? (
                  <InputGroup className='mb-3'>
                    <Form.Control
                      type='text'
                      className='mr-2'
                      readOnly
                      value={fileName}
                      onClick={() => {
                        setShowError(false);
                        document.getElementById('file-input').click();
                      }}
                    />
                    <Button
                      variant='outline-dark'
                      onClick={() => {
                        setShowError(false);
                        document.getElementById('file-input').click();
                      }}
                    >
                      Importar
                    </Button>
                  </InputGroup>
                ) : (
                  <>
                    {' '}
                    Registrando dados, aguarde...
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  </>
                )}
                {showError && <Form.Label style={{ color: 'red' }}>{errorMessage}</Form.Label>}
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className='btn-controls'>
                  <Button className='btn-danger action-btn' onClick={handleCloseModalImport}>
                    Cancelar
                  </Button>
                  <Button className='btn-success action-btn' type='submit'>
                    Enviar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Medicoes;

import React, { useMemo, useState, useEffect } from "react";
import {
  Alert,
  Button,
  Form,
  Modal,
  Accordion,
  Spinner,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import Table from "../Table";
import { useFetchById } from "../../hooks/useFetchById";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useFetchPerformatic } from "../../hooks/useFetchPerformatic";
import endpoints from "../../modules/endpoints";
import SelectFieldFetchWithId from "../selectFieldFetchWithId";
import { useRequestUserInfos } from "../../hooks/useRequestUserInfos";

const RankingTable = ({ id, colid, showTableRanking }) => {

  console.log("id:", id);
  console.log("colid:", colid);
  const ID = id;
  const COLID = colid;
  const [showServiceRanking, setShowServiceRanking] = useState(false);
  const [employeeToAddInList, setEmployeeToAddInList] = useState("");
  const [employeeToAdd, setEmployeeToAdd] = useState("");
  const [rankingEmployeeList2, setRankingEmployeeList] = useState([]);
  const [acordeaoAberto, setAcordeaoAberto] = useState(false);
  const [authToken] = useState(localStorage.getItem("authToken"));
  const [go, setGo] = useState(false);

  const { getUserInfos, userInfos } = useRequestUserInfos();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      getUserInfos(authToken);
    }
  }, []);

  const {
    data: rankingEmployeeList,
    httpConfig: httpConfigRanking,
    loading: loadingRanking,
    error: errorRanking,
  } = useFetchPerformatic(endpoints.pesquisaSaleService() + `/${ID}`);
 
  const columnsRanking = useMemo(
    () => [
      {
        accessorKey: "colaborador.nome",
        header: "Colaborador",
        size: 100,
        minSize: 100,
      },
      {
        accessorKey: "total",
        header: "Total",
        size: 25,
        maxSize: 25,
      },
      {
        accessorKey: "relacionamento",
        header: "Re.",
        size: 14,
        maxSize: 14,
      },
      {
        accessorKey: "complexidade_tecnica",
        header: "C.T",
        size: 10,
        maxSize: 12,
      },
      {
        accessorKey: "concorrencia",
        header: "Con.",
        size: 12,
        maxSize: 12,
      },
      {
        accessorKey: "necessidade_do_cliente",
        header: "N.C",
        size: 30,
        maxSize: 32,
      },
      {
        accessorKey: "chance_de_fechar",
        header: "C.F",
        size: 30,
        maxSize: 32,
      },
    ],
    []
  );

  const initialFormData = {
    id: ID,
    cliente_id: "",
    cidade: "",
    estado: "",
    comprador: "",
    email: "",
    telefone: "",
    revisao: "0",
    tipo: "",
    data_solicitacao: null,
    data_limite_envio: null,
    hora_limite_envio: null,
    status: "RECEBIDO",
    status_finalizacao: "",
    numero_cotacao_ordem_servico: "",
    numero_cotacao_externo: "",
    servico: "",
    data_previsao_inicio: null,
    data_previsao_termino: null,
    cliente_empresa_responsavel: "VOLPE MANUTENÇÃO IND",
    cliente_tecnico_responsavel: "",
    equipe_designada: "",
    obs: "",
    valor: null,
    disciplina: "",
    ncg: null,
    retorno: null,
  };

  const [formData, setFormData] = useState(initialFormData);



  const handleChangeEmployee = (e) => {
    const { value } = e.target;
    setEmployeeToAddInList(value);
  };

  const handleUpdateRanking = async () => {
    console.log('Updating reports...');

    try {
      

      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error('Auth token not found');
        return;
      }

      const response = await fetch(endpoints.pesquisaSaleService() + `/${ID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch reports: ${response.statusText}`);
      }

      const jsonData = await response.json();

      // Atualize o estado com os novos dados
      setNovaData(jsonData);
    } catch (error) {
      console.error('Error updating reports:', error);
    } 
  };


 

  const handleShowRankingEmployee = () => {
    try {
      // Realiza a requisição POST
       const getData = httpConfigRanking(
        endpoints.pesquisaSaleService(),
        endpoints.pesquisaSaleService() + `/${ID}`,
        { proposta_id: ID },
        "GET"
      );

      // Após a requisição POST, atualiza o estado rankingEmployeeList
      const updatedData = httpConfigRanking(
        endpoints.pesquisaSaleService() + `/${ID}`
      );
      
      setShowServiceRanking(false);
      
      setEmployeeToAddInList("");
      setShowServiceRanking(true);
      
    } catch (error) {
      // Lida com erros, se necessário
      console.error("Erro ao adicionar ranking:", error);
      console.log(rankingEmployeeList);
    }
  };


  const addVendor = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };

      const requestBody = {
        proposta_id: ID,
        colaborador_id: employeeToAddInList,
      };

      const response = await fetch(
        `${endpoints.pesquisaSaleService()}`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        console.log("Vendedor adicionado com sucesso!");
        
        
        // Configura o go para acionar o useEffect e atualizar a tabela
        handleUpdateRanking();
        setGo(true);
        // Faça o que for necessário após a adição do vendedor
      } else {
        console.error("Erro ao adicionar vendedor:", response.status);
        // Se necessário, lidar com o erro de alguma maneira
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
      // Lidar com erros de rede ou outras exceções
    }
    
    handleShowRankingEmployee();
  };


   //-----------INICIO DO TRECHO QUE ATUALIZA A TABELA AO INSERIR DADOS-----------------------------------\\
   const [novaData, setNovaData] = useState([]);

   useEffect(() => {
     setNovaData(rankingEmployeeList); // Atualiza 'novaData' sempre que 'items' mudar
   }, [rankingEmployeeList]);
 //-----------FIM DO TRECHO QUE ATUALIZA A TABELA AO INSERIR DADOS----------------------------------------\\

  // ... (lógica de fetch usando useFetchPerformatic e useFetchById)

  const renderMenuItems = (row) => {
    return (
      <Box>
        <FontAwesomeIcon
          icon={faCopy}
          size="xl"
          onClick={(event) => {
            // navigator.clipboard.writeText(
            //   endpoints.pesquisaSaleService() + `/${ID}`
            // );
            const url =
              endpoints.baseApplicationUrl() +
              `/clientes/pesquisaServico/${formData.id}/${row.original.colaborador_id}`;
            window.open(url, "_blank");
            event.stopPropagation();
          }}
        />
      </Box>
    );
  };
 

  return (
    <>
  
      {showServiceRanking ? (
        <>
            <Row>
            <InputGroup>
              <SelectFieldFetchWithId
                label="Colaborador"
                funcao={handleChangeEmployee}
                external_value={"nome"}
                loadedVal={employeeToAddInList}
                path="/collaborators/sellers"
                fieldId={employeeToAddInList}
                placeholder={"selecione um vendedor"}
              />
              {<div style={{paddingLeft: '5px'}}>
                <Button
                  disabled={
                    employeeToAddInList === "" ||
                    (rankingEmployeeList &&
                      rankingEmployeeList.some(
                        (item) => item.colaborador_id === employeeToAddInList
                      ))
                  }
                  type="button"
                  variant="success"
                  className="btn"
                  style={{  textAlign: "center" }}
                  onClick={() => {
                    addVendor();
                    
                  }}
                >
                  Add
                </Button>
                </div>
              }
            </InputGroup>
          </Row>
          <Row>
            <Col>
              <div className="table_services_ranking">
                <Table
                key={go}
                  error={errorRanking}
                  loading={loadingRanking}
                  columns={columnsRanking}
                  items={novaData} //useeffect
                  funcao={(item) => {
                    //   navigator.clipboard.writeText(
                    //     endpoints.pesquisaSaleService() +`/${ID}`

                    //   );
                    const url =
                      endpoints.baseApplicationUrl() +
                      `/clientes/pesquisaServico/${formData.id}/${item.colaborador_id}`;
                    window.open(url, "_blank");
                  }}
                  initialState={{
                    columnVisibility: {},
                    density: "compact",
                    pagination: { pageSize: 100 },
                  }}
                  hasActions={false}
                  actionRenderer={renderMenuItems}
                  positionActionsColumn="last"
                />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Button
          type="button"
          variant="success"
          className="btn"
          onClick={() => {
            handleShowRankingEmployee();
            setShowServiceRanking(true);
          }}
        >
          Mostrar classificações da proposta
        </Button>
      )}
    </>
  );
};

export default RankingTable;

import { useFetch } from '../hooks/useFetch';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import endpoints from '../modules/endpoints';

function SelectFieldFetchWithId({ funcao, loadedVal, path, external_value, fieldId, label, placeholder, required }) {
  const { data: items } = useFetch(endpoints.apiServer() + path);
  return (
    <>
      <InputGroup className=''>
        <Form.Label>{label}</Form.Label>
      </InputGroup>
      <Form.Select value={loadedVal} name={fieldId} onChange={(event) => funcao(event)} className='limited-select' required={required}>
        {items && placeholder !== '' ? <option value=''>{placeholder}</option> : <option value=''>Selecione o {label}</option>}

        {items ? (
          items.map((item) => (
            <option key={item['id']} value={item['id']}>
              {item[external_value]}
            </option>
          ))
        ) : (
          <option value=''>{loadedVal}</option>
        )}
      </Form.Select>
      {/* {path === '/clients' && false && <Button variant='outline-primary'>+</Button>} */}
    </>
  );
}

export default SelectFieldFetchWithId;

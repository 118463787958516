import { useFetch } from '../hooks/useFetch';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import endpoints from "../modules/endpoints";

function SelectFieldFetch({ funcao, loadedVal, path, field, label }) {
    const { data: items } = useFetch(endpoints.apiServer() + path);
    return (
        <>
            <Form.Label>{label}</Form.Label>
            <InputGroup className="mb-3">
                <Form.Select value={loadedVal}
                    onChange={(event) => funcao(event.target.value)}
                >
                    {items && <option value="">Selecione o {label}</option>}

                    {items ? items.map((item) => (
                        <option key={item['id']} value={item[{ field }]}>
                            {item[field]}
                        </option>
                    )) : <option value=''>{loadedVal}</option>}
                </Form.Select>
                {path === "/clients" && false && <Button variant="outline-primary">+</Button>} 
            </InputGroup>
        </>
    )
}

export default SelectFieldFetch
import { useState, useRef, useEffect, useMemo } from 'react';

import Table from '../components/Table';
import endpoints from '../modules/endpoints';
import Sidebar from '../components/Sidebar';
import { Col, Row, Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import SelectFieldFixedNew from '../components/selectFieldFixedNew';
import { useFetchPerformatic } from '../hooks/useFetchPerformatic';

function Clientes() {

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);


  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { data: items, httpConfig, loading, error, showAlert } = useFetchPerformatic(endpoints.clients());

  const initialFormData = {
    razao_social: '',
    status: '',
    segmento: '',
    cnpj: '',
    email: '',
    telefones: '',
    enderecos: '',
    obs: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      if (isEditing) {
        httpConfig(formData, 'PUT');
      } else {
        httpConfig(formData, 'POST');
      }
      setIsEditing(false);
      handleCloseDialog();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.status,
        header: 'status',
      },
      {
        accessorFn: (row) => row.razao_social,
        header: 'Razão Social',
        enableHiding: false,
      },
      {
        accessorFn: (row) => row.segmento,
        header: 'Segmento',
      },
      {
        accessorFn: (row) => row.cnpj,
        header: 'CNPJ',
      },
      {
        accessorFn: (row) => row.email,
        header: 'Email',
      },
      {
        accessorFn: (row) => row.telefones,
        header: 'Telefones',
      },
    ],
    []
  );

  function viewItem(item) {
    setIsEditing(true);
    setFormData(item);
    handleOpenDialog();
  }

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.toUpperCase() });
  };

  const segmentosList = [
    { id: 1, value: 'MINERAÇÃO', label: 'MINERAÇÃO' },
    { id: 2, value: 'CIMENTO', label: 'CIMENTO' },
    { id: 3, value: 'PETRÓLEO E GÁS', label: 'PETRÓLEO E GÁS' },
    { id: 4, value: 'FERTILIZANTE', label: 'FERTILIZANTE' },
    { id: 5, value: 'SIDERURGIA', label: 'SIDERURGIA' },
    { id: 6, value: 'METALURGIA', label: 'METALURGIA' },
    { id: 7, value: 'ALIMENTÍCIA', label: 'ALIMENTÍCIA' },
    { id: 8, value: 'AUTOMOBILÍSTICA', label: 'AUTOMOBILÍSTICA' },
    { id: 9, value: 'BORRACHA', label: 'BORRACHA' },
    { id: 10, value: 'QUÍMICA', label: 'QUÍMICA' },
    { id: 11, value: 'OUTROS', label: 'OUTROS' },
  ];

  const statusList = [
    { id: 1, value: 'NOVO', label: 'NOVO' },
    { id: 2, value: 'ATIVO', label: 'ATIVO' },
    { id: 3, value: 'INATIVO', label: 'INATIVO' },
  ];

  return (
    <>
      <Sidebar />
      <div className='content' ref={elementRef}>
        {showAlert && (
          <Alert key={showAlert.level} variant={showAlert.level}>
            {showAlert.message}
          </Alert>
        )}
        <div className='header_modules'>
          <h2 className='product-title'>CLIENTES</h2>
          {!error ? (
            !loading ? (
              <Button onClick={handleOpenDialog} className='btn-add-roles btn-success'>
                Novo Cliente
              </Button>
            ) : (
              <Button className='btn-add-roles btn-success' disabled>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span>Loading...</span>
              </Button>
            )
          ) : null}
        </div>
        <div className='table_funcoes'>
          <Table elementHeight={height - 200} error={error} loading={loading} columns={columns} items={items} funcao={viewItem} initialState={{ density: 'compact', pagination: { pageSize: 100 } }} />
        </div>
        <div>
          <Modal show={openDialog} onHide={handleCloseDialog} centered>
            <Form onSubmit={handleSubmit} className='form-page'>
              <Modal.Header closeButton>{!isEditing ? <Modal.Title>Novo Cliente</Modal.Title> : <Modal.Title>Editar Cliente</Modal.Title>}</Modal.Header>
              <Modal.Body>
                <Form.Group className='mb-3' controlId='formBasicNome'>
                  <Form.Label>Razão Social</Form.Label>
                  <Form.Control value={formData.razao_social} name='razao_social' type='text' onChange={handleChangeValue} required />
                  <Row className='g-2'>
                    <Col xs={4}>
                      <SelectFieldFixedNew label='status' setValue={handleChangeValue} value={formData.status} items={statusList} name='status' required />
                    </Col>
                    <Col xs={8}>
                      <SelectFieldFixedNew label='Segmento' setValue={handleChangeValue} value={formData.segmento} items={segmentosList} name='segmento' required />
                    </Col>
                  </Row>
                  <Form.Label>CNPJ</Form.Label>
                  <Form.Control type='text' value={formData.cnpj} name='cnpj' onChange={handleChangeValue} required />
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control type='text' value={formData.email} name='email' onChange={handleChangeValue} required />
                  <Form.Label>telefones</Form.Label>
                  <Form.Control type='text' value={formData.telefones} name='telefones' onChange={handleChangeValue} />

                  <Form.Label>Endereços</Form.Label>
                  <Form.Control type='text' value={formData.enderecos} name='enderecos' onChange={handleChangeValue} />
                  <Form.Label>Observações</Form.Label>
                  <Form.Control type='text' value={formData.obs} name='obs' onChange={handleChangeValue} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className='btn-controls'>
                  <Button className='btn-danger action-btn' onClick={handleCloseDialog}>
                    Cancelar
                  </Button>
                  <Button className='btn-success action-btn' type='submit'>
                    {' '}
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Clientes;

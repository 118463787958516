import { useState, useMemo, useEffect } from "react"

import { Form, Col, Row, Button, Alert, Spinner, Modal, InputGroup, ListGroup } from "react-bootstrap";

import React from 'react';

import { useFetch } from '../hooks/useFetch';
import { useRequest } from '../hooks/useRequest';

import endpoints from '../modules/endpoints';

import SelectFieldFixedNew from "../components/selectFieldFixedNew";
import Table from "../components/Table";
import Sidebar from "../components/Sidebar";


function Grupos() {
    const [openDialog, setOpenDialog] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertEdit, setShowAlertEdit] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userInList, setUserInList] = useState(false);

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [groupMembers, setGroupMembers] = useState([]);

    const { data: items, httpConfig, loading, error } = useFetch(endpoints.grupos());

    const { sendRequest, response: colaboradores } = useRequest();


    const initialState = {
        id: '',
        nome: '',
        ativo: '',
        participantes: ''
    }

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        sendRequest(endpoints.colaboradores(), 'GET')
    })

    useEffect(() => {
        colaboradores && setUsers(colaboradores)
    }, [colaboradores])

    const handleAddMember = () => {
        const user = users.find(u => u.id === parseInt(selectedUser));
        const userInList = groupMembers.filter(member => member.id === user.id)
        if (userInList.length > 0) {
            setUserInList(true);
        } else {
            userInList && setUserInList(false);
            user && setGroupMembers([...groupMembers, { id: user.id, nome: user.nome_do_colaborador }]);
        }
        setSelectedUser('');
    };

    useEffect(() => {
        setFormData({ ...formData, participantes: groupMembers })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupMembers])


    const handleRemoveMember = (id) => {
        setGroupMembers(groupMembers.filter(member => member.id !== id));
    };

    const handleChangeValue = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toUpperCase() });
    }

    function resetFormData() {
        setGroupMembers([])
        setFormData(initialState);
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        resetFormData();
        setIsEditing(false)
        setOpenDialog(false);
    };

    const handleSubmit = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();

            const newClient = formData;

            if (isEditing) {
                httpConfig(newClient, 'PUT');
            } else {
                httpConfig(newClient, 'POST');
            }

            handleCloseDialog();

            if (!isEditing) {
                setShowAlert(true);
            } else {
                setShowAlertEdit(true);
            }

            setIsEditing(false);

            setTimeout(() => {
                setShowAlert(false)
                setShowAlertEdit(false)
            }, 5000)
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => row.id,
                header: 'Id',
                size: 30
            },
            {
                accessorFn: (row) => row.nome,
                header: 'Nome',
                enableHiding: false,
                size: 30
            },
            {
                accessorFn: (row) => row.ativo,
                header: 'Ativo',
                size: 40,
                Cell: ({ cell }) => (
                    renderAtivo(cell.getValue())
                )
            }
        ],
        [],
    );

    function editItem(item) {
        setIsEditing(true);
        setFormData(item);

        setGroupMembers(JSON.parse(item.participantes))

        handleOpenDialog();
    }

    const ativoList = [
        { id: 1, value: 1, label: 'SIM' },
        { id: 2, value: 0, label: 'NÃO' },
    ]

    const renderAtivo = (value) => {
        if (value === 1) {
            return 'SIM'
        } else {
            return 'NÃO'
        }
    }

    return (
        <>
        <Sidebar/>
        <div className="content">
            {showAlertEdit && <Alert key="success" variant="success">
                Grupo editado com sucesso!
            </Alert>}
            {showAlert && <Alert key="success" variant="success">
                Grupo cadastrado com sucesso!
            </Alert>}
            <div className='header_modules'>
            <h2 className="product-title">GRUPOS</h2>
                {!error ? !loading ?
                    <Button onClick={handleOpenDialog} className='btn-add-roles btn-success' >Novo Grupo</Button>
                    : <Button className='btn-add-roles btn-success' disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span>Loading...</span>
                    </Button> : null}
            </div>
            <div className="table_funcoes">
                <Table error={error} loading={loading} columns={columns} items={items} funcao={editItem} initialState={{ density: 'compact', pagination: { pageSize: 100 } }} />
            </div>
            <div>
                <Modal show={openDialog} onHide={handleCloseDialog} centered size="lg">
                    <Form onSubmit={handleSubmit} className='form-page' >
                        <Modal.Header closeButton>
                            {!isEditing ? <Modal.Title>Novo Grupo</Modal.Title> : <Modal.Title>Grupo</Modal.Title>}
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={10}>
                                    <Form.Group className="mb-3" controlId="formBasicNome">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control required value={formData.nome} name='nome' type="text" onChange={handleChangeValue} />
                                    </Form.Group>

                                </Col>
                                <Col>
                                    <SelectFieldFixedNew required label='Ativo' setValue={handleChangeValue} value={formData.ativo} items={ativoList} name='ativo' />
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col xs={12}>
                                    <Form.Label>Colaboradores</Form.Label>
                                    <InputGroup className="mb-3">

                                        <Form.Select value={selectedUser}
                                            onChange={(event) => {
                                                setSelectedUser(event.target.value);
                                            }}
                                        >
                                            <option value=''>Selecione um colaborador</option>
                                            {users && users.map((user) => (
                                                <option key={user.id} value={user.id}>{user.nome_do_colaborador}</option>
                                            ))}
                                        </Form.Select>
                                        {<Button disabled={selectedUser === ''} type="button" variant="success" className="btn" onClick={handleAddMember}>Add</Button>}
                                    </InputGroup>
                                    {userInList && <div className="groups-error"> <p>Usuário já está na lista</p></div>}

                                    {<ListGroup style={{ height: '248px', overflowY: 'auto' }}>
                                        {groupMembers && groupMembers.map(member => (
                                            <ListGroup.Item className="grupo-list-item" key={member.id}> {member.nome} <Button className="btn-close " type="button" variant="danger" onClick={() => handleRemoveMember(member.id)}></Button> </ListGroup.Item>
                                        ))}
                                    </ListGroup>}

                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Form.Group className='btn-controls'>
                                <Button className='btn-danger action-btn' onClick={handleCloseDialog}>Cancelar</Button>
                                <Button className='btn-success action-btn' type="submit"> Salvar</Button>
                            </Form.Group>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        </div>
        </>
    )
}

export default Grupos
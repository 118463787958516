import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Modal, Button } from 'react-bootstrap';

const PDFViewer = ({ fileContent, fileName, isViewerOpen, setIsViewerOpen }) => {
  const [modalIsOpen, setModalIsOpen] = useState(isViewerOpen);
  const [pdfURL, setPDFURL] = useState(null);

  useEffect(() => {
    setModalIsOpen(isViewerOpen);  // Atualize o estado quando isViewerOpen mudar
  }, [isViewerOpen]);

  const closeModal = () => {
    setModalIsOpen(false);
    

  };

  const downloadPDF = () => {

    const blob = new Blob([fileContent], { type: 'application/pdf' });
    const filename = fileName;
    console.log(filename);
    saveAs(blob, `${filename}.pdf`);
  };

  useEffect(() => {
    // Criar uma URL para o blob e armazená-la no estado
    const url = URL.createObjectURL(new Blob([fileContent], { type: 'application/pdf' }));
    setPDFURL(url);

    // Limpar a URL quando o componente for desmontado
    return () => URL.revokeObjectURL(url);
  }, [fileContent]);

  useEffect(() => {
    if (!modalIsOpen) {
      // Adicione lógica adicional ao fechar a modal, se necessário
      console.log('Modal fechada');
      setIsViewerOpen(false);
    }
  }, [modalIsOpen]);

  return (
    <Modal show={modalIsOpen} size="xl" onHide={closeModal}>
      <Modal.Header closeButton style={{ maxHeight: '40px', padding: '10px' }}>
        <Modal.Title>Conteúdo do PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Renderizar o conteúdo do PDF usando a URL criada */}
        {pdfURL && <iframe src={pdfURL} title="PDF Viewer" style={{ width: '100%', height: '566px', marginTop: '2px' }} />}
      </Modal.Body>
      <Modal.Footer>
       
        {/* <Button variant="secondary" onClick={closeModal}>
              Fechar
            </Button>
            <Button variant="primary" onClick={downloadPDF}>
              Download PDF
            </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default PDFViewer;

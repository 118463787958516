import React, { useState } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

const ImageUploadModal = ({ isOpen, onRequestClose, reportId, setFileModal }) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [authToken] = useState(localStorage.getItem("authToken"));
  const [fileLoading, setFileLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Pré-visualização da imagem
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);

      // Set the file name for display in the input
      setFileName(selectedFile.name);
    } else {
      setPreviewUrl(null);
      setFileName("");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setShowError(true);
      setErrorMessage("Please select a file");
      return;
    }

    try {
      setFileLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      // Perform your file upload logic here
      // Example fetch call:
      // const response = await fetch(`/api/upload-image/${reportId}`, {
      //   method: "POST",
      //   body: formData,
      //   headers: {
      //     Authorization: `Bearer ${authToken}`,
      //   },
      // });

      // Example check for successful upload
      // if (response.ok) {
      //   alert("Image uploaded successfully");
      //   onRequestClose();
      // } else {
      //   alert("An error occurred while uploading the image");
      // }

      // Replace the above example with your actual file upload logic

      // Set the file modal for the parent component (ReportButton)
      setFileModal(formData);
      onRequestClose();
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while uploading the image");
    } finally {
      setFileLoading(false);
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} contentLabel="Image Upload Modal">
      <Modal.Header closeButton>
        <Modal.Title>Upload Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          {/* Pré-visualização da imagem */}
          {previewUrl && <img src={previewUrl} alt="Preview" style={{ maxWidth: "100%", marginBottom: "10px" }} />}

          <label htmlFor="imageInput">Select an image file:</label>
          <input type="file" accept="image/*" id="imageInput" onChange={onFileChange} />
          <Button type="submit" variant="primary">
            {fileLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                {' '}Uploading...
              </>
            ) : (
              'Upload'
            )}
          </Button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageUploadModal;

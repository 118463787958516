import { useState, useRef, useEffect, useMemo } from "react";
import { useRequest } from "../hooks/useRequest";
import Table from "../components/Table";
import endpoints from "../modules/endpoints";
import Sidebar from "../components/Sidebar";
import { Col, Row, Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import SelectFieldFixedNew from "../components/selectFieldFixedNew";
import { useFetchPerformatic } from "../hooks/useFetchPerformatic";
import { useRequestUserInfos } from "../hooks/useRequestUserInfos";
import ReportButton from "../components/ReportButton/ReportButton";
import AnyFileTable from "../components/AnyFileTable/AnyFileTable";

function Reports() {
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);
  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [novaData, setNovaData] = useState([]);
  const [reportId, setReportId] = useState("");
  const {
    data: items,
    httpConfig,
    method: setItems,
    loading,
    error,
    showAlert,
  } = useFetchPerformatic(endpoints.getReports());

  useEffect(() => {
    setNovaData(items); // Atualiza 'novaData' sempre que 'items' mudar
  }, [items]);

  const { getUserInfos, userInfos } = useRequestUserInfos();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      getUserInfos(authToken);
    }
  }, []);

  const initialFormData = {
    issue: "",
    userId: "", // Assuming you have a field for user ID associated with the report
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleUpdateReports = async () => {
    console.log("Updating reports...");
    console.log(reportId);
    console.log(formData.status);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Auth token not found");
        return;
      }

      const response = await fetch(endpoints.getReports(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch reports: ${response.statusText}`);
      }

      const jsonData = await response.json();

      // Atualize o estado com os novos dados
      setNovaData(jsonData);
    } catch (error) {
      console.error("Error updating reports:", error);
    }
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleOpenViewDialog = () => {
    setOpenViewDialog(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const handleCloseDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleCloseCreateDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenCreateDialog(false);
  };

  const handleCloseViewDialog = () => {
    resetFormData();
    setIsEditing(false);
    setOpenViewDialog(false);
  };

  const handleCreateSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      httpConfig(formData, "POST");
      handleCloseCreateDialog();
    }
  };

  const handleViewSubmitUpdate = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
      const response2 = await fetch(endpoints.updateReports(), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", // Add this header to specify JSON content
        },
        body: JSON.stringify({
          id: reportId,
          status: formData.status,
        }),
      });
      if (!response2.ok) {
        throw new Error(`Erro ao atualizar report`);
      }
      alert("Report atualizado com sucesso");

      // setReportId('');
      handleCloseViewDialog();
      handleUpdateReports();
    }
  };

  //CONTINUAR DAQUI
  const handleViewSubmit = async (e) => {
    // Handle any specific logic needed for viewing
    handleCloseViewDialog();
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();

      if (isEditing) {
        // Assuming your API supports updating reports via PUT
        httpConfig(formData, "PUT");
      } else {
        httpConfig(formData, "POST");
      }
      setIsEditing(false);
      handleCloseDialog();
    }
  };

  function renderStatus(status) {
    let statusClassName;
    if (status === "EM ANÁLISE") {
      statusClassName = "custom-badge-report report-analise";
    } else if (status === "CONCLUIDO") {
      statusClassName = "custom-badge-report report-concluido";
    } else if (status === "EM DESENVOLVIMENTO") {
      statusClassName = "custom-badge-report report-desenvolvimento";
    } else if (status === "FECHADO") {
      statusClassName = "custom-badge-report report-fechado";
    } else if (status === "RECEBIDO") {
      statusClassName = "custom-badge-report report-recebido";
    } else {
      statusClassName = "STATUS INVÁLIDO";
    }
    return (
      <>
        <span className={statusClassName}>{status}</span>
      </>
    );
  }

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.issue,
        header: "Descrição do problema/sugestão",
      },

      {
        // accessorFn: (row) => row.status,
        accessorKey: "status",
        header: "Status",
        enableHiding: false,

        Cell: ({ cell }) => renderStatus(cell.getValue()),
      },

      {
        accessorFn: (row) => row.created_at, // Adjust with the actual timestamp field
        header: "Created At",
      },
      // Add more columns as needed
    ],
    []
  );

  function viewItem(item) {
    setIsEditing(true);
    setFormData({
      ...item,
      userId: userInfos && userInfos.id,
    });
    setReportId(item.id);
    handleOpenViewDialog();
  }

  // Adicione este método para criar um novo relatório
  function handleNewReport() {
    setIsEditing(false);
    setFormData({
      issue: "", // ou qualquer valor padrão desejado
      userId: userInfos && userInfos.id,
    });
    handleOpenCreateDialog();
  }

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Sidebar />
      <div className="content" ref={elementRef}>
        {showAlert && (
          <Alert key={showAlert.level} variant={showAlert.level}>
            {showAlert.message}
          </Alert>
        )}
        <div className="header_modules">
          <h2 className="product-title">Reportar problemas/sugestões</h2>
          {!error ? (
            !loading ? (
              <div>
                <ReportButton handleUpdateReports={handleUpdateReports} />
              </div>
            ) : (
              <Button className="btn-add-roles btn-success" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>Loading...</span>
              </Button>
            )
          ) : null}
        </div>

        <div className="table_funcoes">
          <Table
            elementHeight={height - 200}
            error={error}
            loading={loading}
            columns={columns}
            items={novaData}
            funcao={viewItem}
            initialState={{ density: "compact", pagination: { pageSize: 100 } }}
          />
        </div>
        <div>
          <Modal
            show={openCreateDialog}
            onHide={handleCloseCreateDialog}
            centered
          >
            <Form onSubmit={handleCreateSubmit} className="form-page">
              <Modal.Header closeButton>
                <Modal.Title>Reportar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="formBasicIssue">
                  <Form.Label>
                    Descreva, em no máximo 254 caracteres, o seu
                    problema/sugestão.
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={formData.issue}
                    name="issue"
                    rows={4}
                    maxLength={254} // Definindo o valor máximo para 399
                    onChange={handleChangeValue}
                    required
                  />
                  {/* Contador de caracteres restantes */}
                  <small className="text-muted">
                    Caracteres restantes: {254 - formData.issue.length}
                  </small>
                  {/* Add more fields as needed */}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className="btn-controls">
                  <Button
                    className="btn-danger action-btn"
                    onClick={handleCloseCreateDialog}
                  >
                    Cancelar
                  </Button>
                  <Button className="btn-success action-btn" type="submit">
                    Salvar
                  </Button>
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={openViewDialog} onHide={handleCloseViewDialog} centered>
            <Form onSubmit={handleViewSubmitUpdate} className="form-page">
              <Modal.Header closeButton>
                <Modal.Title>Visualizar Report</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="formBasicStatus">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={formData.status}
                    name="status"
                    onChange={handleChangeValue}
                    readOnly={!isEditing}
                    className="form-select"
                  >
                    <option
                      value="RECEBIDO"
                      disabled={formData.status === "RECEBIDO"}
                    >
                      Recebido
                    </option>
                    <option
                      value="EM ANÁLISE"
                      disabled={formData.status === "EM ANÁLISE"}
                    >
                      Em análise
                    </option>
                    <option
                      value="EM DESENVOLVIMENTO"
                      disabled={formData.status === "EM DESENVOLVIMENTO"}
                    >
                      Em desenvolvimento
                    </option>
                    <option
                      value="CONCLUIDO"
                      disabled={formData.status === "CONCLUIDO"}
                    >
                      Concluído
                    </option>
                    <option
                      value="FECHADO"
                      disabled={formData.status === "FECHADO"}
                    >
                      Fechado
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicIssue">
                  <Form.Label>Problema</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ height: "auto" }}
                    value={formData.issue}
                    name="issue"
                    type="text"
                    onChange={handleChangeValue}
                    readOnly={!isEditing}
                    required
                  />
                </Form.Group>
                <Form.Label>Anexos do report</Form.Label>
                <AnyFileTable formData={formData}></AnyFileTable>
              </Modal.Body>
              <Modal.Footer>
                <Form.Group className="btn-controls">
                  <Button
                    className="btn-danger action-btn"
                    onClick={handleCloseViewDialog}
                  >
                    Fechar
                  </Button>

                  {/* Botão de salvar só é exibido quando estiver em modo de edição */}
                  {isEditing && (
                    <Button className="btn-success action-btn" type="submit">
                      Salvar
                    </Button>
                  )}
                </Form.Group>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Reports;
